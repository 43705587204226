import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  // Checkbox,
  Container,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
// import { ProceedCardDAta, TimeCard } from "../Utils/Constant/Services";
import Map from "../components/googleMap";
import { useDispatch, useSelector } from "react-redux";
import {
  createAddress,
  deleteAddress,
  getAllAddress,
  getPincode,
  getSingleAddress,
  updateAddress,
} from "../Redux/festures/addressSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleAlert } from "../Redux/alertSlice";
import { Add, Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import SkLoading from "../components/SkError/SkLoading";
import SkError from "../components/SkError";
// import {
//   disablePastDate,
//   formattedDateServer,
//   generateTimeArray,
// } from "../Utils/dataFunction";
// import SkDatePicker from "../components/SkDatepicker";
// import { createOrder } from "../Redux/festures/orderSlice";
// import { handleOrderOpen, handleSetOrderId } from "../Redux/OrderModalSlice";
// import { getCartData } from "../Redux/festures/cartSlice";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddressCart2 = ({ isOpen, setIsOpen, isData, setIsData }) => {
  const isUserId = localStorage.getItem("isUserId");
  //   useEffect(() => {
  //     if (isUserId)
  //       dispatch(getCartData(`/eCommerce/getAllCartBycustomerId/${isUserId}`));
  //   }, [isUserId]);
  //   const [addressId, setAddressId] = useState(null);
  //   const [date, setDate] = useState(null);
  //   const [time, setTime] = useState(null);
  //   const { cartData } = useSelector((state) => ({ ...state.isCarts }));
  //   const [paymentOption, setPaymentOption] = useState("COD");

  //   const [timeArray, setTimeArray] = useState(null);
  //   useEffect(() => {
  //     setTimeArray(generateTimeArray(date || new Date()));
  //   }, [date]);

  const { addressData, loading } = useSelector((prev) => prev.isAddress);

  const dispatch = useDispatch();

  const [click, setClick] = useState(false);
  const [myLoading, setMyLoading] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpdateId, setIsUpdateId] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const [coords, setCoords] = useState({ lat: "", lng: "" });
  const [bounds, setBounds] = useState({ lat: "", lng: "" });
  const [pincodeData, setPincodeData] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
        setBounds({ lat: latitude, lng: longitude });
      }
    );
  }, []);

  useEffect(() => {
    if (isUserId) {
      dispatch(getAllAddress(`getAllAddressByCustomerId/${isUserId}`)).then(
        (data) => {
          if (data?.payload?.success) {
            // setAddressId(data?.payload?.data?.at(0)?._id);
            setIsData((prev) => ({
              ...prev,
              addressId: data?.payload?.data?.at(0)?._id,
            }));
          }
        }
      );
    }
  }, [isUserId]);

  const initialValuesRegister = {
    firstName: "",
    lastName: "",
    mobile: "",
    pinCode: "",
    apartment: "",
    landmark: "",
    address: "",
    city: "",
    state: "",
    area: "",
    country: "",
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain letters."
      )
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("firstName is required"),

    lastName: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain letters."
      )
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("Last name is required"),

    mobile: Yup.string()
      .required("Mobile number is required")
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Mobile number must be 10 digits"
      ),

    address: Yup.string()
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("Address is required"),

    // apartment: Yup.string().min(10, "Too Short!").max(256, "Too Long!"),

    landmark: Yup.string().min(2, "Too Short!").max(256, "Too Long!"),

    area: Yup.string()
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("Area is required"),

    city: Yup.string()
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("City is required"),

    state: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain letters."
      )
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("state is required"),

    country: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain letters."
      )
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("City is required"),

    pinCode: Yup.string()
      // .matches(/^\d{6}$/, "Pincode must be 6 digits")
      .matches(/^[0-9]+$/, "Must be only digits or numbers")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits")
      .required("Pincode is required"),
  });

  const formik = useFormik({
    initialValues: initialValuesRegister,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log("onSubmit function called");
      // console.log("Form values:", values);

      const customerId = isUserId;
      const latitude = bounds.lat;
      const longitude = bounds.lng;
      setMyLoading(true);
      if (isUpdate) {
        dispatch(
          updateAddress({
            url: `${process.env.REACT_APP_API}/updateAddress/${isUpdateId}`,
            data: {
              ...values,
              customerId,
              latitude,
              longitude,
            },
          })
        ).then((response) => {
          // console.log(response, "response");

          dispatch(
            handleAlert({
              isOpen: true,
              type: "success",
              msg: response?.payload?.message,
            })
          );
          setMyLoading(false);
          if (response?.payload?.success) {
            dispatch(getAllAddress(`getAllAddressByCustomerId/${isUserId}`));
            setIsOpen(true);
            setClick(false);
            formik.setFieldValue("firstName", "");
            formik.setFieldValue("lastName", "");
            formik.setFieldValue("mobile", "");
            formik.setFieldValue("pinCode", "");
            formik.setFieldValue("apartment", "");
            formik.setFieldValue("landmark", "");
            formik.setFieldValue("address", "");
            formik.setFieldValue("city", "");
            formik.setFieldValue("state", "");
            formik.setFieldValue("area", "");
            formik.setFieldValue("country", "");
          }
        });
      } else {
        dispatch(
          createAddress({
            url: `${process.env.REACT_APP_API}/createAddress`,
            data: {
              ...values,
              customerId,
              latitude,
              longitude,
            },
          })
        ).then((response) => {
          // console.log(response, "response");

          dispatch(
            handleAlert({
              isOpen: true,
              type: response?.payload?.success ? "success" : "error",
              msg: response?.payload?.message,
            })
          );
          setMyLoading(false);
          if (response?.payload?.success) {
            dispatch(getAllAddress(`getAllAddressByCustomerId/${isUserId}`));
            setIsOpen(true);
            setClick(false);
            formik.setFieldValue("firstName", "");
            formik.setFieldValue("lastName", "");
            formik.setFieldValue("mobile", "");
            formik.setFieldValue("pinCode", "");
            formik.setFieldValue("apartment", "");
            formik.setFieldValue("landmark", "");
            formik.setFieldValue("address", "");
            formik.setFieldValue("city", "");
            formik.setFieldValue("state", "");
            formik.setFieldValue("area", "");
            formik.setFieldValue("country", "");
          }
        });
      }
    },
  });

  const handlePincodeChange = (e) => {
    const pincode = e.target.value;

    // console.log(pincode);
    if (pincode.length === 6) {
      setIsSearching(true);
      dispatch(
        getPincode({
          url: `https://api.postalpincode.in/pincode/${pincode}`,
        })
      )
        .then((data) => {
          // console.log(data, "pincodedcata");
          if (data.payload?.at(0)?.Status === "Success") {
            setIsSearching(false);
            setPincodeData(data.payload?.at(0)?.PostOffice?.at(0));
            formik.setFieldValue(
              "city",
              data.payload?.at(0)?.PostOffice?.at(0)?.District
            );
            formik.setFieldValue(
              "state",
              data.payload?.at(0)?.PostOffice?.at(0)?.State
            );
            formik.setFieldValue(
              "area",
              data.payload?.at(0)?.PostOffice?.at(0)?.Name ||
                data.payload?.at(0)?.PostOffice?.at(0)?.Division
            );
            formik.setFieldValue(
              "country",
              data.payload?.at(0)?.PostOffice?.at(0)?.Country
            );
            formik.setFieldValue(
              "pinCode",
              data.payload?.at(0)?.PostOffice?.at(0)?.Pincode
            );
          } else {
            setPincodeData(null);
            formik.setFieldValue("city", "");
            formik.setFieldValue("state", "");
            formik.setFieldValue("area", "");
            formik.setFieldValue("country", "");
            formik.setFieldValue("pinCode", "");
            dispatch(
              handleAlert({
                isOpen: true,
                type: "error",
                msg: "please provide the valid pincode",
              })
            );
          }
        })
        .catch((err) => {
          console.log(err, "Error fetching pincode data");
        });
    }
  };
  // const handleCreateOrder = () => {
  //   // dispatch(
  //   //   createOrder({
  //   //     url: `${process.env.REACT_APP_API}/eCommerce/createOrder`,
  //   //     data: {
  //   //       ...cartData?.billDetail,
  //   //       paymentMethod: paymentOption,
  //   //     },
  //   //   })
  //   // ).then((data) => {
  //   //   if (data?.payload?.success) {
  //   //     // console.log(data);
  //   //     dispatch(
  //   //       handleAlert({
  //   //         isOpen: true,
  //   //         type: data?.payload?.success ? "success" : "error",
  //   //         msg: data?.payload?.message,
  //   //       })
  //   //     );
  //   //     setClick(false);
  //   //     setIsClick(false);
  //   //     setIsOpen(false);
  //   //     formik.setFieldValue("firstName", "");
  //   //     formik.setFieldValue("lastName", "");
  //   //     formik.setFieldValue("mobile", "");
  //   //     formik.setFieldValue("pinCode", "");
  //   //     formik.setFieldValue("apartment", "");
  //   //     formik.setFieldValue("landmark", "");
  //   //     formik.setFieldValue("address", "");
  //   //     formik.setFieldValue("city", "");
  //   //     formik.setFieldValue("state", "");
  //   //     formik.setFieldValue("area", "");
  //   //     formik.setFieldValue("country", "");
  //   //     setAddressId(null);
  //   //     setDate(null);
  //   //     setTime(null);
  //   //     dispatch(handleOrderOpen(true));
  //   //     dispatch(handleSetOrderId(data?.payload?.data?._id));
  //   //   }
  //   // });
  // };
  return (
    <>
      {/* get or select Address  */}
      {isOpen && !click && (
        <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          <Box
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            onClick={() => setClick(true)}
          >
            <Button
              variant="text"
              color="primary"
              startIcon={
                <Add
                  sx={{
                    width: 20,
                    height: 20,
                    bgcolor: "primary.main",
                    color: "white.main",
                    borderRadius: 1,
                  }}
                />
              }
              sx={{
                fontSize: 15,
                fontWeight: 600,
                color: "primary.main",
                cursor: "pointer",
                textTransform: "capitalize",
              }}
            >
              Add another address
            </Button>
          </Box>
          <Box sx={{ width: "100%" }}>
            <RadioGroup
              sx={{
                "& .MuiFormControlLabel-root": {
                  width: "100%",
                },
              }}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={isData?.addressId}
              onChange={(e) =>
                setIsData((prev) => ({ ...prev, addressId: e.target.value }))
              }
            >
              {loading ? (
                <SkLoading />
              ) : addressData && addressData.length > 0 ? (
                addressData.map((value) => (
                  <FormControlLabel
                    sx={{
                      "& .MuiTypography-root": {
                        width: "100%",
                      },
                    }}
                    value={value?._id}
                    key={value?._id}
                    control={
                      <Radio
                        onClick={() => {
                          setIsOpen(false);
                          //   setIsClick(true);
                          setClick(false);
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          border: 0.5,
                          borderRadius: 2,
                          borderColor: "gray.border",
                          width: "100%",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          gap: 0.2,
                          p: 1,
                          my: 0.5,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 0.5,
                            position: "absolute",
                            top: 2,
                            right: 2,
                          }}
                        >
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              dispatch(
                                getSingleAddress(`getByAddressId/${value?._id}`)
                              ).then((data) => {
                                if (data?.payload?.success) {
                                  // console.log(data?.payload?.data, "data");
                                  formik.setFieldValue(
                                    "firstName",
                                    data?.payload?.data?.firstName
                                  );
                                  formik.setFieldValue(
                                    "lastName",
                                    data?.payload?.data?.lastName
                                  );
                                  formik.setFieldValue(
                                    "mobile",
                                    data?.payload?.data?.mobile
                                  );
                                  formik.setFieldValue(
                                    "pinCode",
                                    data?.payload?.data?.pinCode
                                  );
                                  formik.setFieldValue(
                                    "apartment",
                                    data?.payload?.data?.apartment
                                  );
                                  formik.setFieldValue(
                                    "landmark",
                                    data?.payload?.data?.landmark
                                  );
                                  formik.setFieldValue(
                                    "address",
                                    data?.payload?.data?.address
                                  );
                                  formik.setFieldValue(
                                    "city",
                                    data?.payload?.data?.city
                                  );
                                  formik.setFieldValue(
                                    "state",
                                    data?.payload?.data?.state
                                  );
                                  formik.setFieldValue(
                                    "area",
                                    data?.payload?.data?.area
                                  );
                                  formik.setFieldValue(
                                    "country",
                                    data?.payload?.data?.country
                                  );

                                  setCoords((prev) => ({
                                    ...prev,
                                    lat: data?.payload?.data?.latitude,
                                    lng: data?.payload?.data?.longitude,
                                  }));
                                  setBounds((prev) => ({
                                    ...prev,
                                    lat: data?.payload?.data?.latitude,
                                    lng: data?.payload?.data?.longitude,
                                  }));

                                  setClick(true);
                                  setIsUpdate(true);
                                  setIsUpdateId(value?._id);
                                }
                              });
                            }}
                          >
                            <Edit sx={{ color: "primary.main" }} />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              dispatch(
                                deleteAddress({
                                  url: `${process.env.REACT_APP_API}/deleteAddress/${value?._id}`,
                                })
                              ).then((data) => {
                                dispatch(
                                  handleAlert({
                                    isOpen: true,
                                    type: data?.payload?.success
                                      ? "success"
                                      : "error",
                                    msg: data?.payload?.message,
                                  })
                                );
                                if (data?.payload?.success) {
                                  dispatch(
                                    getAllAddress(
                                      `getAllAddressByCustomerId/${isUserId}`
                                    )
                                  );
                                }
                              });
                            }}
                          >
                            <Delete sx={{ color: "primary.main" }} />
                          </IconButton>
                        </Box>
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            fontSize: 15,
                            fontWeight: 600,
                            maxWidth: "75%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {value?.firstName} {value?.lastName}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            fontSize: 15,
                            fontWeight: 600,
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {value?.mobile}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 4,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {value?.apartment} ,{value?.landmark},{value?.area}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {" "}
                          {value?.address}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {value?.city}, {value?.state}, {value?.country},
                          {value?.pinCode}
                        </Typography>
                      </Box>
                    }
                  />
                ))
              ) : (
                <SkError EComm={true} type={"Error"} />
              )}
            </RadioGroup>
          </Box>
        </Box>
      )}
      {/* create Address  */}
      {isOpen && click && (
        <Box
          sx={{
            //   display:isHover?"none":"flex",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            justifyContent: "center",
            alignItems: "center",
            // p:5
          }}
        >
          <Map
            setCoords={setCoords}
            coords={coords}
            bounds={bounds}
            setBounds={setBounds}
          />
          <Container
            sx={{
              my: 2,
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    // autoComplete="off"
                    label="First Name"
                    placeholder="First Name"
                    name="firstName"
                    fullWidth
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    // autoComplete="off"
                    label="Last Name"
                    placeholder="Last Name"
                    name="lastName"
                    fullWidth
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="standard"
                    type="number"
                    label="Mobile"
                    placeholder="Mobile"
                    name="mobile"
                    fullWidth
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.mobile && Boolean(formik.errors.mobile)
                    }
                    helperText={formik.touched.mobile && formik.errors.mobile}
                    inputProps={{
                      maxLength: 10,
                      onKeyDown: (e) => {
                        if (e.target.value.length >= 10) {
                          e.preventDefault();
                        }
                      },
                    }}
                    sx={{
                      "& .MuiInput-input": {
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          {
                            "-webkit-appearance": "none",
                            "-moz-appearance": "textfield",
                          },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    // autoComplete="off"
                    label="Address"
                    placeholder="Address"
                    name="address"
                    fullWidth
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    label="Apartment"
                    placeholder="Apartment"
                    name="apartment"
                    fullWidth
                    value={formik.values.apartment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.apartment &&
                      Boolean(formik.errors.apartment)
                    }
                    helperText={
                      formik.touched.apartment && formik.errors.apartment
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    label="Landmark"
                    placeholder="Landmark"
                    name="landmark"
                    fullWidth
                    value={formik.values.landmark}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.landmark && Boolean(formik.errors.landmark)
                    }
                    helperText={
                      formik.touched.landmark && formik.errors.landmark
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    label="Pincode"
                    placeholder="Pincode"
                    name="pinCode"
                    fullWidth
                    type="number"
                    value={formik.values.pinCode}
                    onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    error={
                      formik.touched.pinCode && Boolean(formik.errors.pinCode)
                    }
                    helperText={formik.touched.pinCode && formik.errors.pinCode}
                    onBlur={(e) => {
                      handlePincodeChange(e);
                      formik.handleBlur(e);
                    }}
                    inputProps={{
                      maxLength: 6,
                      minLength: 6,
                      // onKeyDown: (e) => {
                      //   if (e.target.value.length >= 6) {
                      //     e.preventDefault();
                      //   }
                      // },
                    }}
                    sx={{
                      "& .MuiInput-input": {
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          {
                            "-webkit-appearance": "none",
                            "-moz-appearance": "textfield",
                          },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    label="Area"
                    disabled={isSearching}
                    placeholder="Area"
                    name="area"
                    fullWidth
                    value={formik.values.area}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.area && Boolean(formik.errors.area)}
                    helperText={formik.touched.area && formik.errors.area}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    label="City"
                    disabled={isSearching}
                    placeholder="City"
                    name="city"
                    fullWidth
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    label="State"
                    disabled={isSearching}
                    placeholder="State"
                    name="state"
                    fullWidth
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    label="Country"
                    placeholder="Country"
                    name="country"
                    fullWidth
                    disabled={isSearching}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    loading={myLoading}
                    variant="contained"
                    type="submit"
                    color="primary"
                    sx={{ textTransform: "capitalize" }}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      )}
    </>
  );
};

export default AddressCart2;
