import {
  Box,
  Typography,
  Button,
  Container,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import Contaimport { useFormik } from 'formik';

import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useNavigate } from "react-router-dom";

// import { ContactCardData } from "../../Utils/Constant/Services";
import { useDispatch, useSelector } from "react-redux";
import { registerUsers } from "../../Redux/festures/userSlice";
import { handleAlert } from "../../Redux/alertSlice";
import { Phone } from "@mui/icons-material";

const ContactUs = () => {
  const isMobile = useMediaQuery("(max-width:870px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isData, setIsData] = useState({
    fullName: "",
    email: "",
    discription: "",
  });
  const { company } = useSelector((state) => state.isCompany);

  const initialValues = {
    name: "",
    email: "",
    message: "",
    mobile: "",
  };

  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const validationSchema = Yup.object({
    mobile: Yup.string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .matches(phoneRegex, "Invalid mobile number")
      .required("mobile is required"),
    name: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain alphabet letters."
      )
      .min(3, "Must be exactly 3 digits")
      .max(200, "Must be exactly 200 digits")
      .matches(/^\s*[\S]+(\s[\S]+)+\s*$/, "Please enter your full name.")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      )
      .required("Email is required"),
    message: Yup.string()
      .min(6, "Must be exactly 6 digits")
      .max(1000, "Must be exactly 1000 digits")
      .required("Message is required"),
  });

  const onSubmit = (values, { resetForm }) => {
    // Here, you can add the logic to send the form data, e.g., through an API call or email.
    // console.log("Form data:", values);
    dispatch(
      registerUsers({
        url: `${process.env.REACT_APP_API}/createContactUs `,
        data: {
          fullName: values?.name,
          email: values?.email?.toLowerCase(),
          discription: values?.message,
          mobile: values?.mobile,
        },
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: data?.payload?.success ? "success" : "error",
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        navigate("/home-page");
        resetForm();
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      {/* <HomeNav /> */}

      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            flexDirection: { xs: "column", sm: "column", md: "row", xl: "row" },
            mt: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: { xs: "100%", sm: "100%", md: "40%", xl: "40%" },
              textAlign: "justify",
            }}
          >
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 25, fontWeight: 600, fontFamily: "serif" }}
            >
              Contact Us
            </Typography>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 15,
                fontWeight: 400,
                color: "gray.main",
                fontFamily: "Inter",
              }}
            >
              Thank you for considering our {company?.site_name} and exploring
              our range of gym products! We're excited to help you on your
              fitness journey. If you have any questions, need assistance, or
              want to learn more about our offerings, please don't hesitate to
              get in touch with us.
            </Typography>
            {/* <a href={"#contact_us_form"}> */}
            {/* <Button
              variant="contained"
              color="primary"
              // component={Link}
              // to={"#contact_us_form"}
              sx={{
                fontSize: 15,
                fontFamily: "Inter",
                textTransform: "capitalize",
                width: "50%",
              }}
            >
              Connect With Me
            </Button> */}
            {/* </a> */}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "60%", xl: "60%" },
              height: "35vh",
            }}
          >
            <img
              src={require("../../assets/contactimg.png")}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              alt="..."
            />
          </Box>
        </Box>
      </Container>
      <Box sx={{ border: 1, borderColor: "gray.main", mt: 3 }}></Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            sx={{ fontSize: 20, fontWeight: 800, fontFamily: "Inter" }}
          >
            You Contact here
          </Typography>
        </Box>
        {/* <Box
          // id={"contact_us_form"}
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            pb: 3,
          }}
        >
         
         
        </Box> */}
        <Box
          sx={{
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
            px: { sm: 2, xs: 2, md: 0, xl: 0 },
            gap: 2,
            my: isMobile ? 2 : 1,
            // marginBottom: isMobile ? 2 : 1,
          }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              width: isMobile ? "100%" : "50%",
            }}
            onSubmit={formik.handleSubmit}
          >
            <TextField
              fullWidth
              type={"text"}
              required
              autoComplete="off"
              variant="outlined"
              placeholder="Full Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              sx={{
                "& .MuiInputBase-root": {
                  height: 45,
                  //   border: 2,
                  //   borderColor: "gray",
                  fontSize: 14,
                  fontFamily: "Inter",
                  borderRadius: "15px",
                  width: "100%",
                  gap: 2,
                  bgcolor: "#ECECEC",

                  // boxShadow:
                  // " inset -6px -3px 5px rgba(255, 255, 255, 0.750601), inset 3px 6px 6px #C3D7E7, inset 4px 2px 6px #B1C5D5, inset 0px 2px 4px #C8D4E2",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  outline: "none",
                  border: "none",
                },
              }}
              InputProps={{
                startAdornment: (
                  <inputAdornment position="start">
                    <PersonIcon sx={{ fontSize: 15 }} />
                  </inputAdornment>
                ),
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <TextField
              fullWidth
              type={"email"}
              required
              autoComplete="off"
              variant="outlined"
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              sx={{
                "& .MuiInputBase-root": {
                  height: 45,
                  //   border: 2,
                  //   borderColor: "gray",
                  fontSize: 14,
                  fontFamily: "Inter",
                  borderRadius: "15px",
                  width: "100%",
                  gap: 2,
                  bgcolor: "#ECECEC",

                  // boxShadow:
                  // " inset -6px -3px 5px rgba(255, 255, 255, 0.750601), inset 3px 6px 6px #C3D7E7, inset 4px 2px 6px #B1C5D5, inset 0px 2px 4px #C8D4E2",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  outline: "none",
                  border: "none",
                },
              }}
              InputProps={{
                startAdornment: (
                  <inputAdornment position="start">
                    <EmailIcon sx={{ fontSize: 15 }} />
                  </inputAdornment>
                ),
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <TextField
              fullWidth
              type={"number"}
              required
              autoComplete="off"
              variant="outlined"
              placeholder="mobile"
              name="mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mobile && !!formik.errors.mobile}
              helperText={formik.touched.mobile && formik.errors.mobile}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              sx={{
                "& .MuiInputBase-root": {
                  height: 45,
                  //   border: 2,
                  //   borderColor: "gray",
                  fontSize: 14,
                  fontFamily: "Inter",
                  borderRadius: "15px",
                  width: "100%",
                  gap: 2,
                  bgcolor: "#ECECEC",

                  // boxShadow:
                  // " inset -6px -3px 5px rgba(255, 255, 255, 0.750601), inset 3px 6px 6px #C3D7E7, inset 4px 2px 6px #B1C5D5, inset 0px 2px 4px #C8D4E2",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  outline: "none",
                  border: "none",
                },
              }}
              InputProps={{
                startAdornment: (
                  <inputAdornment position="start">
                    <Phone sx={{ fontSize: 15 }} />
                  </inputAdornment>
                ),
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <TextField
              multiline
              rows={4}
              maxRows={8}
              fullWidth
              type={"text"}
              required
              autoComplete="off"
              variant="outlined"
              name="message"
              placeholder="Write something about your query"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.message && !!formik.errors.message}
              helperText={formik.touched.message && formik.errors.message}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: 14,
                  fontFamily: "Inter",
                  borderRadius: "15px",
                  width: "100%",
                  gap: 2,
                  bgcolor: "#ECECEC",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  outline: "none",
                  border: "none",
                },
              }}
              InputProps={{
                startAdornment: (
                  <inputAdornment position="start">
                    <EditOutlinedIcon sx={{ fontSize: 15 }} />
                  </inputAdornment>
                ),
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                formik.touched.name &&
                formik.errors.name &&
                formik.touched.email &&
                formik.errors.email &&
                formik.touched.message &&
                formik.errors.message
              }
              sx={{
                width: "40%",
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontSize: 15,
                fontWeight: 600,
              }}
            >
              Send
            </Button>
          </form>
          <Box
            sx={{
              width: "40%",
              height: "60vh",
              display: { sm: "none", xs: "none", md: "block", xl: "block" },
            }}
          >
            <img
              src={require("../../assets/contactimage.png")}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt="..."
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ContactUs;
