import { Box, CircularProgress } from "@mui/material";
import React from "react";

const SkLoading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <CircularProgress size={30} thickness={4} value={20} />
      Loading...
    </Box>
  );
};

export default SkLoading;
