import { Box, Typography, Button, IconButton } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { SkPrice } from "../Utils/dataFunction";
import {
  // createCartData,
  deleteCart,
  getCartData,
  getEcommCartData,
  updateCart,
} from "../Redux/festures/cartSlice";
import { useDispatch } from "react-redux";
import { handleAlert } from "../Redux/alertSlice";

const CardPageCard = ({ value, EComm }) => {
  const dispatch = useDispatch();
  const isUserId = localStorage.getItem("isUserId");
  return (
    <>
      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "35rem", xl: "35rem" },
          height: {
            xs: "11.5rem",
            sm: "11.5rem",
            md: "12.5rem",
            xl: "12.5rem",
          },
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          boxShadow:
            "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: 2,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "calc(100% - 30%)",
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: 18,
              fontWeight: 800,
              maxWidth: "100%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value?.productId?.title}
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontSize: 15, fontWeight: 800, color: "rgba(75, 75, 75, 1)" }}
          >
            {SkPrice(value?.productId?.price)}
          </Typography>
          {/* <Box sx={{ display: "flex", gap: 1, alignItems: "center", mt: 1 }}>
            <CircleIcon sx={{ fontSize: 8, color: "info.main" }} />
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 12, fontWeight: 400, color: "info.main" }}
            >
              Service within 30 min
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <CircleIcon sx={{ fontSize: 8, color: "info.main" }} />
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 12, fontWeight: 400, color: "info.main" }}
            >
              7 Year experience Plumber
            </Typography>
          </Box> */}
          {value?.productId?.include &&
            value?.productId?.include.length > 0 &&
            value?.productId?.include.slice(0, 3)?.map((text, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  maxWidth: "100%",
                }}
              >
                <CircleIcon sx={{ fontSize: 10, color: "gray" }} />

                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 13.5,
                    fontWeight: 400,
                    color: "gray",
                    fontFamily: "Inter",
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {text}
                </Typography>
              </Box>
            ))}
          {value?.productId?.features &&
            value?.productId?.features.length > 0 &&
            value?.productId?.features.slice(0, 3)?.map((text, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  maxWidth: "100%",
                }}
              >
                <CircleIcon sx={{ fontSize: 10, color: "gray" }} />

                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 13.5,
                    fontWeight: 400,
                    color: "gray",
                    fontFamily: "Inter",
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {text}
                </Typography>
              </Box>
            ))}

          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "15%",
              height: "20%",
              bgcolor: "white.main",
              fontSize: 10,
              color: "black.main",
              textTransform: "capitalize",
              fontWeight: 600,
              borderRadius: 2,
              boxShadow:
                "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25)",
              mt: 1,
              "&:hover": {
                bgcolor: "white.main",
              },
            }}
            onClick={() => {
              if (EComm) {
                dispatch(
                  deleteCart({
                    url: `${process.env.REACT_APP_API}/eCommerce/deleteCartById/${value?._id}`,
                  })
                ).then((data) => {
                  // console.log(data, "quantityUpdate");
                  if (data?.payload?.success) {
                    // dispatch(
                    //   getEcommCartData(
                    //     `/eCommerce/getAllCartBycustomerId/${isUserId}`
                    //   )
                    // );
                    if (localStorage.getItem("isUserId")) {
                      dispatch(
                        getEcommCartData(
                          `/eCommerce/getAllCartBycustomerId/${localStorage.getItem(
                            "isUserId"
                          )}`
                        )
                      );
                    } else {
                      dispatch(
                        getEcommCartData(
                          `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
                            "isUserIdDummy"
                          )}`
                        )
                      );
                    }
                  }
                  if (!data?.payload?.success) {
                    // setIsShow(false);
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: "error",
                        msg: data?.payload?.message,
                      })
                    );
                  }
                });
              } else {
                dispatch(
                  deleteCart({
                    url: `${process.env.REACT_APP_API}/deleteCartById/${value?._id}`,
                  })
                ).then((data) => {
                  if (data?.payload?.success) {
                    if (localStorage.getItem("isUserId")) {
                      dispatch(
                        getCartData(`getAllCartBycustomerId/${isUserId}`)
                      );
                    } else {
                      dispatch(
                        getCartData(
                          `/getAllCartByuserId/${localStorage.getItem(
                            "isUserIdDummy"
                          )}`
                        )
                      );
                    }
                  }
                  if (!data?.payload?.success) {
                    // setIsShow(false);
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: "error",
                        msg: data?.payload?.message,
                      })
                    );
                  }
                });
              }
            }}
          >
            Remove
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            width: "30%",
            p: 1.5,
          }}
        >
          <Box sx={{ width: "100%", height: "9rem" }}>
            <img
              src={`${process.env.REACT_APP_URI}/${value?.productId?.thumnail}`}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../assets/images/g3.png");
              }}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "8px",
              }}
              alt="..."
            />
          </Box>
          <Box
            // onClick={() => setIsShow(false)}
            sx={{
              display: "flex",
              gap: 1,
              borderRadius: " 10px",
              border: "1px solid #DCDCDC",

              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "white.main",
              m: -3,
              width: "auto",
              height: "auto",
            }}
          >
            {/* {console.log(isQuantity(data?._id, cartData?.cartData) === 0)} */}

            <Box
              sx={{
                display: "flex",

                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  if (EComm) {
                    dispatch(
                      updateCart({
                        url: `${process.env.REACT_APP_API}/eCommerce/quantityUpdate/${value?._id}`,
                      })
                    ).then((data) => {
                      // console.log(data, "quantityUpdate");
                      if (data?.payload?.success) {
                        if (localStorage.getItem("isUserId")) {
                          dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllCartBycustomerId/${localStorage.getItem(
                                "isUserId"
                              )}`
                            )
                          );
                        } else {
                          dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
                                "isUserIdDummy"
                              )}`
                            )
                          );
                        }
                      }
                      if (!data?.payload?.success) {
                        // setIsShow(false);
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: data?.payload?.message,
                          })
                        );
                      }
                    });
                  } else {
                    dispatch(
                      updateCart({
                        url: `${process.env.REACT_APP_API}/quantityUpdate/${value?._id}`,
                      })
                    ).then((data) => {
                      // console.log(data, "quantityUpdate");
                      if (data?.payload?.success) {
                        if (localStorage.getItem("isUserId")) {
                          dispatch(
                            getCartData(`getAllCartBycustomerId/${isUserId}`)
                          );
                        } else {
                          dispatch(
                            getCartData(
                              `/getAllCartByuserId/${localStorage.getItem(
                                "isUserIdDummy"
                              )}`
                            )
                          );
                        }
                      }
                      if (!data?.payload?.success) {
                        // setIsShow(false);
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: data?.payload?.message,
                          })
                        );
                      }
                    });
                  }
                }}
                sx={{
                  fontSize: 13.5,
                  fontWeight: 600,
                  color: "primary.main",
                  cursor: "pointer",
                }}
              >
                +
              </IconButton>
              <IconButton
                sx={{
                  fontSize: 13.5,
                  fontWeight: 600,
                  color: "primary.main",
                  cursor: "pointer",
                }}
              >
                {value?.quantity}
              </IconButton>
              <IconButton
                onClick={() => {
                  if (EComm) {
                    dispatch(
                      updateCart({
                        url: `${process.env.REACT_APP_API}/eCommerce/removeQuantity/${value?._id}`,
                      })
                    ).then((data) => {
                      if (data?.payload?.success) {
                        if (localStorage.getItem("isUserId")) {
                          dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllCartBycustomerId/${localStorage.getItem(
                                "isUserId"
                              )}`
                            )
                          );
                        } else {
                          dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
                                "isUserIdDummy"
                              )}`
                            )
                          );
                        }
                      }
                      if (!data?.payload?.success) {
                        // setIsShow(false);
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: data?.payload?.message,
                          })
                        );
                      }
                    });
                  } else {
                    dispatch(
                      updateCart({
                        url: `${process.env.REACT_APP_API}/removeQuantity/${value?._id}`,
                      })
                    ).then((data) => {
                      if (data?.payload?.success) {
                        if (localStorage.getItem("isUserId")) {
                          dispatch(
                            getCartData(`getAllCartBycustomerId/${isUserId}`)
                          );
                        } else {
                          dispatch(
                            getCartData(
                              `/getAllCartByuserId/${localStorage.getItem(
                                "isUserIdDummy"
                              )}`
                            )
                          );
                        }
                      }
                      if (!data?.payload?.success) {
                        // setIsShow(false);
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: data?.payload?.message,
                          })
                        );
                      }
                    });
                  }
                }}
                sx={{
                  fontSize: 13.5,
                  fontWeight: 600,
                  color: "primary.main",
                  cursor: "pointer",
                }}
              >
                -
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CardPageCard;
