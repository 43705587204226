import Home from "./pages/home";
import Services from "./pages/services";
import About from "./pages/About";
import PrivatePolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import Faq from "./pages/Faq";

import Cart from "./pages/Cart";
import Order from "./pages/Order";
import Productdetail from "./pages/Productdetail";

import OrderSummary from "./pages/OrderSummary/OrderSummary";
import ServiceDetailsPage from "./pages/ServiceDtails page";
import TermsAndCondition from "./pages/TermsAndCondition";
import EcommServiceDetails from "./pages/ServiceDtails page/E_ServiceDetails";
import EcommServices from "./pages/services/EcommServices";
import EcommHome from "./pages/home/EcommHome";
import EcommCart from "./pages/Cart/EcommCart";
import ProfilePage from "./pages/ProfilePage";
import { Searchresult } from "./pages/Search/Searchresult";
import ProfessionalSection from "./pages/ProfessionalSection";
import OrderHistoryDetails from "./pages/Order/OrderHistoryDetails";
import PaymentStatus from "./pages/Notification/PaymentStatus";
import Notification from "./pages/Notification/Notification";
import ShippingAndDeliveryPage from "./pages/policys/ShippingAndDeliveryPage";
import RefundReturnPage from "./pages/policys/RefundReturnPage";
import AccountDelete from "./pages/AccountDelete";

const routes = [
  {
    type: "route",
    name: "home",
    key: "home",
    route: "/home-page",
    component: <Home />,
  },
  {
    type: "route",
    name: "About",
    key: "About",
    route: "/about-page",
    component: <About />,
  },
  {
    type: "route",
    name: "profile",
    key: "profile",
    route: "/profile-page",
    component: <ProfilePage />,
  },

  {
    type: "route",
    name: "PrivatePolicy",
    key: "PrivatePolicy",
    route: "/privatePolicy-page",
    component: <PrivatePolicy />,
  },
  {
    type: "route",
    name: "ShippingAndDeliveryPage",
    key: "ShippingAndDeliveryPage",
    route: "/shipping-and-delivery-page",
    component: <ShippingAndDeliveryPage />,
  },
  {
    type: "route",
    name: "RefundReturnPage",
    key: "RefundReturnPage",
    route: "/refund-policy-page",
    component: <RefundReturnPage />,
  },
  {
    type: "route",
    name: "TermsAndCondition",
    key: "TermsAndCondition",
    route: "/tems&conditon-page",
    component: <TermsAndCondition />,
  },
  {
    type: "route",
    name: "ContactUs",
    key: "ContactUs",
    route: "/contact-page",
    component: <ContactUs />,
  },
  {
    type: "route",
    name: "Faq",
    key: "Faq",
    route: "/faq-page",
    component: <Faq />,
  },
  {
    type: "route",
    name: "Order",
    key: "Order",
    route: "/order-page",
    component: <Order />,
  },
  {
    type: "route",
    name: "Cart",
    key: "Cart",
    route: "/cart-page",
    component: <Cart />,
  },
  {
    type: "route",
    name: "services",
    key: "services",
    route: "/services-page/:id",
    component: <Services />,
  },

  {
    type: "route",
    name: "Productdetail",
    key: "Productdetail",
    route: "/productdetail-page",
    component: <Productdetail />,
  },
  {
    type: "route",
    name: "serviceDetails",
    key: "serviceDetails",
    route: "/serviceDetails-page/:id",
    component: <ServiceDetailsPage />,
  },

  {
    type: "route",
    name: "Searchresult",
    key: "Searchresult",
    route: "/search-page",
    component: <Searchresult />,
  },
  // {
  //   type: "route",
  //   name: "AddAddress",
  //   key: "AddAddress",
  //   route: "/addAddress-page",
  //   component: <AddAddress />,
  // },

  // {
  //   type: "route",
  //   name: "Payment",
  //   key: "Payment",
  //   route: "/payment-page",
  //   component: <Payment />,
  // },

  // {
  //   type: "route",
  //   name: "CartMobile",
  //   key: "CartMobile",
  //   route: "/cartMobile-page",
  //   component: <Cartmobile />,
  // },
  {
    type: "route",
    name: "OrderSummary",
    key: "OrderSummary",
    route: "/orderSummary-page",
    component: <OrderSummary />,
  },
  {
    type: "route",
    name: "EHome",
    key: "EHome",
    route: "/e_home-page",
    component: <EcommHome />,
  },
  {
    type: "route",
    name: "EService",
    key: "EService",
    route: "/e_categories-page/:id",
    component: <EcommServices />,
  },
  {
    type: "route",
    name: "ECart",
    key: "ECart",
    route: "/e_cart-page",
    component: <EcommCart />,
  },
  {
    type: "route",
    name: "e_serviceDetails",
    key: "e_serviceDetails",
    route: "/e_categoriesDetails/:id",
    component: <EcommServiceDetails />,
  },
  {
    type: "route",
    name: "ProfessionalSection",
    key: "ProfessionalSection",
    route: "/professionalRegistration",
    component: <ProfessionalSection />,
  },
  {
    type: "route",
    name: "OrderHistoryDetails",
    key: "OrderHistoryDetails",
    route: "/orderHistoryPage",
    component: <OrderHistoryDetails />,
  },
  {
    type: "route",
    name: "payment",
    key: "payment",
    route: "/paymentstauspage",
    component: <PaymentStatus />,
  },
  {
    type: "route",
    name: "notification",
    key: "notification",
    route: "/notification",
    component: <Notification />,
  },
  {
    type: "route",
    name: "accountDelete",
    key: "accountDelete",
    route: "/account_delete",
    component: <AccountDelete />,
  },
];
export default routes;
