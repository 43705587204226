import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Divider, Rating, IconButton } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { SkPrice } from "../Utils/dataFunction";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createCartData,
  getCartData,
  getEcommCartData,
  updateCart,
} from "../Redux/festures/cartSlice";
import { handleModalOpen } from "../Redux/LoginSlice";
import { handleAlert } from "../Redux/alertSlice";
const Choosebutton = ({ data, EComm }) => {
  const isUserId = localStorage.getItem("isUserId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartData, ecommCartData } = useSelector((state) => state.isCarts);
  const { existUser } = useSelector((State) => State.isUsers);
  // const [show, setShow] = useState(true);
  const [isShow, setIsShow] = useState(true);
  // const [counter, setCounter] = useState(1);
  // const handleClick1 = () => {
  //   setCounter(counter + 1);
  // };
  // const handleClick2 = () => {
  //   if (counter > 0) {
  //     setCounter(counter - 1);
  //   }
  // };

  const isCartId = (isId, data) => {
    const matchingProducts = data?.find((value) => {
      return value?.productId?._id === isId;
    });
    const matchingProductIds = matchingProducts?._id;
    return matchingProductIds;
  };
  const isQuantity = (isId, data) => {
    const matchingProducts = data?.find((value) => {
      return value?.productId?._id === isId;
    });
    const matchingProductIds = matchingProducts?.quantity;
    return matchingProductIds;
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "12.5rem",

          borderRadius: 1.2,
          boxShadow:
            "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          p: { xs: 0.8, sm: 0.8, md: 2, xl: 2 },
          cursor: "pointer",
        }}
        onClick={() => {
          EComm
            ? navigate(`/e_categoriesDetails/${data?._id}`)
            : navigate(`/serviceDetails-page/${data?._id}`);
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "65%",
            height: "100%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 0.1,
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: 18,
              fontWeight: 800,
              fontFamily: "Inter",
              maxWidth: "100%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.title}
          </Typography>
          <Box sx={{ display: "flex", gap: 1, maxWidth: "60%" }}>
            <Rating
              name="customized-1"
              max={1}
              value={data?.reviewRating}
              size="small"
              readOnly
            />
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: "#4B4B4B",
                fontFamily: "Inter",
              }}
            >
              {data?.reviewRating}
            </Typography>
          </Box>
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: 20,
              fontWeight: 800,
              fontFamily: "Inter",
              maxWidth: "100%",
            }}
          >
            {SkPrice(data?.price)}
          </Typography>
          <Divider width={"100%"}></Divider>
          {/* {isCartId(data?._id, cartData?.cartData)} */}
          {data?.include &&
            data?.include.length > 0 &&
            data?.include.slice(0, 3)?.map((text, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  maxWidth: "100%",
                }}
              >
                <CircleIcon sx={{ fontSize: 10, color: "gray" }} />

                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 13.5,
                    fontWeight: 400,
                    color: "gray",
                    fontFamily: "Inter",
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {text}
                </Typography>
              </Box>
            ))}

          {data?.features &&
            data?.features.length > 0 &&
            data?.features.slice(0, 3)?.map((text, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  maxWidth: "100%",
                }}
              >
                <CircleIcon sx={{ fontSize: 10, color: "gray" }} />

                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 13.5,
                    fontWeight: 400,
                    color: "gray",
                    fontFamily: "Inter",
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {text}
                </Typography>
              </Box>
            ))}
          {/* 
          <Button
           
            variant="text"
            color="primary"
            sx={{
              textTransform: "capitalize",
              fontFamily: "Inter",
            }}
          >
            View Detail
          </Button> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            width: { xs: "45%", sm: "45%", md: "30%", xl: "30%" },
            p: 1.5,
          }}
        >
          <Box sx={{ width: "100%", height: "9rem" }}>
            <img
              src={`${process.env.REACT_APP_URI}/${data?.thumnail}`}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../assets/images/g3.png");
              }}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "8px",
              }}
              alt="..."
            />
          </Box>
          <Box
            // onClick={() => setIsShow(false)}
            sx={{
              display: "flex",
              gap: 1,
              borderRadius: " 10px",
              border: "1px solid #DCDCDC",

              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "white.main",
              m: -3,
              width: "auto",
              height: "auto",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/* {console.log(existUser, "existUser")} */}
            <Typography
              variant="body1"
              color="initial"
              sx={{
                display:
                  isQuantity(
                    data?._id,
                    cartData?.cartData || ecommCartData?.cartData
                  ) >= 1
                    ? "none"
                    : "flex",
                fontSize: 15,
                textTransform: "capitalize",
                fontWeight: 600,
                color: "primary.main",
                px: 2.5,
                py: 0.5,
                cursor: "pointer",
              }}
              onClick={async () => {
                try {
                  const id = data?._id;
                  const customerId = EComm
                    ? existUser
                      ? isUserId
                      : localStorage.getItem("isUserIdDummy")
                    : existUser
                    ? isUserId
                    : localStorage.getItem("isUserIdDummy") || "";

                  // if (
                  //   EComm &&
                  //   (!isUserId ||
                  //     !existUser ||
                  //     !localStorage.getItem("isToken"))
                  // ) {
                  //   dispatch(handleModalOpen(true));
                  //   return;
                  // }

                  const url = EComm
                    ? isUserId && existUser && localStorage.getItem("isToken")
                      ? `${process.env.REACT_APP_API}/eCommerce/createCart`
                      : `${process.env.REACT_APP_API}/eCommerce/createDummyCart`
                    : isUserId && existUser && localStorage.getItem("isToken")
                    ? `${process.env.REACT_APP_API}/createCart`
                    : `${process.env.REACT_APP_API}/createDummyCart`;

                  const response = await dispatch(
                    createCartData({
                      url,
                      data: { productId: id, customerId: customerId },
                    })
                  );

                  if (response.payload.success) {
                    if (response.payload.data?.customerId) {
                      localStorage.setItem(
                        "isUserIdDummy",
                        response.payload.data.customerId
                      );
                    }
                    setIsShow(true);
                    EComm
                      ? isUserId && existUser
                        ? dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllCartBycustomerId/${isUserId}`
                            )
                          )
                        : dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
                                "isUserIdDummy"
                              )}`
                            )
                          )
                      : isUserId && existUser
                      ? dispatch(
                          getCartData(
                            `getAllCartBycustomerId/${localStorage.getItem(
                              "isUserId"
                            )}`
                          )
                        )
                      : dispatch(
                          getCartData(
                            `/getAllCartByuserId/${localStorage.getItem(
                              "isUserIdDummy"
                            )}`
                          )
                        );

                    // dispatch(
                    //   getCartData(
                    //     // EComm
                    //     //   ? `/eCommerce/getAllCartBycustomerId/${isUserId}`
                    //     //   : existUser && isUserId
                    //     //   ? `getAllCartBycustomerId/${customerId}`
                    //     //   : `getAllCartByuserId/${customerId}`

                    //     EComm
                    //       ? existUser
                    //         ? `/eCommerce/getAllCartBycustomerId/${isUserId}`
                    //         : `/eCommerce/getAllDummyCartBycustomerId/${customerId}`
                    //       : existUser && isUserId
                    //       ? `getAllCartBycustomerId/${customerId}`
                    //       : `getAllCartByuserId/${customerId}`
                    //   )
                    // );
                  } else {
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: "error",
                        msg: response.payload.message,
                      })
                    );
                  }
                } catch (error) {
                  console.error(error);
                  setIsShow(false);
                }
              }}
            >
              Add
            </Typography>

            {/* {console.log(isQuantity(data?._id, cartData?.cartData) === 0)} */}
            {/* <Typography
              variant="body1"
              color="initial"
              sx={{
                display:
                  isQuantity(data?._id, cartData?.cartData) >= 1
                    ? "none"
                    : "flex",
                fontSize: 15,
                textTransform: "capitalize",
                fontWeight: 600,
                color: "primary.main",
                px: 2.5,
                py: 0.5,
                cursor: "pointer",
              }}
              onClick={() => {
                if (EComm) {
                  if (existUser && localStorage.getItem("isToken")) {
                    // ecommerce
                    dispatch(
                      createCartData({
                        url: `${process.env.REACT_APP_API}/eCommerce/createCart`,
                        data: {
                          productId: data?._id,
                          customerId: isUserId,
                        },
                      })
                    ).then((data) => {
                      // console.log(data, "data");
                      if (data?.payload?.success) {
                        // setIsShow(false);
                        dispatch(
                          getCartData(
                            `/eCommerce/getAllCartBycustomerId/${isUserId}`
                          )
                        );
                      }
                    });
                  } else {
                    dispatch(handleModalOpen(true));
                  }
                } else if (
                  isUserId &&
                  existUser &&
                  localStorage.getItem("isToken")
                ) {
                  dispatch(
                    createCartData({
                      url: `${process.env.REACT_APP_API}/createCart`,
                      data: {
                        productId: data?._id,
                        customerId: isUserId,
                      },
                    })
                  ).then((data) => {
                    // console.log(data, "data");
                    if (data?.payload?.success) {
                      // setIsShow(false);
                      dispatch(
                        getCartData(`getAllCartBycustomerId/${isUserId}`)
                      );
                    }
                    if (!data?.payload?.success) {
                      // setIsShow(false);
                      // localStorage.setItem(
                      //   "isUserIdDummy",
                      //   data?.payload?.data?.customerId
                      // );
                      dispatch(
                        handleAlert({
                          isOpen: true,
                          type: "error",
                          msg: data?.payload?.message,
                        })
                      );
                    }
                  });
                } else {
                  dispatch(
                    createCartData({
                      url: `${process.env.REACT_APP_API}/createDummyCart`,
                      data: {
                        productId: data?._id,
                        customerId: localStorage.getItem("isUserIdDummy") || "",
                      },
                    })
                  ).then((data) => {
                    // console.log(data, "data");
                    if (data?.payload?.success) {
                      data?.payload?.data?.customerId &&
                        localStorage.setItem(
                          "isUserIdDummy",
                          data?.payload?.data?.customerId
                        );
                      setIsShow(true);
                      dispatch(
                        getCartData(
                          `/getAllCartByuserId/${data?.payload?.data?.customerId}`
                        )
                      );
                    }
                    if (!data?.payload?.success) {
                      // setIsShow(false);
                      dispatch(
                        handleAlert({
                          isOpen: true,
                          type: "error",
                          msg: data?.payload?.message,
                        })
                      );
                    }
                  });
                }
                // } else {
                //   dispatch(handleModalOpen(true));
                // }
              }}
            >
              Add
            </Typography> */}
            {/* {(existUser && localStorage.getItem("isToken")) ||
              (localStorage.getItem("isUserIdDummy") && isShow && (
                <Box
                  sx={{
                    display:
                      isQuantity(data?._id, cartData?.cartData) >= 1
                        ? "flex"
                        : "none",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      const id = isCartId(data?._id, cartData?.cartData);
                      if (EComm) {
                        dispatch(
                          updateCart({
                            url: `${process.env.REACT_APP_API}/eCommerce/quantityUpdate/${id}`,
                          })
                        ).then((data) => {
                          // console.log(data, "quantityUpdate");
                          if (data?.payload?.success) {
                            dispatch(
                              getCartData(
                                `/eCommerce/getAllCartBycustomerId/${isUserId}`
                              )
                            );
                          }
                          if (!data?.payload?.success) {
                            // setIsShow(false);
                            dispatch(
                              handleAlert({
                                isOpen: true,
                                type: "error",
                                msg: data?.payload?.message,
                              })
                            );
                          }
                        });
                      } else if (
                        isUserId &&
                        existUser &&
                        localStorage.getItem("isToken")
                      ) {
                        dispatch(
                          updateCart({
                            url: `${process.env.REACT_APP_API}/quantityUpdate/${id}`,
                          })
                        ).then((data) => {
                          // console.log(data, "quantityUpdate");
                          if (data?.payload?.success) {
                            dispatch(
                              getCartData(`getAllCartBycustomerId/${isUserId}`)
                            );
                          }
                          if (!data?.payload?.success) {
                            // setIsShow(false);
                            dispatch(
                              handleAlert({
                                isOpen: true,
                                type: "error",
                                msg: data?.payload?.message,
                              })
                            );
                          }
                        });
                      } else {
                        dispatch(
                          updateCart({
                            url: `${process.env.REACT_APP_API}/quantityUpdate/${id}`,
                          })
                        ).then((data) => {
                          // console.log(data, "quantityUpdate");
                          if (data?.payload?.success) {
                            dispatch(
                              getCartData(
                                `/getAllCartByuserId/${localStorage.getItem(
                                  "isUserIdDummy"
                                )}`
                              )
                            );
                          }
                          if (!data?.payload?.success) {
                            // setIsShow(false);
                            dispatch(
                              handleAlert({
                                isOpen: true,
                                type: "error",
                                msg: data?.payload?.message,
                              })
                            );
                          }
                        });
                      }
                    }}
                    sx={{
                      fontSize: 13.5,
                      fontWeight: 600,
                      color: "primary.main",
                      cursor: "pointer",
                    }}
                  >
                    +
                  </IconButton>
                  <IconButton
                    sx={{
                      fontSize: 13.5,
                      fontWeight: 600,
                      color: "primary.main",
                      cursor: "pointer",
                    }}
                  >
                    {isQuantity(data?._id, cartData?.cartData)}
                  {console.log(isQuantity(data?._id, cartData?.cartData))} 
                   </IconButton> 
                 
                </Box>
              ))} */}
            {((existUser && localStorage.getItem("isToken")) ||
              (localStorage.getItem("isUserIdDummy") && isShow)) && (
              <Box
                sx={{
                  display:
                    isQuantity(
                      data?._id,
                      cartData?.cartData || ecommCartData?.cartData
                    ) >= 1
                      ? "flex"
                      : "none",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={async () => {
                    try {
                      const id = isCartId(
                        data?._id,
                        cartData?.cartData || ecommCartData?.cartData
                      );
                      const url = EComm
                        ? `${process.env.REACT_APP_API}/eCommerce/quantityUpdate/${id}`
                        : isUserId &&
                          existUser &&
                          localStorage.getItem("isToken")
                        ? `${process.env.REACT_APP_API}/quantityUpdate/${id}`
                        : `${process.env.REACT_APP_API}/quantityUpdate/${id}`;

                      const response = await dispatch(updateCart({ url }));
                      if (response.payload.success) {
                        // dispatch(
                        //   getCartData(
                        //     EComm
                        //       ? isUserId
                        //         ? `/eCommerce/getAllCartBycustomerId/${isUserId}`
                        //         : `/eCommerce/getAllDummyCartBycustomerId/${isUserId}`
                        //       : isUserId
                        //       ? `getAllCartBycustomerId/${isUserId}`
                        //       : `/getAllCartByuserId/${localStorage.getItem(
                        //           "isUserIdDummy"
                        //         )}`
                        //   )
                        // );
                        EComm
                          ? isUserId && existUser
                            ? dispatch(
                                getEcommCartData(
                                  `/eCommerce/getAllCartBycustomerId/${isUserId}`
                                )
                              )
                            : dispatch(
                                getEcommCartData(
                                  `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
                                    "isUserIdDummy"
                                  )}`
                                )
                              )
                          : dispatch(
                              isUserId && existUser
                                ? dispatch(
                                    getCartData(
                                      `getAllCartBycustomerId/${localStorage.getItem(
                                        "isUserId"
                                      )}`
                                    )
                                  )
                                : dispatch(
                                    getCartData(
                                      `/getAllCartByuserId/${localStorage.getItem(
                                        "isUserIdDummy"
                                      )}`
                                    )
                                  )
                            );
                      } else {
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: response.payload.message,
                          })
                        );
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  sx={{
                    fontSize: 13.5,
                    fontWeight: 600,
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                >
                  +
                </IconButton>
                <IconButton
                  sx={{
                    fontSize: 13.5,
                    fontWeight: 600,
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                >
                  {isQuantity(
                    data?._id,
                    cartData?.cartData || ecommCartData?.cartData
                  )}
                  {/* {console.log(isQuantity(data?._id, cartData?.cartData))} */}
                </IconButton>
                <IconButton
                  onClick={async () => {
                    try {
                      const id = isCartId(
                        data?._id,
                        cartData?.cartData || ecommCartData?.cartData
                      );
                      const url = EComm
                        ? `${process.env.REACT_APP_API}/eCommerce/removeQuantity/${id}`
                        : isUserId &&
                          existUser &&
                          localStorage.getItem("isToken")
                        ? `${process.env.REACT_APP_API}/removeQuantity/${id}`
                        : `${process.env.REACT_APP_API}/removeQuantity/${id}`;

                      const response = await dispatch(updateCart({ url }));
                      if (response.payload.success) {
                        // dispatch(
                        //   getCartData(
                        //     EComm
                        //       ? `/eCommerce/getAllCartBycustomerId/${isUserId}`
                        //       : isUserId
                        //       ? `getAllCartBycustomerId/${isUserId}`
                        //       : `/getAllCartByuserId/${localStorage.getItem(
                        //           "isUserIdDummy"
                        //         )}`
                        //   )
                        // );
                        EComm
                          ? isUserId && existUser
                            ? dispatch(
                                getEcommCartData(
                                  `/eCommerce/getAllCartBycustomerId/${isUserId}`
                                )
                              )
                            : dispatch(
                                getEcommCartData(
                                  `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
                                    "isUserIdDummy"
                                  )}`
                                )
                              )
                          : dispatch(
                              isUserId && existUser
                                ? dispatch(
                                    getCartData(
                                      `getAllCartBycustomerId/${localStorage.getItem(
                                        "isUserId"
                                      )}`
                                    )
                                  )
                                : dispatch(
                                    getCartData(
                                      `/getAllCartByuserId/${localStorage.getItem(
                                        "isUserIdDummy"
                                      )}`
                                    )
                                  )
                            );
                      } else {
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: response.payload.message,
                          })
                        );
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  sx={{
                    fontSize: 13.5,
                    fontWeight: 600,
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                >
                  -
                </IconButton>
              </Box>
              // </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Choosebutton;
