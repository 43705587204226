// import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  // TextField,
  Typography,
  // useMediaQuery,
} from "@mui/material";
import React from "react";
import { handleProfileOpen } from "../../Redux/ProfileSlice";
import { getUserDetails, updateUsers } from "../../Redux/festures/userSlice";
import { handleAlert } from "../../Redux/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { handleModalOpen, handleModalOpenLogout } from "../../Redux/LoginSlice";

const ProfilePage = () => {
  // const isMobile = useMediaQuery("(max-width:870px)");

  const { userData, existUser } = useSelector((state) => state.isUsers);
  const [isDisable, setIsDisable] = useState(false);
  const [fullName, setFullName] = useState("");
  const [images, setImages] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  // console.log(userData, "userData");
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      if (userData.fullName) setFullName(userData.fullName);
    }
  }, [userData]);

  const dispatch = useDispatch();
  const isUserId = localStorage.getItem("isUserId");

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    setImages(file);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
    });
    fileReader.readAsDataURL(file);

    // e.preventDefault();
    const formData = new FormData();
    formData.append("image", file);

    dispatch(
      updateUsers({
        url: `${process.env.REACT_APP_API}/updateUser/${isUserId}`,
        data: formData,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: data?.payload?.success ? "success" : "error",
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        dispatch(getUserDetails(`/getUserById/${isUserId}`));
        // dispatch(handleProfileOpen(false));
      }
    });
  };

  useEffect(() => {
    if (isUserId || existUser) {
      setIsDisable(false);
    } else {
      // navigate("/home-page");
      setIsDisable(true);
      dispatch(handleModalOpen(true));
    }
  }, [existUser, isUserId]);

  return (
    <Box
      sx={{
        width: "100%",
        p: 2.5,
        position: "relative",
      }}
    >
      {isDisable && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: 11,
            opacity: 0.9,
            backgroundColor: "white.main",
            top: 0,
            left: 0,
          }}
        />
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            mt: 0.6,
            height: 200,
            width: 200,
            border: 2,
            borderColor: "primary.main",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <label htmlFor="imageuploader22">
            {" "}
            <img
              src={require("../../assets/Iconly/Light/Edit Square.png")}
              alt="edit button"
              style={{
                height: "3.5rem",
                width: "3.5rem",
                position: "absolute",
                bottom: "-5px",
                right: "-5px ",
                cursor: "pointer",
                borderRadius: "50%",
                zIndex: 10,
              }}
            />
          </label>
          <input
            required
            type="file"
            accept="image/*"
            hidden
            onChange={handleSelectImage}
            id={"imageuploader22"}
          />

          <img
            src={
              previewImage
                ? previewImage
                : userData?.image
                ? `${process.env.REACT_APP_URI}/${userData?.image}`
                : ""
            }
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../../assets/images/avatar.jpg");
            }}
            alt="edit button"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        </Box>
        <Typography
          variant="h6"
          color="initial"
          fontWeight={700}
          sx={{
            textAlign: "center",
            maxWidth: "90%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {userData?.fullName}
        </Typography>
        <Typography variant="h6" color="initial" fontWeight={600}></Typography>

        <Divider
          sx={{
            borderWidth: 0.5,
            borderColor: "primary.main",
            width: "100%",
            my: 0.5,
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
            onClick={() => dispatch(handleProfileOpen(true))}
          >
            <Box sx={{ height: "2.5rem", width: "2.5rem" }}>
              <img
                src={require("../../assets/Iconly/Light/Profile.png")}
                style={{ width: "100%", height: "100%" }}
                alt="edit"
              />
            </Box>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                display: "flex",
                flex: 1,
                fontSize: "1.3rem",
                fontWeight: 600,
              }}
            >
              Edit Profile
            </Typography>
            <ArrowForwardIos />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
            onClick={() => {
              navigate("/privatePolicy-page");
            }}
          >
            <Box sx={{ height: "2.5rem", width: "2.5rem" }}>
              <img
                src={require("../../assets/Iconly/Light/Lock.png")}
                style={{ width: "100%", height: "100%" }}
                alt="edit"
              />
            </Box>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                display: "flex",
                flex: 1,
                fontSize: "1.3rem",
                fontWeight: 600,
              }}
            >
              Privacy Policy
            </Typography>
            <ArrowForwardIos />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
            onClick={() => {
              navigate("/about-page");
            }}
          >
            <Box sx={{ height: "2.5rem", width: "2.5rem" }}>
              <img
                src={require("../../assets/Iconly/Light/Info Square.png")}
                style={{ width: "100%", height: "100%" }}
                alt="edit"
              />
            </Box>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                display: "flex",
                flex: 1,
                fontSize: "1.3rem",
                fontWeight: 600,
              }}
            >
              About Us
            </Typography>
            <ArrowForwardIos />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
            onClick={() => {
              navigate("/contact-page");
            }}
          >
            <Box sx={{ height: "2.5rem", width: "2.5rem" }}>
              <img
                src={require("../../assets/Iconly/Light/ant-design_message-outlined.png")}
                style={{ width: "100%", height: "100%" }}
                alt="edit"
              />
            </Box>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                display: "flex",
                flex: 1,
                fontSize: "1.3rem",
                fontWeight: 600,
              }}
            >
              Contact Us
            </Typography>
            <ArrowForwardIos />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
            onClick={() => {
              navigate("/account_delete");
            }}
          >
            <Box sx={{ height: "2.5rem", width: "2.5rem" }}>
              <img
                src={require("../../assets/Iconly/Light/Profile.png")}
                style={{ width: "100%", height: "100%" }}
                alt="edit"
              />
            </Box>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                display: "flex",
                flex: 1,
                fontSize: "1.3rem",
                fontWeight: 600,
              }}
            >
              Delete Account
            </Typography>
            <ArrowForwardIos />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
            onClick={() => {
              dispatch(handleModalOpenLogout(true));
            }}
          >
            <Box sx={{ height: "2.5rem", width: "2.5rem" }}>
              <img
                src={require("../../assets/Iconly/Light/Logout.png")}
                style={{ width: "100%", height: "100%" }}
                alt="edit"
              />
            </Box>
            <Typography
              variant="body1"
              color="primary"
              sx={{
                display: "flex",
                flex: 1,
                fontSize: "1.3rem",
                fontWeight: 600,
              }}
            >
              Logout
            </Typography>
            <ArrowForwardIos />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePage;
