import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleModalOpenLogout } from "../../Redux/LoginSlice";
import { handleExistUser } from "../../Redux/festures/userSlice";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LogoutConfirm({
  dialogTitle,
  dialogContent,
  dialogAction,
  open,
  setOpen,
}) {
  const { isOpenLogout } = useSelector((state) => state.isLoginModal);
  //   console.log(isOpenLogout, "isOpenLogout");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Dialog
      open={isOpenLogout}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        // dispatch(handleModalOpenLogout(false));
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {" "}
        <Typography variant="h4" fontWeight={700}>
          Logout
        </Typography>
        <Divider
          sx={{
            borderWidth: 0.5,
            borderColor: "primary.main",
            width: "100%",
            mt: 1,
          }}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography variant="h6" fontWeight={600}>
            Are you sure you want to logout?
          </Typography>
          <Typography variant="subtitle2" fontWeight={500}>
            This is the Permanently logged out !
          </Typography>
        </DialogContentText>
      </DialogContent>
      {/* <DialogTitle></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{dialogContent}</DialogContentText>
      </DialogContent> */}
      <DialogActions>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="text"
            color="primary"
            sx={{ width: "50%" }}
            onClick={() => {
              dispatch(handleModalOpenLogout(false));
            }}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            sx={{ width: "50%" }}
            color="info"
            onClick={() => {
              localStorage.removeItem("isUserId");
              localStorage.removeItem("isToken");
              localStorage.removeItem("credential");
              localStorage.removeItem("userVerify");
              localStorage.removeItem("isUserIdDummy");
              navigate("/home=page");
              dispatch(handleExistUser(false));
              dispatch(handleModalOpenLogout(false));
            }}
          >
            yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
