import React from "react";

import { Box, Container, Typography } from "@mui/material";
import { sanitize } from "dompurify";
import { useSelector } from "react-redux";

const TermsAndCondition = () => {
  const { company } = useSelector((state) => state.isCompany);
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          mt: 4,
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontSize: 25,
            fontWeight: 800,
            // fontFamily: "Inter",
            // letterSpacing: 2,
            fontFamily: "serif",
          }}
        >
          Terms & Condition
        </Typography>
        <Typography
          variant="body1"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            // "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            lineHeight: "30px",
            maxWidth: "100%",
          }}
          dangerouslySetInnerHTML={{
            __html: sanitize(company?.term_condition),
          }}
        />
      </Box>
    </Container>
  );
};

export default TermsAndCondition;
