import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  getNotification,
  getNotificationCount,
  openNoti,
} from "../../Redux/NotiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { NotificationBellIcon } from "../../Utils/Extra";
import { formatDate } from "../../Utils/dataFunction";
import SkError from "../SkError";
import { handleSwitch } from "../../Redux/festures/orderSlice";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
}));

export default function SkMenu() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const isUserId = localStorage.getItem("isUserId");
  const { isOpen, Notification } = useSelector((state) => state?.isNoti);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        getNotification(
          `${process.env.REACT_APP_API}/getByUserId/${isUserId}?userType=CUSTOMER`
        )
      ).then((res) => {
        if (res?.payload) {
          dispatch(
            getNotificationCount(
              `${process.env.REACT_APP_API}/seenCount/${isUserId}?userType=CUSTOMER`
            )
          );
        }
      });
    }
  }, [isOpen]);

  return (
    <div>
      <BootstrapDialog
        onClose={() => {
          dispatch(openNoti(false));
          dispatch(
            getNotificationCount(
              `${process.env.REACT_APP_API}/seenCount/${isUserId}?userType=CUSTOMER`
            )
          );
        }}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Notifications
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => dispatch(openNoti(false))}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              width: "0.4em",
              display: "none",
            },
          }}
        >
          {Notification && Notification?.length > 0 ? (
            Notification?.map((value, index) => (
              <List
                key={index}
                sx={{
                  width: "100%",
                  maxWidth: 360,

                  cursor: "pointer",
                }}
                onClick={() => {
                  if (value?.type === "ECOM_ORDERED") {
                    dispatch(handleSwitch(true));
                    dispatch(openNoti(false));
                    navigate("/order-page", { state: value?.orderId });
                  } else if (value?.type === "ORDERED") {
                    dispatch(handleSwitch(false));
                    navigate("/order-page", { state: value?.orderId });
                    dispatch(openNoti(false));
                  }
                }}
              >
                {/* {console.log(value, "value")} */}
                <ListItem
                  alignItems="flex-start"
                  gap={1}
                  sx={{
                    bgcolor: "background.paper",
                    "&:hover": {
                      backgroundColor: "#D3D3D3",
                      color: "white",
                      "& .MuiListItemIcon-root": {
                        color: "white",
                      },
                    },
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 60,
                      width: 60,
                      borderRadius: "50%",
                      bgcolor: "#DEDEDE",
                      mr: 1,
                    }}
                  >
                    {NotificationBellIcon}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{ display: "block" }}
                        variant="body1"
                        fontWeight={600}
                        color="primary"
                      >
                        {value?.title}
                      </Typography>
                    }
                    // secondary={`${value?.message}`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "block" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {`${value?.message}`}
                        </Typography>
                        {` — ${formatDate(value?.createdAt)}`}
                      </React.Fragment>
                    }
                  />

                  {/* <ListItemText  primary={formatDate(value?.createdAt)} /> */}
                </ListItem>
                <Divider />
              </List>
            ))
          ) : (
            <SkError type={"Error"} rate={true} />
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
