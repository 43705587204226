import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";
import axios from "axios";

export const getPincode = createAsyncThunk(
  "pinCode/getPincode",
  async ({ url }) => {
    // console.log(url)
    try {
      const res = await axios.get(url);
      return await res.data;
    } catch (error) {
      return error;
    }
  }
);
export const getSingleAddress = createAsyncThunk(
  "getSingleAddress",
  async (url) => {
    try {
      const res = await http.get(url);
      return res?.data;
    } catch (error) {
      return { data: null };
    }
  }
);
export const getAllAddress = createAsyncThunk("getAllAddress", async (url) => {
  try {
    const res = await http.get(url);
    return res?.data;
  } catch (error) {
    return { data: null };
  }
});
export const createAddress = createAsyncThunk(
  "createAddress",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const updateAddress = createAsyncThunk(
  "updateAddress",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("isToken"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);
export const deleteAddress = createAsyncThunk(
  "deleteAddress",
  async ({ url }) => {
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("isToken"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);
const addressSlice = createSlice({
  name: "address",
  initialState: {
    loading: false,
    addressData: null,
    singleAddress: null,
    pinCode: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSingleAddress.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSingleAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.singleAddress = action?.payload?.data;
      })
      .addCase(getSingleAddress.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getPincode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPincode.fulfilled, (state, action) => {
        state.loading = false;
        state.pinCode = action?.payload?.data;
      })
      .addCase(getPincode.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllAddress.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.addressData = action?.payload?.data;
      })
      .addCase(getAllAddress.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default addressSlice.reducer;
