import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import http from "../../Utils/Api";
import { Box } from "@mui/material";

const ProfessionalSection = () => {
  const [isData, setIsData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await http.get(`/getBypartnerProfilehome`);
        //   console.log(res);
        if (res?.data?.success) {
          setIsData(res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          cursor: "pointer",
          p: 1,
        }}
        onClick={() => {
          let link = isData?.link;

          if (link) {
            if (link.startsWith("www")) {
              link = `https://${link}`;
            }

            const win = window.open(link, "_self");
            if (win) {
              win.focus();
            }
          }
        }}
      >
        <img
          src={`${process.env.REACT_APP_URI}/${isData?.banner}`}
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "4px",
          }}
          onError={(e) => {
            e.onerror = null;
            e.target.src = require("../../assets/images/banner.png");
          }}
          alt="..."
        />
      </Box>
    </>
  );
};

export default ProfessionalSection;
