import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import CardPageCard from "../../examples/CardPageCard";

import { useNavigate } from "react-router-dom";
import BasicModal from "../../components/Modal";

import MobileCartPageCard from "../../examples/MobileCartPageCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons } from "../../Redux/festures/couponSlice";

import { SkPrice } from "../../Utils/dataFunction";
import { getEcommCartData } from "../../Redux/festures/cartSlice";
import img1 from "../../assets/images/1svg1.svg";
import SkError from "../../components/SkError";
import AddressCart2 from "../../examples/AddressCart2";
import { handleAlert } from "../../Redux/alertSlice";
import { createOrder } from "../../Redux/festures/orderSlice";
import {
  handleSetOrderId,
  handleOrderOpen,
  handleSetOrderEComm,
} from "../../Redux/OrderModalSlice";
import {
  AddTask,
  ExpandLess,
  ExpandMore,
  RemoveCircle,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { handleModalOpen } from "../../Redux/LoginSlice";
import { handlePaymentOption } from "../../Redux/ProfileSlice";
import axios from "axios";
// import Address from "../../components/Address";
// import Address from "../../components/Address/Address";

const IsSpecialCart = ({ value, i, setCouponOption, setIsSpecialOffer }) => {
  const dispatch = useDispatch();
  return (
    <List
      component="div"
      disablePadding
      sx={{
        backgroundColor: i % 2 === 0 ? "#f2f2f2" : "white.main",
        "& : hover": {
          backgroundColor: i % 2 === 0 ? "#f2f2f2" : "white.main",
        },
        display: "flex",
      }}
    >
      <ListItemButton
        sx={{
          px: 1.5,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <ListItemText
          sx={{ maxWidth: ` calc(100% - 110px)` }}
          primary={
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontWeight: 600,
                fontSize: 16,
                maxWidth: `100%`,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Coupon : {value?.couponName}
            </Typography>
          }
        />
        <ListItemText
          sx={{ maxWidth: 100 }}
          primary={
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontWeight: 800, fontSize: 16 }}
            >
              Off : {value?.couponPercent}%
            </Typography>
          }
        />
        <ListItemIcon
          onClick={() => {
            if (localStorage.getItem("isUserId")) {
              setIsSpecialOffer(false);
              setCouponOption((prev) => ({
                ...prev,
                couponName: value?.couponName,
                couponCode: value?.couponCode,
                _id: value?._id,
              }));
              window.scrollTo({ top: 0, behavior: "smooth" });
            } else dispatch(handleModalOpen(true));
          }}
        >
          <AddTask
            sx={{
              color: "primary.main",
              mx: 0.5,
            }}
          />{" "}
          <Typography variant="body1" color="primary" fontWeight={600}>
            {" "}
            Apply
          </Typography>
        </ListItemIcon>
      </ListItemButton>
      {/* <IconButton
          aria-label="apply_coupon"
          sx={{
            maxWidth: 50,
          }}
          onClick={() => {
            setIsSpecialOffer(false);
            setCouponOption((prev) => ({
              ...prev,
              couponName: value?.couponName,
              couponCode: value?.couponCode,
              _id: value?._id,
            }));
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <AddTask />
        </IconButton> */}
      {/* <ListItemButton
        variant="contained"
        color="primary"
        size="small"
        sx={{
          textTransform: "capitalize",
        }}
      >
        apply
      </ListItemButton> */}
    </List>
  );
};

const EcommCart = () => {
  const [isData, setIsData] = useState({
    customerId: null,
    addressId: null,
    paymentMethod: "ONLINE",
  });
  const isMobile = useMediaQuery("(max-width:870px)");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { ecommCartData } = useSelector((state) => state.isCarts);
  const navigate = useNavigate();
  const isUserId = localStorage.getItem("isUserId");
  const { checked } = useSelector((state) => state.isOrders);
  const { existUser } = useSelector((State) => State.isUsers);

  useEffect(() => {
    if (checked && isUserId) {
      dispatch(
        getEcommCartData(`/eCommerce/getAllCartBycustomerId/${isUserId}`)
      );
    } else if (localStorage.getItem("isUserIdDummy")) {
      dispatch(
        getEcommCartData(
          `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
            "isUserIdDummy"
          )}`
        )
      );
    }
    setIsData((prev) => ({ ...prev, customerId: isUserId }));
  }, [dispatch, isUserId, localStorage.getItem("isUserIdDummy")]);

  // console.log(ecommCartData, "ecommCartData");
  const [isCouponOption, setCouponOption] = useState({
    couponName: "",
    couponCode: "",
    _id: "",
  });

  const [isShowCouponOption, setShowCouponOption] = useState({
    isOpen: false,
    type: null,
    msg: null,
  });

  useEffect(() => {
    if (isCouponOption?.couponCode !== "") {
      dispatch(
        getEcommCartData(
          `/eCommerce/getAllCartBycustomerId/${isUserId}?couponCode=${isCouponOption?.couponCode}`
        )
      ).then((data) => {
        if (data?.payload?.success) {
          setTimeout(() => {
            setShowCouponOption((prev) => ({
              ...prev,
              isOpen: false,
              type: null,
              msg: null,
            }));
          }, 30000);
          setShowCouponOption((prev) => ({
            ...prev,
            isOpen: data?.payload?.success,
            type: data?.payload?.isSuccess ? "#013220" : "primary.main",
            msg: data?.payload?.isMessage,
          }));
        }
      });
    }
  }, [isCouponOption]);

  // console.log(isCouponOption, "isCouponOption");
  const [myLoading, setMyLoading] = useState(false);
  const [isSpecialOffer, setIsSpecialOffer] = useState(false);
  const [isCoupons, setIsCoupons] = useState([]);
  const [isPage, setIsPage] = useState(1);
  const [isHasMore, setIsHasMore] = useState(true);
  // console.log(isHasMore, "fgsdfg");
  const isFetchData = async () => {
    dispatch(
      getAllCoupons(`/getAllCoupon?type=ECOM_CATEGORY&page=${isPage}`)
    ).then((data) => {
      if (data?.payload?.data?.length > 0) {
        setIsCoupons((prevData) => [...prevData, ...data?.payload?.data]);
        setIsPage(isPage + 1);
      } else {
        setIsHasMore(false);
      }
    });
  };
  useEffect(() => {
    isFetchData();
  }, []);

  // const handleCheckPaymentStatus = async (merchantId) => {
  //   console.log(merchantId);
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_API}/PhonePayGatewayCheckStatus?merchantTransactionId=${merchantId}`
  //     );
  //     console.log(res, "resPyament sytaus");
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  // console.log("isMobileNumber", isMobileNumber);
  const isMobileNumber = JSON.parse(localStorage.getItem("credential"));
  const handleDigitalPayment = async ({ orderId, userId, amount }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/PhonePayGateway`,
        {
          orderId: orderId,
          amount: amount,
          userId: userId,
          mobileNumber: isMobileNumber?.phoneNumber,
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("phoneapy", res);
      if (res?.data?.success) {
        localStorage.setItem(
          "merchantId",
          res?.data?.data?.data?.merchantTransactionId
        );
      }
      // return res?.data;
      if (res?.data?.success) {
        const url =
          res?.data?.data?.data?.instrumentResponse?.redirectInfo?.url;
        if (url) {
          // window.open(name);
          // var myWindow = window.open("", "response", "resizable=yes");
          // myWindow.document.open(name);
          // var win =window.location.replace(name);
          // win.focus();
          var win = window.open(url, "_self");
          // var win = window.open(
          //   url,
          //   "targetWindow",
          //   `toolbar=no,
          //    location=no,
          //    status=no,
          //                           menubar=no,
          //                           scrollbars=yes,
          //                           resizable=yes,
          //                           width=SomeSize,
          //                           height=SomeSize`
          // );
          win.focus();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCreateOrder = () => {
    setMyLoading(true);
    dispatch(
      createOrder({
        url: `${process.env.REACT_APP_API}/eCommerce/createOrder`,
        data: {
          ...ecommCartData?.billDetail,
          customerId: isData?.customerId,
          addressId: isData?.addressId,
          paymentMethod: isData?.paymentMethod,
        },
      })
    ).then((data) => {
      setMyLoading(false);
      dispatch(
        handleAlert({
          isOpen: true,
          type: data?.payload?.success ? "success" : "error",
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        // console.log(data);
        if (isData?.paymentMethod === "ONLINE") {
          handleDigitalPayment({
            orderId: data?.payload?.data?._id,
            userId: data?.payload?.data?.customerId,
            amount: data?.payload?.data?.orderTotal,
          });
        } else {
          dispatch(handleOrderOpen(true));
        }

        dispatch(handleSetOrderEComm(true));
        dispatch(handleSetOrderId(data?.payload?.data?._id));
      }
    });
  };
  return (
    <>
      {isMobile ? (
        <>
          {ecommCartData && ecommCartData?.cartData?.length > 0 ? (
            <Box
              sx={{ display: "flex", flexDirection: "column", p: 1, gap: 2 }}
            >
              {ecommCartData &&
                ecommCartData?.cartData?.length > 0 &&
                ecommCartData?.cartData?.map((value, index) => (
                  <MobileCartPageCard key={index} value={value} EComm={true} />
                ))}
              {/* <Button
            variant="outlined"
            color="primary"
            sx={{ color: "red", border: 1, borderColor: "red" }}
          >
            ADD MORE ITEMS
          </Button> */}
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  border: 1,
                  borderColor: "gray.border",
                  borderRadius: 2,
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 1.5,
                    p: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{ fontSize: 20, fontWeight: 600 }}
                  >
                    Order Summary
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        fontSize: 15,
                        fontWeight: 400,
                        fontFamily: "Inter",
                      }}
                    >
                      Total price
                    </Typography>
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        fontSize: 15,
                        fontWeight: 500,
                        fontFamily: "Inter",
                      }}
                    >
                      {SkPrice(ecommCartData?.billDetail?.netAmount)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        fontSize: 15,
                        fontWeight: 400,
                        fontFamily: "Inter",
                      }}
                    >
                      Taxes and Fee
                    </Typography>
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        fontSize: 15,
                        fontWeight: 500,
                        fontFamily: "Inter",
                      }}
                    >
                      {SkPrice(ecommCartData?.billDetail?.taxAmount)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      onClick={() => setIsSpecialOffer(!isSpecialOffer)}
                    >
                      <ListItemIcon>
                        <Box sx={{ width: 25, height: 25 }}>
                          <img
                            src={require("../../assets/persent.png")}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            alt="..."
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText primary="Coupon and Offer" />
                      {isSpecialOffer ? <ExpandLess /> : <ExpandMore />}
                      {ecommCartData?.billDetail?.couponDiscount > 0 && (
                        <IconButton
                          aria-label="removeCoupon"
                          onClick={(e) => {
                            e.stopPropagation();
                            // alert("hh");/
                            setCouponOption((prev) => ({
                              ...prev,
                              couponName: null,
                              couponCode: null,
                              _id: null,
                            }));
                          }}
                          sx={{
                            color: "primary.main",
                            textTransform: "capitalize",
                            fontWeight: 600,
                          }}
                        >
                          <RemoveCircle />
                        </IconButton>
                      )}
                    </ListItemButton>
                    <Collapse in={isSpecialOffer} timeout="auto" unmountOnExit>
                      {isCoupons &&
                        isCoupons.length > 0 &&
                        isCoupons.map((data, index) => (
                          <IsSpecialCart
                            key={index}
                            value={data}
                            i={index}
                            setCouponOption={setCouponOption}
                            setIsSpecialOffer={setIsSpecialOffer}
                          />
                        ))}
                    </Collapse>
                  </Box>
                  {!isSpecialOffer && isShowCouponOption?.isOpen && (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 13.5,
                          fontWeight: 800,
                          color: isShowCouponOption?.type,
                        }}
                      >
                        {isShowCouponOption?.msg}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{ fontSize: 18, fontWeight: 400, color: "red" }}
                    >
                      Discount
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ fontSize: 18, fontWeight: 500, color: "red" }}
                      >
                        -{" "}
                        {ecommCartData?.billDetail?.totalOfferDiscount
                          ? SkPrice(
                              ecommCartData?.billDetail?.totalOfferDiscount
                            )
                          : 0}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Add Address
              </Button>{" "} */}
              {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 2,
              border: 1,
              borderColor: "gray.border",
              borderRadius: 2,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 15, fontWeight: 600, color: "gray.border" }}
                >
                  Delivery to :
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 15, fontWeight: 600 }}
                >
                  Pawan kumar, 825301
                </Typography>
              </Box>

              <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontSize: 15,
                  fontWeight: 600,
                  color: "gray.border",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Bjp Building, premnagar near shiv Bjp Building, premnagar near
                shiv...
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              sx={{
                bgcolor: "white.main",
                color: "#000",
                borderRadius: 10,
                width: "40%",
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "white.main",
                },
              }}
            >
              change
            </Button>
          </Box> */}
              {isData?.addressId && isData?.customerId && (
                <FormControl
                  style={{
                    display: "flex",
                    padding: 1,
                    margin: "0",
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <RadioGroup
                    row
                    style={{ display: "flex", flexDirection: "row" }}
                    value={isData?.paymentMethod}
                    defaultValue={"ONLINE"}
                    name="radio-buttons-group"
                    onChange={(e) =>
                      setIsData((prev) => ({
                        ...prev,
                        paymentMethod: e.target.value,
                      }))
                    }
                  >
                    <FormControlLabel
                      // disabled
                      value="ONLINE"
                      control={<Radio />}
                      label="Digital Payment"
                    />

                    <FormControlLabel
                      disabled
                      value="COD"
                      control={<Radio />}
                      label="Cash On Delivery"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              <Box
                sx={{
                  width: "100%",
                  height: "10vh",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "space-evenly",
                  boxShadow:
                    "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25)",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{ fontSize: 15, fontWeight: 800 }}
                  >
                    {SkPrice(ecommCartData?.billDetail?.orderTotal)}
                  </Typography>
                  <Button
                    variant="text"
                    color="primary"
                    sx={{
                      textTransform: "capitalize",
                      color: "rgba(46, 106, 160, 1)",
                    }}
                  >
                    Total Price
                  </Button>
                </Box>
                {isData?.addressId &&
                isData?.customerId &&
                isData?.paymentMethod ? (
                  <LoadingButton
                    loading={myLoading}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 10,
                      width: "40%",
                      height: "50%",
                    }}
                    onClick={() => {
                      handleCreateOrder();
                    }}
                  >
                    Order Place
                  </LoadingButton>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: 10,
                      width: "40%",
                      height: "50%",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      if (existUser && isUserId) {
                        setIsOpen(true);
                      } else {
                        dispatch(handleModalOpen(true));
                      }
                    }}
                  >
                    Add Address
                  </Button>
                )}
              </Box>
            </Box>
          ) : (
            // </Box>
            <SkError EComm={true} type={"Cart"} />
          )}
        </>
      ) : (
        <>
          <Container maxWidth="lg">
            {ecommCartData && ecommCartData?.cartData?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  justifyContent: "center",
                  // alignItems: "center",
                  height: "100%",
                  mt: 5,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    width: "55%",
                    height: "100%",
                    border: 1,
                    borderColor: "#D1D1D1",
                    borderRadius: 2,
                    p: 4,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    my: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{ fontSize: 20, fontWeight: 600 }}
                  >
                    Added Products
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      gap: 4,
                      p: 1,
                      alignItems: "center",
                      overflowY: "scroll",
                      width: "100%",
                      maxHeight: {
                        xs: "100%",
                        md: "150vh",
                        xl: "150vh",
                      },
                      scrollBehavior: "smooth",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {ecommCartData?.cartData &&
                      ecommCartData?.cartData?.length > 0 &&
                      ecommCartData?.cartData?.map((data, index) => (
                        <CardPageCard key={index} value={data} EComm={true} />
                      ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                    width: "40%",
                    height: "100%",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{ fontSize: 20, fontWeight: 600 }}
                    >
                      Order Summary
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 15,
                          fontWeight: 400,
                          fontFamily: "Inter",
                        }}
                      >
                        Total price
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 15,
                          fontWeight: 500,
                          fontFamily: "Inter",
                        }}
                      >
                        {SkPrice(ecommCartData?.billDetail?.netAmount)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 15,
                          fontWeight: 400,
                          fontFamily: "Inter",
                        }}
                      >
                        Taxes and Fee
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 15,
                          fontWeight: 500,
                          fontFamily: "Inter",
                        }}
                      >
                        {SkPrice(ecommCartData?.billDetail?.taxAmount)}
                      </Typography>
                    </Box>
                    {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{ fontSize: 15, fontWeight: 400, fontFamily: "Inter" }}
                  >
                    Tax Percentage
                  </Typography>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{ fontSize: 15, fontWeight: 500, fontFamily: "Inter" }}
                  >
                    {ecommCartData?.billDetail?.taxPercentage}%
                  </Typography>
                </Box> */}
                    <Box
                      sx={{
                        width: "100%",
                        p: 0,
                      }}
                    >
                      <ListItemButton
                        onClick={() => setIsSpecialOffer(!isSpecialOffer)}
                      >
                        <ListItemIcon>
                          <Box sx={{ width: 25, height: 25 }}>
                            <img
                              src={require("../../assets/persent.png")}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              alt="..."
                            />
                          </Box>
                        </ListItemIcon>
                        <ListItemText primary="Coupon and Offer" />
                        {isSpecialOffer ? <ExpandLess /> : <ExpandMore />}
                        {ecommCartData?.billDetail?.couponDiscount > 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              // alert("hh");/
                              setCouponOption((prev) => ({
                                ...prev,
                                couponName: null,
                                couponCode: null,
                                _id: null,
                              }));
                            }}
                            sx={{
                              color: "primary.main",
                              textTransform: "capitalize",
                              fontWeight: 600,
                            }}
                          >
                            remove
                          </Button>
                        )}
                      </ListItemButton>
                      <Collapse
                        in={isSpecialOffer}
                        timeout="auto"
                        unmountOnExit
                      >
                        {isCoupons &&
                          isCoupons.length > 0 &&
                          isCoupons.map((data, index) => (
                            <IsSpecialCart
                              key={index}
                              value={data}
                              i={index}
                              setCouponOption={setCouponOption}
                              setIsSpecialOffer={setIsSpecialOffer}
                            />
                          ))}
                      </Collapse>
                    </Box>
                    {!isSpecialOffer && isShowCouponOption?.isOpen && (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            fontSize: 13.5,
                            fontWeight: 800,
                            color: isShowCouponOption?.type,
                          }}
                        >
                          {isShowCouponOption?.msg}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ fontSize: 18, fontWeight: 400, color: "red" }}
                      >
                        Discount
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{ fontSize: 18, fontWeight: 500, color: "red" }}
                        >
                          -{" "}
                          {ecommCartData?.billDetail?.totalOfferDiscount
                            ? SkPrice(
                                ecommCartData?.billDetail?.totalOfferDiscount
                              )
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: 0.5,
                        borderColor: "gray.border",
                        width: "100%",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ fontSize: 18, fontWeight: 600 }}
                      >
                        Total
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ fontSize: 18, fontWeight: 600 }}
                      >
                        {SkPrice(ecommCartData?.billDetail?.orderTotal)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: 0.5,
                        borderColor: "gray.border",
                        width: "100%",
                      }}
                    ></Box>
                    {isData?.addressId && isData?.customerId && (
                      <FormControl
                        style={{
                          display: "flex",
                          padding: "0",
                          margin: "0",
                          width: "100%",
                          flexDirection: "row",
                        }}
                      >
                        <RadioGroup
                          row
                          style={{ display: "flex", flexDirection: "row" }}
                          value={isData?.paymentMethod}
                          defaultValue={"ONLINE"}
                          name="radio-buttons-group"
                          onChange={(e) =>
                            setIsData((prev) => ({
                              ...prev,
                              paymentMethod: e.target.value,
                            }))
                          }
                        >
                          <FormControlLabel
                            // disabled
                            value="ONLINE"
                            control={<Radio />}
                            label="Digital Payment"
                          />

                          <FormControlLabel
                            value="COD"
                            control={<Radio />}
                            label="Cash On Delivery"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Box>
                  {isData?.addressId &&
                  isData?.customerId &&
                  isData?.paymentMethod ? (
                    <LoadingButton
                      loading={myLoading}
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => {
                        handleCreateOrder();
                      }}
                    >
                      Order Place
                    </LoadingButton>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => {
                        if (existUser && isUserId) {
                          setIsOpen(true);
                        } else {
                          dispatch(handleModalOpen(true));
                        }
                        // setIsOpen(true);
                      }}
                    >
                      Add Address
                    </Button>
                  )}
                  {/* <Box
                // sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4,  }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 1,
                  gap: 2,
                  my: 1,
                  overflowY: "scroll",
                  height: {
                    xs: "100%",
                    md: "calc(100vh - 50px)",
                    xl: "calc(100vh - 50px)",
                  },
                  scrollBehavior: "smooth",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <InfiniteScroll
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "8px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    height: "auto",
                    width: "100%",
                  }}
                  dataLength={isCoupons.length || 0}
                  next={isFetchData}
                  hasMore={isHasMore}
                  loader={<CircularProgress />}
                  endMessage={<p>No More Coupons To Load...</p>}
                >
                  {isCoupons &&
                    isCoupons.length > 0 &&
                    isCoupons.map((data, index) => (
                      <SpecialOffer key={index} value={data} i={index} />
                    ))}
                </InfiniteScroll>
              </Box> */}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box sx={{ width: "50%", height: "calc(100vh - 200px)" }}>
                  <img
                    src={img1}
                    alt={"...."}
                    style={{ height: "100%", width: "100%" }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate("/e_home-page")}
                >
                  go back to more services
                </Button>
              </Box>
            )}
          </Container>
        </>
      )}
      {/* {console.log(isData, "isData")} */}
      <BasicModal
        show={isOpen}
        unShow={setIsOpen}
        overflowY={true}
        height={"auto"}
        maxWidth={"90%"}
        title={"Added address"}
      >
        <AddressCart2
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setIsData={setIsData}
          isData={isData}
        />
      </BasicModal>
    </>
  );
};

export default EcommCart;
