import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SkConfirm({
  dialogTitle,
  dialogContent,
  dialogAction,
  open,
  setOpen,
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setOpen(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {" "}
        <Typography variant="h4" fontWeight={700}>
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography variant="h6" fontWeight={600}>
            {dialogContent}
          </Typography>
          <Typography variant="subtitle2" fontWeight={500}>
            This is the All products of the Order is canceled !
          </Typography>
        </DialogContentText>
      </DialogContent>
      {/* <DialogTitle></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{dialogContent}</DialogContentText>
      </DialogContent> */}
      <DialogActions>{dialogAction}</DialogActions>
    </Dialog>
  );
}
