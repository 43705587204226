import { ShoppingCart } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { pages } from "../../Utils/Constant/Services";
// import BasicModal from "../Modal";
import { handleModalOpen, handleModalOpenLogout } from "../../Redux/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleProfileOpen } from "../../Redux/ProfileSlice";
import { handleSwitch } from "../../Redux/festures/orderSlice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badges from "../Badges";
import {
  getNotification,
  getNotificationCount,
  openNoti,
} from "../../Redux/NotiSlice";
import NotiCard from "../../examples/NotiCard";
import SkMenu from "../SkMenu";
import { useEffect } from "react";
// import { handleExistUser } from "../../Redux/festures/userSlice";
const pages1 = ["Register As A Professional", "Help", "Login/ Sign up"];
// const settings = ["Home", "Service", "About Us", "Contact Us"];

const IsTransparentNavbar = ({ isUserVerify }) => {
  const { existUser, userData } = useSelector((State) => State.isUsers);
  const { checked } = useSelector((state) => state.isOrders);
  const { count } = useSelector((state) => state?.isNoti);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  // console.log(count?.count);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const dispatch = useDispatch();

  return (
    <>
      <AppBar
        elevation={0}
        position="static"
        top={5}
        sx={{
          "&.MuiAppBar-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            "& 	.MuiContainer-root": {
              bgcolor: "transparent",
            },
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              "& 	.MuiToolbar-root": {
                backgroundColor: "transparent",
                color: "white",
                fontFamily: "inter",
              },
            }}
          >
            <Box
              sx={{ width: "5rem", height: "100%" }}
              // component={Link}
              // to="/home-page"
              onClick={() =>
                checked ? navigate("/e_home-page") : navigate("/home-page")
              }
            >
              <img
                src={require("../../assets/images/logo.png")}
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                alt="logo"
                title="easy_solution"
              />
            </Box>

            {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuBookOutlined />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page?.name} onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      component={Link}
                      to={page?.link}
                    >
                      {page?.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}
            <Box
              sx={{
                width: "5rem",
                height: "100%",
                display: { xs: "flex", md: "none" },
              }}
              component={Link}
              to="/home-page"
            >
              <img
                src={require("../../assets/images/logo.png")}
                style={{ width: "100%", height: "100%", cursor: "pointer"  }}
                alt="logo"
                title="easy_solution"
              />
            </Box>
            <Box sx={{ flexGrow: 1 }} />

            {existUser && localStorage.getItem("isUserId") ? (
              <>
                <Box sx={{ flexGrow: 0 }}>
                  <IconButton
                    aria-label="cartButton"
                    onClick={() =>
                      checked
                        ? navigate("/e_cart-page")
                        : navigate("/cart-page")
                    }
                    sx={{
                      color: "white.main",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ShoppingCart
                      sx={{
                        color: "inherit",
                      }}
                    />
                  </IconButton>

                  <IconButton
                    aria-label="cartButton"
                    onClick={() => dispatch(openNoti(true))}
                    sx={{
                      color: "white.main",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <Badges count={count?.count}>
                      <NotificationsIcon
                        sx={{
                          color: "inherit",
                        }}
                      />
                    </Badges>
                  </IconButton>
                  {/* <SkMenu /> */}

                  {!checked ? (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mx: 1.5 }}
                      onClick={() => {
                        navigate("/e_home-page");
                        dispatch(handleSwitch(true));
                      }}
                    >
                      E-Commerce
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mx: 1.5 }}
                      onClick={() => {
                        navigate("/home-page");
                        dispatch(handleSwitch(false));
                      }}
                    >
                      services
                    </Button>
                  )}
                  <Tooltip title="Profile">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        src={`${process.env.REACT_APP_URI}/${userData?.image}`}
                        // src={require("../../assets/images/0000-shraddha-kapoor.jpg")}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        dispatch(handleProfileOpen(true));
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{ fontFamily: "Inter" }}
                      >
                        My Profile
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate("/order-page");
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{ fontFamily: "Inter" }}
                      >
                        Order history
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        // localStorage.removeItem("isUserId");
                        // localStorage.removeItem("isToken");
                        // localStorage.removeItem("credential");
                        // localStorage.removeItem("userVerify");
                        // navigate("/home=page");
                        // dispatch(handleExistUser(false));
                        handleCloseUserMenu();
                        dispatch(handleModalOpenLogout(true));
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{ fontFamily: "Inter" }}
                      >
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>{" "}
              </>
            ) : (
              <>
                {/* <Box sx={{ flexGrow: 1 }} /> */}
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  {pages1.map((page) => (
                    <Button
                      key={page}
                      onClick={() => {
                        handleCloseNavMenu();
                        if (page === "Login/ Sign up") {
                          dispatch(handleModalOpen(true));
                        }
                        if (page === "Help") {
                          navigate("/faq-page");
                        }
                        if (page === "Register As A Professional") {
                          navigate("/professionalRegistration");
                        }
                      }}
                      // onClick={() => dispatch(handleModalOpen(true))}
                      sx={{
                        my: 2,
                        color: "white.main",
                        display: "block",
                        fontFamily: "inter",
                        "&:hover": {
                          color: "third.main",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  ))}
                </Box>
                {!checked ? (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mx: 1.5 }}
                    onClick={() => {
                      navigate("/e_home-page");
                      dispatch(handleSwitch(true));
                    }}
                  >
                    E-Commerce
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mx: 1.5 }}
                    onClick={() => {
                      navigate("/home-page");
                      dispatch(handleSwitch(false));
                    }}
                  >
                    services
                  </Button>
                )}
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default IsTransparentNavbar;
