import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import http from "../../Utils/Api";
import { SkPrice } from "../../Utils/dataFunction";

function SearchResults({ results }) {
  const navigate = useNavigate();
  const { checked } = useSelector((state) => state.isOrders);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "flex-start",
        border: 0.5,
        borderColor: "gray.border",
        borderRadius: 2,
        p: 1,
      }}
      onClick={() =>
        checked
          ? navigate(`/e_categoriesDetails/${results?._id}`)
          : navigate(`/serviceDetails-page/${results?._id}`)
      }
    >
      <Box sx={{ width: 180, height: 120 }}>
        <img
          src={`${process.env.REACT_APP_URI}/${results?.thumnail}`}
          onError={(e) => {
            e.onerror = null;
            e.target.src = require("../../assets/images/banner.png");
          }}
          alt="...."
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Box sx={{ flex: 1, flexDirection: "column", display: "flex" }}>
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontWeight: 700,
            fontSize: 16,
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {results?.title}
        </Typography>
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontWeight: 500,
            fontSize: 15,
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {results?.subtitle}
        </Typography>
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontWeight: 400,
            fontSize: 12,
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {SkPrice(results?.price)}
        </Typography>
      </Box>
    </Box>
  );
}

export const Searchresult = () => {
  const [isData, setIsData] = useState(null);
  const [isSearch, setIsSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { checked } = useSelector((state) => state.isOrders);
  //   const loading = open && options.length === 0;
  //   console.log(isSearch, "isSearch");
  const url = checked ? `/searchProducts` : "/searchServices";
  //   console.log(options, "|options");
  useEffect(() => {
    if (isSearch?.length) {
      const isSearchValue = isSearch?.trim();
      (async () => {
        setLoading(true);
        try {
          const res = await http.get(`${url}?search=${isSearchValue}`);
          //   console.log(res);
          if (res?.data?.success) {
            setIsData(res?.data?.data?.services);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [isSearch]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          mt: 2,
          alignItems: "center",
          width: "100%",
          p: 1,
        }}
      >
        <TextField
          fullWidth
          type="text"
          autoComplete="off" // This line disables autocomplete
          value={isSearch}
          onChange={(e) => setIsSearch(e.target.value)}
          variant="outlined"
          placeholder={checked ? "Search Products" : "Search services"}
          sx={{
            "& .MuiInputBase-root": {
              height: "3.5rem",
              fontSize: 20,
              fontFamily: "Inter",
              borderRadius: "15px",
              width: "100%",
              gap: 2,
              bgcolor: "#ECECEC",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              outline: "none",
              border: "none",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fontSize: 20, color: "gray.border" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
              </React.Fragment>
            ),
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
        <Typography
          variant="body1"
          color="initial"
          sx={{ fontSize: 15, fontWeight: 800 }}
        >
          Search result :
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 2,
          maxHeight: "75vh",
          overflowY: "scroll",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {isData &&
          isData?.length > 0 &&
          isData?.map((value, index) => (
            <SearchResults key={index} results={value} />
          ))}
      </Box>
    </>
  );
};
