import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";

// import { useState } from "react";

const HistoryCard = ({
  value,
  isId,
  setIsId,
  i,
  isOrderIndex,
  isMobileView,
}) => {
  //   console.log(value, 'hosjhasdkjhfkanggadfg')
  return (
    <>
      <Card
        elevation={3}
        sx={{
          height: 130,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          p: 1,
          gap: 0.8,
          border: 2,
          borderColor: isId === value?._id ? "primary.main" : "transparent",
          backgroundColor: i === isOrderIndex ? "#e0fdff" : "transparent",
        }}
        onClick={() => setIsId(value?._id)}
      >
        <Box sx={{ height: "80%", width: 140 }}>
          <img
            src={`${process.env.REACT_APP_URI}/${value?.productId?.thumnail}`}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../assets/images/banner.png");
            }}
            alt={value?.productId?.title}
          />
        </Box>
        <CardContent gutterBottom sx={{ width: "55%", p: 0.5 }}>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              maxWidth: "100%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value?.productId?.title}
          </Typography>

          <Typography sx={{ fontSize: 12, fontWeight: 600, py: 0.5 }}>
            {value?.price?.toLocaleString("en-US", {
              style: "currency",
              currency: "INR",
            })}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{ fontSize: 12, fontWeight: 400 }}
          >
            Quantity : {value?.quantity}
          </Typography>
          {isMobileView && (
            <Typography
              variant="subtitle2"
              sx={{ fontSize: 12, fontWeight: 400 }}
            >
              Status : {isMobileView}
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default HistoryCard;
