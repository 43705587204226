import React, { useEffect, useState } from "react";
import HeaderSection from "../../examples/HeaderSection";
// import banner from "../../assets/images/banner.png";
import {
  Box,
  Divider,
  Container,
  Typography,
  Button,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import SimpleImage from "../../examples/SimpleImage";

import Choosebutton from "../../examples/Choosebutton";
import SpecialOffer from "../../examples/SpecialOffer";
import { Link, useParams } from "react-router-dom";
// import MobileProductCard from "../../examples/MobileProductCard";
import { SwiperSlide } from "swiper/react";
import SwiperSlider from "../../components/Carousel/SwiperSlider";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getSubServices,
  getSubServicesProducts,
} from "../../Redux/festures/serviceSlice";
// import SkPagination from "../../components/SkPagination";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAllCoupons } from "../../Redux/festures/couponSlice";
import { SkPrice } from "../../Utils/dataFunction";
import SkError from "../../components/SkError";
import { getCartData } from "../../Redux/festures/cartSlice";
import SkLoading from "../../components/SkError/SkLoading";
import axios from "axios";
import http from "../../Utils/Api";
// const label = { inputProps: { "aria-label": "Checkbox demo" } };

const EcommServices = ({ e_homeData }) => {
  const dispatch = useDispatch();
  const data = { ...e_homeData?.e_homeData };
  useEffect(() => {
    dispatch(
      getCartData(
        `/eCommerce/getAllCartBycustomerId/${localStorage.getItem("isUserId")}`
      )
    );
  }, [localStorage.getItem("isUserId")]);

  const { subServiecs, loading } = useSelector((state) => state.isServices);

  // const { coupons, isLoading, isPages } = useSelector(
  //   (state) => state.isCoupons
  // );
  const { ecommCartData } = useSelector((state) => state.isCarts);
  const [active, setActive] = useState(null);
  // console.log("cartData", ecommCartData);
  // console.log("coupons", coupons);
  // const [pages, setPages] = useState(1);
  const isMobile = useMediaQuery("(max-width:870px)");
  const { id } = useParams();

  // onScroll
  // console.log("active", active);

  const [subCategorys, setSubCategorys] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  // console.log(subCategorys, "subCategorys")
  const fetchData = async () => {
    try {
      if (!active) {
        setSubCategorys([]); // Clear the data when active is null
        return setHasMore(false);
      }

      const response = await http.get(
        `/eCommerce/getProductBySubCategory/${active}?page=${page}`
      );

      if (response?.data?.page >= page) {
        if (response?.data?.data?.length > 0) {
          // setSubCategorys([]); // Clear the data before updating
          setSubCategorys((prevData) => [...prevData, ...response?.data?.data]);
          setPage(page + 1);
        } else {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchData = async () => {
  //   // console.log("subCategorys", subCategorys);
  //   // if (!active) return setHasMore(false); // Skip if active is null

  //   // dispatch(
  //   //   getSubServicesProducts(
  //   //     `/eCommerce/getProductBySubCategory/${active}?page=${page}`
  //   //   )
  //   // ).then((data) => {
  //   //   if (data?.payload?.data?.length > 0) {
  //   //     setSubCategorys((prevData) => [...prevData, ...data.payload.data]);
  //   //     setPage(page + 1);
  //   //   } else {
  //   //     setHasMore(false);
  //   //   }
  //   // });
  //   // console.log("setSubCategorys", subCategorys);
  //   try {
  //     if (!active) {
  //       setSubCategorys([]); // Clear the data when active is null
  //       return setHasMore(false);
  //     }

  //     const response = await http.get(
  //       `/eCommerce/getProductBySubCategory/${active}?page=${page}`
  //     );

  //     // console.log("response data:", response);
  //     if (response?.data?.page >= page) {
  //       if (response?.data?.data?.length > 0) {
  //         setSubCategorys((prevData) => [...prevData, ...response?.data?.data]);
  //         setPage(page + 1);
  //         // console.log("response data else:", page);
  //       } else {
  //         // setSubCategorys([]); // Clear the data when API response is empty
  //         setHasMore(false);
  //         // console.log("response data else:", response);
  //       }
  //     } else {
  //       // setSubCategorys([]); // Clear the data when API response is empty
  //       setHasMore(false);
  //       // console.log("response data else:", response);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // onScroll coupon

  const [isCoupons, setIsCoupons] = useState([]);
  const [isPage, setIsPage] = useState(1);
  const [isHasMore, setIsHasMore] = useState(true);

  const isFetchData = async () => {
    dispatch(getAllCoupons(`/getAllCoupon?type=ECOM_CATEGORY&page=${isPage}`))
      .then((data) => {
        if (data?.payload?.data?.length > 0) {
          setIsCoupons((prevData) => [...prevData, ...data.payload.data]);
          setIsPage(isPage + 1);
        } else {
          setIsHasMore(false);
        }
      })
      .catch((err) => {
        setIsHasMore(false);
      });
  };

  useEffect(() => {
    dispatch(
      getSubServices(`eCommerce/getCategoryWithPcategoryByUser/${id}`)
    ).then((data) => {
      if (data?.payload?.data && data?.payload?.data.length > 0) {
        setActive(data.payload.data[0]._id);
        setSubCategorys([]);
      } else {
        setActive(null);
      }
    });
  }, [dispatch, id]);

  useEffect(() => {
    if (!active) {
      return;
    } // Skip if active is null

    dispatch(
      getSubServicesProducts(
        `/eCommerce/getProductBySubCategory/${active}?page=${1}`
      )
    ).then((data) => {
      if (data?.payload?.success) {
        // console.log("asdkfhj", data.payload.data);
        // setSubCategorys((prevData) => [...prevData, ...data.payload.data]);
        setPage(2);
        setSubCategorys(data.payload.data);
      }
    });
  }, [active]);

  // useEffect(() => {
  //   isFetchData(); // Initial fetch
  // }, [isPage]);

  // useEffect(() => {
  //   if (page > 1) fetchData(); // Initial fetch
  // }, [page]);

  return (
    <>
      {!isMobile && (
        <HeaderSection
          bannar={data?.homeBanner?.at(0)?.banner}
          isLogin={true}
        />
      )}
      {subServiecs && subServiecs.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            mt: { xs: 1.1, sm: 1.1, md: 5, xl: 5 },
            px: { xs: 3, sm: 3, md: 8, xl: 8 },
          }}
        >
          <SwiperSlider items={isMobile ? 2 : 8} nav={true}>
            {subServiecs &&
              subServiecs.length > 0 &&
              subServiecs.map((value, index) => (
                <SwiperSlide key={index}>
                  <SimpleImage
                    data={value}
                    active={active}
                    setActive={setActive}
                    setSubCategorys={setSubCategorys}
                    setPage={setPage}
                  />{" "}
                </SwiperSlide>
              ))}
          </SwiperSlider>
        </Box>
      )}
      <Container maxWidth="lg">
        <Divider
          sx={{
            mt: 4,
            display: { xs: "none", sm: "none", md: "flex", xl: "flex" },
          }}
        ></Divider>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            pb: 2,
            width: "100%",
            gap: 1,
            // position: "relative",
            height: "auto",
            minHeight: " 35rem",
            flexDirection: { xs: "column", sm: "column", md: "row", xl: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 4,
              height: "100%",
              width: { xs: "100%", sm: "100%", md: "50%", xl: "50%" },
              // overflowY: "scroll",
            }}
          >
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 25,
                fontWeight: 800,
                mt: 3,
                fontFamily: "Inter",
              }}
            >
              Choose Products
            </Typography>
            {subCategorys && subCategorys.length > 0 ? (
              <Box
                sx={{
                  width: "100%",
                  p: { xs: 1, sm: 1 },
                }}
              >
                <InfiniteScroll
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "32px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    height: "auto",
                    width: "100%",
                    padding: "10px",
                  }}
                  dataLength={subCategorys?.length || 0}
                  next={fetchData}
                  hasMore={hasMore}
                  loader={<CircularProgress />}
                  // endMessage={<p>No More Category Products to Load....</p>}
                >
                  {loading ? (
                    <SkLoading />
                  ) : (
                    subCategorys &&
                    subCategorys.length > 0 &&
                    subCategorys.map((value, index) => (
                      <Choosebutton key={index} data={value} EComm={true} />
                    ))
                  )}
                </InfiniteScroll>
              </Box>
            ) : (
              <SkError EComm={true} type={"Products"} />
            )}
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", sm: "none", md: "flex", xl: "flex" } }}
          />
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "45%", xl: "45%" },
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 4,
              zIndex: -2,
            }}
          >
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 20,
                fontWeight: 800,
                mt: 3,
                fontFamily: "Inter",
                display: { xs: "none", sm: "none", md: "flex", xl: "flex" },
              }}
            >
              Special Offer
            </Typography>
            {isCoupons && isCoupons.length > 0 ? (
              <Box
                sx={{
                  width: "100%",
                  display: { xs: "none", sm: "none", md: "flex", xl: "flex" },
                }}
              >
                <InfiniteScroll
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "8px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    height: "auto",
                    width: "100%",
                  }}
                  dataLength={isCoupons.length || 0}
                  next={isFetchData}
                  hasMore={isHasMore}
                  loader={<CircularProgress />}
                  // endMessage={<p>No More Coupons To Load...</p>}
                >
                  {isCoupons &&
                    isCoupons.length > 0 &&
                    isCoupons.map((data, index) => (
                      <SpecialOffer key={index} value={data} i={index} />
                    ))}
                </InfiniteScroll>
              </Box>
            ) : (
              <SkError EComm={true} type={"Coupon"} />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            bottom: 0,
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            // zIndex: -1,
          }}
        >
          <Box sx={{ flex: 1 }} />
          {subCategorys &&
            subCategorys.length > 0 &&
            subServiecs &&
            subServiecs.length > 0 &&
            ecommCartData &&
            ecommCartData?.cartData?.length > 0 && (
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "45%", xl: "45%" },
                  height: {
                    xs: "5.5rem",
                    sm: "5.5rem",
                    md: "15vh",
                    xl: "15vh",
                  },
                  bgcolor: "white.main",
                  borderRadius: 2,
                  boxShadow:
                    "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  zIndex: 2,
                }}
              >
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 18,
                    fontWeight: 800,
                    fontFamily: "Inter",
                  }}
                >
                  {SkPrice(ecommCartData?.billDetail?.netAmount)}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/e_cart-page"
                  sx={{
                    textTransform: "lowercase",
                    fontFamily: "Inter",

                    fontWeight: 600,
                  }}
                >
                  View cart
                </Button>
              </Box>
            )}
        </Box>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    e_homeData: state.isHomes,
  };
};

export default connect(mapStateToProps)(EcommServices);
// export default ;
