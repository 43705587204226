import { Box, Rating, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { formattedDateServer } from "../Utils/dataFunction";

const ProductImage = ({ data, i }) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: 2,
        p: 1,
        gap: 1.5,
        display: "flex",
        flexDirection: "column",
        backgroundColor: i % 2 === 0 ? "#faf5f5" : "white.main",
        border: 0.5,
        borderColor: i % 2 === 0 ? "white.main" : "#faf5f5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Box sx={{ width: "4.5rem", height: "4.5rem", borderRadius: "50%" }}>
            <img
              src={`${process.env.REACT_APP_URI}/${data?.userId?.image}`}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../assets/images/banner.png");
              }}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              alt="..."
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "80%" }}>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 20,
                fontWeight: 600,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {data?.userId?.fullName}
            </Typography>
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 12, fontWeight: 600, color: "gray.border" }}
            >
              {formattedDateServer(new Date(data?.updatedAt))}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "10%",
            height: "5vh",
            bgcolor: "rgba(255, 233, 238, 1)",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            borderRadius: "20px",
            gap: 0.4,
          }}
        >
          <Rating
            name="read-only"
            value={data?.rating}
            readOnly
            precision={0.5}
            size="small"
            max={1}
          />
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontSize: 10, fontWeight: 600 }}
          >
            {data?.rating}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="body1"
        color="initial"
        sx={{ fontSize: 16, fontWeight: 400, color: "gray.main", px: 1.5 }}
      >
        {data?.comment?.length > 250 ? (
          !isShow ? (
            <>
              {data?.comment?.slice(0, 250)}{" "}
              <Button
                variant="text"
                color="primary"
                sx={{
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setIsShow(true);
                }}
              >
                show more...
              </Button>
            </>
          ) : (
            <>
              {data?.comment}{" "}
              <Button
                variant="text"
                color="primary"
                sx={{
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setIsShow(false);
                }}
              >
                show less...
              </Button>
            </>
          )
        ) : (
          data?.comment
        )}
      </Typography>
    </Box>
  );
};

export default ProductImage;
