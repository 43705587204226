import {
  Box,
  Card,
  Typography,
  Button,
  Rating,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import VerticalLinearStepper from "../components/Steper/StepperCard";
import { Cancel, KeyboardReturn, Replay, Star } from "@mui/icons-material";
import { SkPrice } from "../Utils/dataFunction";
import { useDispatch } from "react-redux";
import { getAllOrder, updateOrders } from "../Redux/festures/orderSlice";
import { handleAlert } from "../Redux/alertSlice";
import SkConfirm from "../components/SkConfirm";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import axios from "axios";

const OrderHistoryCard = ({ value, singleOrder, setIsOpen, checked }) => {
  const dispatch = useDispatch();
  const [isOpenDailog, setIsOpenDailog] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isUserId = localStorage.getItem("isUserId");
  // console.log(value);
  const isMobile = useMediaQuery("(max-width:870px)");
  const isMobileNumber = JSON.parse(localStorage.getItem("credential"));
  const handleDigitalPayment = async ({ orderId, userId, amount }) => {
    try {
      setIsLoadingBtn(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API}/PhonePayGateway`,
        {
          orderId: orderId,
          amount: amount,
          userId: userId,
          mobileNumber: isMobileNumber?.phoneNumber,
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("phoneapy", res);
      if (res?.data?.success) {
        setIsLoadingBtn(false);
        localStorage.setItem(
          "merchantId",
          res?.data?.data?.data?.merchantTransactionId
        );
      }
      // return res?.data;
      if (res?.data?.success) {
        const url =
          res?.data?.data?.data?.instrumentResponse?.redirectInfo?.url;
        if (url) {
          // window.open(name);
          // var myWindow = window.open("", "response", "resizable=yes");
          // myWindow.document.open(name);
          // var win =window.location.replace(name);
          // win.focus();
          var win = window.open(url, "_self");
          // var win = window.open(
          //   url,
          //   "targetWindow",
          //   `toolbar=no,
          //    location=no,
          //    status=no,
          //                           menubar=no,
          //                           scrollbars=yes,
          //                           resizable=yes,
          //                           width=SomeSize,
          //                           height=SomeSize`
          // );
          win.focus();
        }
      }
    } catch (error) {
      setIsLoadingBtn(false);
      console.log(error);
    }
  };
  return (
    <>
      {isMobile ? (
        <>
          {" "}
          <Card
            elevation={5}
            sx={{
              width: "100%",
              p: 2.5,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: 2,
              ml: 1.5,
            }}
          >
            <Box sx={{ width: "100%", height: 250, borderRadius: 0.5 }}>
              <img
                src={`${process.env.REACT_APP_URI}/${value?.productId?.thumnail}`}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("../assets/images/banner.png");
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
                alt=".."
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                // flex: 1,

                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {value?.productId?.title && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize={16}
                    fontWeight={800}
                    sx={{
                      maxWidth: "100%",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value?.productId?.title}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {value?.productId?.subtitle && (
                  <Typography
                    variant="body1"
                    fontSize={12.5}
                    fontWeight={500}
                    sx={{
                      maxWidth: "100%",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value?.productId?.subtitle}
                  </Typography>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: 1,
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    {value?.productId?.reviewRating > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          gap: 1,
                        }}
                      >
                        <Rating
                          name="read-only"
                          value={value?.productId?.reviewRating}
                          readOnly
                          precision={0.5}
                          size="small"
                        />
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            fontSize: 15,
                            fontWeight: 600,
                            color: "rgba(121, 121, 121, 1)",
                          }}
                        >
                          ({value?.productId?.reviewRating})
                        </Typography>
                      </Box>
                    )}

                    {value?.quantity && (
                      <Typography
                        variant="body1"
                        fontSize={12}
                        fontWeight={400}
                        sx={{
                          maxWidth: "100%",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Quantity :{value?.quantity}
                      </Typography>
                    )}
                    {singleOrder?.status ||
                      (value?.status && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={400}
                          sx={{
                            display: {
                              md: "none",
                              xl: "none",
                              sm: "flex",
                              xs: "flex",
                              color:
                                ((singleOrder?.status ||
                                  value?.product?.status) === "PENDING" &&
                                  "#f9a603") ||
                                ((singleOrder?.status ||
                                  value?.product?.status) === "ORDERED" &&
                                  "#00A36C") ||
                                ((singleOrder?.status ||
                                  value?.productId?.status) === "ACCEPTED" &&
                                  "#013220") ||
                                ((singleOrder?.status ||
                                  value?.product?.status) === "ONTHEWAY" &&
                                  "#89CFF0") ||
                                ((singleOrder?.status ||
                                  value?.product?.status) === "WORKING" &&
                                  "#088F8F") ||
                                ((singleOrder?.status ||
                                  value?.product?.status) === "COMPLETED" &&
                                  "#00008B") ||
                                ((singleOrder?.status ||
                                  value?.product?.status) === "CANCELLED" &&
                                  "#FF0000") ||
                                "#006400",
                            },
                          }}
                        >
                          Status :
                          {value?.product?.status ||
                            value?.status ||
                            singleOrder?.status}
                        </Typography>
                      ))}
                  </Box>
                  {value?.productId?.price && (
                    <Typography
                      variant="body1"
                      fontSize={12}
                      fontWeight={400}
                      sx={{
                        maxWidth: "100%",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Price: {SkPrice(value?.productId?.price)}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 1,
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                disabled={
                  value?.status !== "DELIVERED " ||
                  value?.status === "CANCELLED" ||
                  singleOrder?.status !== "COMPLETED" ||
                  singleOrder?.status === "CANCELLED"
                }
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  // = window.open(name, "_self");
                  var win = window.open(
                    `${process.env.REACT_APP_URI}/${singleOrder?.invoice}`,
                    "_self"
                  );
                  win.focus();
                }}
              >
                invoice
              </Button>
              {/* <Button
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                disabled={
                  value?.status !== "COMPLETED" ||
                  value?.status === "CANCELLED"
                }
                sx={{ textTransform: "capitalize", my: 0.5 }}
                startIcon={<Star />}
                onClick={() =>
                  setIsOpen((prev) => ({
                    ...prev,
                    state: true,
                    id: value?.productId?._id,
                    rating: true,
                  }))
                }
              >
                Review
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                disabled={
                  value?.status === "COMPLETED" ||
                  value?.status === "WORKING" ||
                  value?.status === "CANCELLED"
                }
                sx={{ textTransform: "capitalize", my: 0.5 }}
                startIcon={<Cancel />}
                onClick={() =>
                  setIsOpen((prev) => ({
                    ...prev,
                    state: true,
                    id: singleOrder?._id,
                    rating: false,
                  }))
                }
              >
                Cancel
              </Button> */}
              {checked ? (
                <React.Fragment>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={
                      value?.status !== "DELIVERED " ||
                      value?.status === "CANCELLED"
                    }
                    sx={{ textTransform: "capitalize", my: 0.5 }}
                    startIcon={<Star />}
                    onClick={() =>
                      setIsOpen((prev) => ({
                        ...prev,
                        state: true,
                        id: value?.productId?._id,
                        rating: true,
                      }))
                    }
                  >
                    Review
                  </Button>
                  {/* {console.log(value?.status, "value?.status")} */}
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={value?.status !== "DELIVERED"}
                    sx={{ textTransform: "capitalize", my: 0.5 }}
                    startIcon={<KeyboardReturn />}
                    onClick={() => setIsOpenDailog(true)}
                  >
                    Return
                  </Button>
                  {singleOrder?.paymentMethod === "ONLINE" &&
                    !singleOrder?.paymentStatus && (
                      <Button
                        variant="outlined"
                        color="shubham"
                        size="small"
                        disabled={
                          singleOrder?.status === "COMPLETED" ||
                          singleOrder?.status === "CANCELLED"
                        }
                        sx={{ textTransform: "capitalize", my: 0.5 }}
                        startIcon={<Replay />}
                        onClick={() => {
                          handleDigitalPayment({
                            orderId: singleOrder?._id,
                            userId: singleOrder?.customerId?._id,
                            amount: singleOrder?.orderTotal,
                          });
                        }}
                      >
                        {/* {console.log(value?.status !== "COMPLETED")} */}
                        {isLoadingBtn ? "Retry Payment...." : "Retry Payment"}
                      </Button>
                    )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={
                      singleOrder?.status !== "COMPLETED" ||
                      singleOrder?.status === "CANCELLED"
                    }
                    sx={{ textTransform: "capitalize", my: 0.5 }}
                    startIcon={<Star />}
                    onClick={() =>
                      setIsOpen((prev) => ({
                        ...prev,
                        state: true,
                        id: value?.productId?._id,
                        rating: true,
                      }))
                    }
                  >
                    Review
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={
                      singleOrder?.status === "COMPLETED" ||
                      singleOrder?.status === "WORKING" ||
                      singleOrder?.status === "ONTHEWAY" ||
                      singleOrder?.status === "CANCELLED"
                    }
                    sx={{ textTransform: "capitalize", my: 0.5 }}
                    startIcon={<Cancel />}
                    onClick={() =>
                      setIsOpen((prev) => ({
                        ...prev,
                        state: true,
                        id: singleOrder?._id,
                        rating: false,
                      }))
                    }
                  >
                    Cancel
                  </Button>
                  {singleOrder?.paymentMethod === "ONLINE" &&
                    !singleOrder?.paymentStatus && (
                      <Button
                        variant="outlined"
                        color="shubham"
                        size="small"
                        disabled={
                          singleOrder?.status === "COMPLETED" ||
                          singleOrder?.status === "CANCELLED"
                        }
                        sx={{ textTransform: "capitalize", my: 0.5 }}
                        startIcon={<Replay />}
                        onClick={() => {
                          handleDigitalPayment({
                            orderId: singleOrder?._id,
                            userId: singleOrder?.customerId?._id,
                            amount: singleOrder?.orderTotal,
                          });
                        }}
                      >
                        {/* {console.log(value?.status !== "COMPLETED")} */}
                        {isLoadingBtn ? "Retry Payment...." : "Retry Payment"}
                      </Button>
                    )}
                </React.Fragment>
              )}
            </Box>
          </Card>
        </>
      ) : (
        <Card
          elevation={5}
          sx={{
            width: "100%",
            p: 2.5,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          {(singleOrder?.status || value?.status) && (
            <Box
              sx={{
                width: "15.5%",
                display: { md: "flex", xl: "flex", sm: "none", xs: "none" },
              }}
            >
              {/* {console.log(
                "status",
                checked,
                value?.status,
                singleOrder?.status
              )} */}
              {checked ? (
                <VerticalLinearStepper
                  data={{
                    status: value?.status,
                  }}
                />
              ) : (
                <VerticalLinearStepper
                  data={{
                    status: singleOrder?.status,
                  }}
                />
              )}
            </Box>
          )}
          <Box
            sx={{
              width: "80%",
              // flex: 1,

              display: "flex",
              flexDirection: "column",
              gap: 1,
              ml: 1.5,
            }}
          >
            {value?.productId?.title && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 0.5,
                }}
              >
                <Typography
                  variant="body1"
                  fontSize={16}
                  fontWeight={800}
                  sx={{
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {value?.productId?.title}
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ textTransform: "capitalize" }}
                  disabled={
                    checked
                      ? value?.status === "CANCELLED" ||
                        value?.status !== "DELIVERED"
                      : singleOrder?.status !== "COMPLETED" ||
                        singleOrder?.status === "CANCELLED"
                  }
                  onClick={() => {
                    // = window.open(name, "_self");
                    var win = window.open(
                      `${process.env.REACT_APP_URI}/${singleOrder?.invoice}`,
                      "_self"
                    );
                    win.focus();
                  }}
                >
                  invoice
                </Button>
              </Box>
            )}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 2,
              }}
            >
              {value?.productId?.images?.at(0)?.type === "IMAGE" && (
                <Box sx={{ width: "40%", height: 100, borderRadius: 0.5 }}>
                  <img
                    src={`${process.env.REACT_APP_URI}/${
                      value?.productId?.images?.at(0)?.url
                    }`}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("../assets/images/banner.png");
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                    alt=".."
                  />
                </Box>
              )}
              {value?.productId?.images?.at(1)?.type === "IMAGE" && (
                <Box sx={{ width: "40%", height: 100 }}>
                  <img
                    src={`${process.env.REACT_APP_URI}/${
                      value?.productId?.images?.at(1)?.url
                    }`}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("../assets/images/banner.png");
                    }}
                    // src={require("../assets/images/banner.png")}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt=".."
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {value?.productId?.subtitle && (
                <Typography
                  variant="body1"
                  fontSize={12.5}
                  fontWeight={500}
                  sx={{
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {value?.productId?.subtitle}
                </Typography>
              )}
              {value?.productId?.reviewRating > 0 && (
                <Typography
                  variant="body1"
                  fontSize={12}
                  fontWeight={400}
                  sx={{
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Rating
                      name="read-only"
                      value={value?.productId?.reviewRating}
                      readOnly
                      precision={0.5}
                      size="small"
                    />
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        fontSize: 15,
                        fontWeight: 600,
                        color: "rgba(121, 121, 121, 1)",
                      }}
                    >
                      ({value?.productId?.reviewRating})
                    </Typography>
                  </Box>
                </Typography>
              )}
              {value?.productId?.price && (
                <Typography
                  variant="body1"
                  fontSize={12}
                  fontWeight={400}
                  sx={{
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Price: {SkPrice(value?.productId?.price)}
                </Typography>
              )}
              {value?.quantity && (
                <Typography
                  variant="body1"
                  fontSize={12}
                  fontWeight={400}
                  sx={{
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Quantity :{value?.quantity}
                </Typography>
              )}
              {/* {singleOrder?.status && (
                <Typography
                  variant="body1"
                  fontSize={12}
                  fontWeight={400}
                  sx={{
                    display: {
                      md: "none",
                      xl: "none",
                      sm: "flex",
                      xs: "flex",
                      color:
                        (singleOrder?.status === "PENDING" && "#f9a603") ||
                        (singleOrder?.status === "ORDERED" && "#00A36C") ||
                        (singleOrder?.status === "ACCEPTED" && "#013220") ||
                        (singleOrder?.status === "ONTHEWAY" && "#89CFF0") ||
                        (singleOrder?.status === "WORKING" && "#088F8F") ||
                        (singleOrder?.status === "COMPLETED" && "#00008B") ||
                        (singleOrder?.status === "CANCELLED" && "#FF0000") ||
                        "#006400",
                    },
                  }}
                >
                  status :{singleOrder?.status}
                </Typography>
              )} */}
              {singleOrder?.workingOtp &&
                singleOrder?.status !== "COMPLETED" && (
                  <>
                    {singleOrder?.workingOtp &&
                      singleOrder?.status !== "CANCELLED" && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={600}
                          sx={{
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Working Otp :{singleOrder?.workingOtp}
                        </Typography>
                      )}
                    {singleOrder?.completedOtp &&
                      singleOrder?.status === "WORKING" && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={600}
                          sx={{
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Completed Otp :{singleOrder?.completedOtp}
                        </Typography>
                      )}
                  </>
                )}
            </Box>
          </Box>
          {checked ? (
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                disabled={
                  value?.status === "CANCELLED" || value?.status !== "DELIVERED"
                }
                sx={{ textTransform: "capitalize", my: 0.5 }}
                startIcon={<Star />}
                onClick={() =>
                  setIsOpen((prev) => ({
                    ...prev,
                    state: true,
                    id: value?.productId?._id,
                    rating: true,
                  }))
                }
              >
                Review
              </Button>
              {/* {console.log(value?.status, "value?.status")} */}
              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                disabled={value?.status !== "DELIVERED"}
                sx={{ textTransform: "capitalize", my: 0.5 }}
                startIcon={<KeyboardReturn />}
                onClick={() => setIsOpenDailog(true)}
              >
                Return
              </Button>
              {singleOrder?.paymentMethod === "ONLINE" &&
                !singleOrder?.paymentStatus && (
                  <Button
                    variant="outlined"
                    color="shubham"
                    size="small"
                    fullWidth
                    disabled={
                      singleOrder?.status === "COMPLETED" ||
                      singleOrder?.status === "CANCELLED"
                    }
                    sx={{ textTransform: "capitalize", my: 0.5 }}
                    startIcon={<Replay />}
                    onClick={() => {
                      handleDigitalPayment({
                        orderId: singleOrder?._id,
                        userId: singleOrder?.customerId?._id,
                        amount: singleOrder?.orderTotal,
                      });
                    }}
                  >
                    {/* {console.log(value?.status !== "COMPLETED")} */}
                    {isLoadingBtn ? "Retry Payment...." : "Retry Payment"}
                  </Button>
                )}
            </Box>
          ) : (
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                disabled={
                  singleOrder?.status !== "COMPLETED" ||
                  singleOrder?.status === "CANCELLED"
                }
                sx={{ textTransform: "capitalize", my: 0.5 }}
                startIcon={<Star />}
                onClick={() =>
                  setIsOpen((prev) => ({
                    ...prev,
                    state: true,
                    id: value?.productId?._id,
                    rating: true,
                  }))
                }
              >
                {/* {console.log(value?.status !== "COMPLETED")} */}
                Review
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                disabled={
                  singleOrder?.status === "COMPLETED" ||
                  singleOrder?.status === "ONTHEWAY" ||
                  singleOrder?.status === "WORKING" ||
                  singleOrder?.status === "CANCELLED"
                }
                sx={{ textTransform: "capitalize", my: 0.5 }}
                startIcon={<Cancel />}
                onClick={() =>
                  setIsOpen((prev) => ({
                    ...prev,
                    state: true,
                    id: singleOrder?._id,
                    rating: false,
                  }))
                }
              >
                Cancel
              </Button>
              {singleOrder?.paymentMethod === "ONLINE" &&
                !singleOrder?.paymentStatus && (
                  <Button
                    variant="outlined"
                    color="shubham"
                    size="small"
                    fullWidth
                    disabled={
                      singleOrder?.status === "COMPLETED" ||
                      singleOrder?.status === "CANCELLED"
                    }
                    sx={{ textTransform: "capitalize", my: 0.5 }}
                    startIcon={<Replay />}
                    onClick={() => {
                      handleDigitalPayment({
                        orderId: singleOrder?._id,
                        userId: singleOrder?.customerId?._id,
                        amount: singleOrder?.orderTotal,
                      });
                    }}
                  >
                    {/* {console.log(value?.status !== "COMPLETED")} */}
                    {isLoadingBtn ? "Retry Payment...." : "Retry Payment"}
                  </Button>
                )}
            </Box>
          )}
        </Card>
      )}

      <SkConfirm
        open={isOpenDailog}
        setOpen={setIsOpenDailog}
        dialogTitle={" Order Return,"}
        dialogContent={"Are you sure you want to Return this Order?,"}
        dialogAction={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="primary"
              sx={{ width: "50%" }}
              onClick={() => {
                setIsOpenDailog(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              variant="text"
              sx={{ width: "50%" }}
              color="info"
              onClick={() => {
                setIsLoading(true);
                dispatch(
                  updateOrders({
                    url: `${process.env.REACT_APP_API}/eCommerce/returnRequestOrde/${singleOrder?._id}?productId=${value?.productId?._id}`,
                  })
                )
                  .then((data) => {
                    if (data?.payload?.success) {
                      setIsLoading(false);
                      dispatch(
                        getAllOrder(
                          `eCommerce/getOrderByCustomerId/${localStorage.getItem(
                            "isUserId"
                          )}`
                        )
                      );
                      dispatch(
                        handleAlert({
                          isOpen: true,
                          type: data?.payload?.success ? "success" : "error",
                          msg: data?.payload?.message,
                        })
                      );
                      setIsOpenDailog(false);
                    }
                  })
                  .catch(() => setIsLoading(false));
              }}
            >
              yes
            </LoadingButton>
          </Box>
        }
      />
    </>
  );
};

export default OrderHistoryCard;
