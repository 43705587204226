import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { pages } from "../../Utils/Constant/Services";
// import BasicModal from "../Modal";
import { handleModalOpen, handleModalOpenLogout } from "../../Redux/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleProfileOpen } from "../../Redux/ProfileSlice";
import { handleSwitch } from "../../Redux/festures/orderSlice";
import Badges from "../Badges";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  closeNoti,
  getNotification,
  getNotificationCount,
  openNoti,
} from "../../Redux/NotiSlice";
import NotiCard from "../../examples/NotiCard";
import SkMenu from "../SkMenu";
import { useEffect } from "react";
// import { handleExistUser } from "../../Redux/festures/userSlice";

// const pages1 = ["Register As A Professional", "Help", "Login/ Sign up"];
// const settings = ["Home", "Service", "About Us", "Contact Us"];

const TransparentNavbar = ({ isUserVerify }) => {
  const { existUser, userData } = useSelector((State) => State.isUsers);
  const { checked } = useSelector((state) => state.isOrders);
  const navigate = useNavigate();
  // const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { isOpen, Notification, count } = useSelector((state) => state?.isNoti);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const dispatch = useDispatch();

  return (
    <>
      <AppBar
        position="static"
        top={5}
        sx={{
          "&.MuiAppBar-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            "& 	.MuiContainer-root": {
              bgcolor: "transparent",
            },
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              "& 	.MuiToolbar-root": {
                backgroundColor: "transparent",
                color: "white",
                fontFamily: "inter",
              },
            }}
          >
            <Box
              sx={{ width: "5rem", height: "100%" }}
              onClick={() =>
                checked ? navigate("/e_home-page") : navigate("/home-page")
              }
            >
              <img
                src={require("../../assets/images/logo.png")}
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                alt="logo"
                title="easy_solution"
              />
            </Box>

            <Box
              sx={{
                width: "5rem",
                height: "100%",
                display: { xs: "flex", md: "none" },
              }}
              component={Link}
              to="/home-page"
            >
              <img
                src={require("../../assets/images/logo.png")}
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                alt="logo"
                title="easy_solution"
              />
            </Box>

            {/* <Search
              sx={{
                border: 1,
                borderColor: "white.main",
                borderRadius: 3,
              }}
            >
              <SearchIconWrapper sx={{ color: "white.main" }}>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2, mx: 2 }}>
              {pages.map((page) => (
                <Typography
                  key={page?.name}
                  component={Link}
                  to={page?.link}
                  // onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "white.main",
                    display: "block",
                    fontFamily: "inter",
                    fontWeight: 400,
                    "&:hover": {
                      color: "third.main",
                      cursor: "pointer",
                    },
                  }}
                >
                  {page?.name}
                </Typography>
              ))}
            </Box>
            {/* <Box sx={{ flexGrow: 1 }} /> */}
            {!checked ? (
              <Button
                variant="contained"
                color="primary"
                sx={{ mx: 1.5 }}
                onClick={() => {
                  navigate("/e_home-page");
                  dispatch(handleSwitch(true));
                }}
              >
                E-Commerce
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{ mx: 1.5 }}
                onClick={() => {
                  navigate("/home-page");
                  dispatch(handleSwitch(false));
                }}
              >
                services
              </Button>
            )}
            {existUser && localStorage.getItem("isUserId") ? (
              <>
                <Box sx={{ flexGrow: 0, display: "flex" }}>
                  <Tooltip title="Profile">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        src={`${process.env.REACT_APP_URI}/${userData?.image}`}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        handleCloseUserMenu();
                        dispatch(handleProfileOpen(true));
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{ fontFamily: "Inter" }}
                      >
                        My Profile
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate("/order-page");
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{ fontFamily: "Inter" }}
                      >
                        Order history
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        // localStorage.removeItem("isUserId");
                        // localStorage.removeItem("isToken");
                        // localStorage.removeItem("credential");
                        // localStorage.removeItem("userVerify");
                        // navigate("/home=page");
                        // dispatch(handleExistUser(false));
                        dispatch(handleModalOpenLogout(true));
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{ fontFamily: "Inter" }}
                      >
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
                <IconButton
                  aria-label="cartButton"
                  onClick={() => dispatch(openNoti(true))}
                  sx={{
                    color: "primary.main",
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  <Badges count={count?.count}>
                    <NotificationsIcon
                      sx={{
                        color: "inherit",
                      }}
                    />
                  </Badges>
                </IconButton>
                {/* <SkMenu /> */}
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  px: 3,
                  borderRadius: 2,
                  fontFamily: "inter",
                  fontWeight: 400,
                }}
                onClick={() => {
                  dispatch(handleModalOpen(true));
                }}
              >
                Login
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default TransparentNavbar;
