import { Box, IconButton, Rating, Typography } from "@mui/material";
import React from "react";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { SkPrice } from "../Utils/dataFunction";
import {
  deleteCart,
  getCartData,
  getEcommCartData,
  updateCart,
} from "../Redux/festures/cartSlice";
import { useDispatch } from "react-redux";
import { Delete } from "@mui/icons-material";
import { handleAlert } from "../Redux/alertSlice";
const MobileCartPageCard = ({ value, EComm }) => {
  const dispatch = useDispatch();
  const isUserId = localStorage.getItem("isUserId");
  // console.log(isUserId, "isUserId");
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          border: 2,
          borderColor: "gray.border",
          borderRadius: 5,
          width: "100%",
          height: "12.5rem",
          p: 1.5,
          gap: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "35%",
            height: "100%",
            border: 1,
            borderColor: "gray.border",
            borderRadius: 2,
          }}
        >
          <img
            src={`${process.env.REACT_APP_URI}/${value?.productId?.thumnail}`}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../assets/images/g3.png");
            }}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
            alt="..."
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            height: "100%",
            width: "65%",
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontSize: 15, fontWeight: 400, color: "gray.border" }}
          >
            COUGAR
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",

              color: "primary.main",
              fontSize: 18,
              fontWeight: 800,
            }}
          >
            {value?.productId?.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 18, fontWeight: 400, color: "gray.border" }}
            >
              time : {value?.productId?.time}
            </Typography>
            <Rating
              name="read-only"
              value={value?.productId?.reviewRating}
              readOnly
              precision={0.5}
              size="small"
              max={1}
            />
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 18, fontWeight: 400 }}
            >
              {value?.productId?.reviewRating}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2.5, alignItems: "center" }}>
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 15, fontWeight: 800 }}
            >
              {SkPrice(value?.productId?.price)}
            </Typography>
            <Box
              sx={{
                width: "auto",
                height: "auto",
                bgcolor: "rgba(243, 45, 43, 0.31)",
                borderRadius: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  if (EComm) {
                    dispatch(
                      updateCart({
                        url: `${process.env.REACT_APP_API}/eCommerce/removeQuantity/${value?._id}`,
                      })
                    ).then((data) => {
                      if (data?.payload?.success) {
                        // dispatch(
                        //   getEcommCartData(
                        //     `/eCommerce/getAllCartBycustomerId/${isUserId}`
                        //   )
                        // );
                        if (localStorage.getItem("isUserId")) {
                          dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllCartBycustomerId/${localStorage.getItem(
                                "isUserId"
                              )}`
                            )
                          );
                        } else {
                          dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
                                "isUserIdDummy"
                              )}`
                            )
                          );
                        }
                      }
                      if (!data?.payload?.success) {
                        // setIsShow(false);
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: data?.payload?.message,
                          })
                        );
                      }
                    });
                  } else {
                    dispatch(
                      updateCart({
                        url: `${process.env.REACT_APP_API}/removeQuantity/${value?._id}`,
                      })
                    ).then((data) => {
                      if (data?.payload?.success) {
                        // dispatch(
                        //   getCartData(`getAllCartBycustomerId/${isUserId}`)
                        // );
                        if (localStorage.getItem("isUserId")) {
                          dispatch(
                            getCartData(`getAllCartBycustomerId/${isUserId}`)
                          );
                        } else {
                          dispatch(
                            getCartData(
                              `/getAllCartByuserId/${localStorage.getItem(
                                "isUserIdDummy"
                              )}`
                            )
                          );
                        }
                      }
                      if (!data?.payload?.success) {
                        // setIsShow(false);
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: data?.payload?.message,
                          })
                        );
                      }
                    });
                  }
                }}
                sx={{ fontSize: 18, fontWeight: 800, color: "#000" }}
              >
                -
              </IconButton>
              <IconButton sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>
                {value?.quantity}
              </IconButton>
              <IconButton
                onClick={() => {
                  if (EComm) {
                    dispatch(
                      updateCart({
                        url: `${process.env.REACT_APP_API}/eCommerce/quantityUpdate/${value?._id}`,
                      })
                    ).then((data) => {
                      // console.log(data, "quantityUpdate");
                      if (data?.payload?.success) {
                        // dispatch(
                        //   getEcommCartData(
                        //     `/eCommerce/getAllCartBycustomerId/${isUserId}`
                        //   )
                        // );
                        if (localStorage.getItem("isUserId")) {
                          dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllCartBycustomerId/${localStorage.getItem(
                                "isUserId"
                              )}`
                            )
                          );
                        } else {
                          dispatch(
                            getEcommCartData(
                              `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
                                "isUserIdDummy"
                              )}`
                            )
                          );
                        }
                      }
                      if (!data?.payload?.success) {
                        // setIsShow(false);
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: data?.payload?.message,
                          })
                        );
                      }
                    });
                  } else {
                    dispatch(
                      updateCart({
                        url: `${process.env.REACT_APP_API}/quantityUpdate/${value?._id}`,
                      })
                    ).then((data) => {
                      // console.log(data, "quantityUpdate");
                      if (data?.payload?.success) {
                        // dispatch(
                        //   getCartData(`getAllCartBycustomerId/${isUserId}`)
                        // );
                        if (localStorage.getItem("isUserId")) {
                          dispatch(
                            getCartData(`getAllCartBycustomerId/${isUserId}`)
                          );
                        } else {
                          dispatch(
                            getCartData(
                              `/getAllCartByuserId/${localStorage.getItem(
                                "isUserIdDummy"
                              )}`
                            )
                          );
                        }
                      }
                      if (!data?.payload?.success) {
                        // setIsShow(false);
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: "error",
                            msg: data?.payload?.message,
                          })
                        );
                      }
                    });
                  }
                }}
                sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}
              >
                +
              </IconButton>
            </Box>
          </Box>
        </Box>
        <IconButton
          aria-label="deleteCArt"
          onClick={() => {
            if (EComm) {
              dispatch(
                deleteCart({
                  url: `${process.env.REACT_APP_API}/eCommerce/deleteCartById/${value?._id}`,
                })
              ).then((data) => {
                if (data?.payload?.success) {
                  if (localStorage.getItem("isUserId")) {
                    dispatch(
                      getEcommCartData(
                        `/eCommerce/getAllCartBycustomerId/${localStorage.getItem(
                          "isUserId"
                        )}`
                      )
                    );
                  } else {
                    dispatch(
                      getEcommCartData(
                        `/eCommerce/getAllDummyCartBycustomerId/${localStorage.getItem(
                          "isUserIdDummy"
                        )}`
                      )
                    );
                  }
                }
                if (!data?.payload?.success) {
                  // setIsShow(false);
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: "error",
                      msg: data?.payload?.message,
                    })
                  );
                }
              });
            } else {
              dispatch(
                deleteCart({
                  url: `${process.env.REACT_APP_API}/deleteCartById/${value?._id}`,
                })
              ).then((data) => {
                if (localStorage.getItem("isUserId")) {
                  dispatch(getCartData(`getAllCartBycustomerId/${isUserId}`));
                } else {
                  dispatch(
                    getCartData(
                      `/getAllCartByuserId/${localStorage.getItem(
                        "isUserIdDummy"
                      )}`
                    )
                  );
                }
                if (!data?.payload?.success) {
                  // setIsShow(false);
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: "error",
                      msg: data?.payload?.message,
                    })
                  );
                }
              });
            }
          }}
          sx={{
            position: "absolute",
            zIndex: 1,
            top: 2,
            right: 2,
          }}
        >
          <Delete
            sx={{
              color: "primary.main",
            }}
          />
        </IconButton>
      </Box>
    </>
  );
};

export default MobileCartPageCard;
