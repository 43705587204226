import React from "react";
import { useLocation } from "react-router-dom";
import { SkPrice } from "../../Utils/dataFunction";
import { Box, Button, Rating, TextField, Typography } from "@mui/material";
import OrderHistoryCard from "../../examples/OrderHistoryCard";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  createOrder,
  getAllOrder,
  updateOrders,
} from "../../Redux/festures/orderSlice";
import { handleAlert } from "../../Redux/alertSlice";
import BasicModal from "../../components/Modal";
import SkConfirm from "../../components/SkConfirm";

const OrderHistoryDetails = () => {
  const [isOpen, setIsOpen] = useState({
    state: false,
    id: null,
    rating: false,
  });
  const isUserId = localStorage.getItem("isUserId");
  // const [isId, setIsId] = useState(false);
  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  //   const { checked } = useSelector((state) => state.isOrders);
  const { state } = useLocation();
  //   console.log(state);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const handleSubmitForm = (e) => {
    e.preventDefault();
    setIsOpenDialog(true);
    setIsOpen((prev) => ({
      ...prev,
      state: false,
      // id: null,
      // rating: false,
    }));
    // dispatch(
    //   updateOrders({
    //     url: `${process.env.REACT_APP_API}/cancelOrder/${isOpen?.id}/${isUserId}`,
    //     data: {
    //       reason: reason?.trim(),
    //     },
    //   })
    // ).then((data) => {
    //   dispatch(
    //     handleAlert({
    //       isOpen: true,
    //       type: data?.payload?.success ? "success" : "error",
    //       msg: data?.payload?.message,
    //     })
    //   );
    //   if (data?.payload?.success) {
    //     dispatch(getAllOrder(`getAllOrderByCostomerId/${isUserId}`));
    //     setReason("");
    //     setIsOpen((prev) => ({
    //       ...prev,
    //       state: false,
    //       id: null,
    //       rating: false,
    //     }));
    //   }
    // });
  };
  const handleSubmitForm2 = (e) => {
    e.preventDefault();

    dispatch(
      createOrder({
        url: `${process.env.REACT_APP_API}/createReview`,
        data: {
          // reason: reason?.trim(),
          userId: isUserId,
          productId: isOpen?.id,
          comment: comment?.trim(),
          rating: rating,
        },
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: data?.payload?.success ? "success" : "error",
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        dispatch(getAllOrder(`getAllOrderByCostomerId/${isUserId}`));
        setReason("");
        setComment("");
        setRating(0);
        setIsOpen((prev) => ({
          ...prev,
          state: false,
          id: null,
          rating: false,
        }));
      }
    });
  };
  return (
    <>
      {state?.data?.product?.length > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",

            flex: 1,
            p: 1,
            gap: 3,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // border: 0.5,
            // borderColor: "gray.border",
            // borderRadius: 2,
          }}
        >
          {state?.data?.product &&
            state?.data?.product?.length > 0 &&
            state?.data?.product.map((value, index) => (
              <OrderHistoryCard
                value={value}
                singleOrder={state?.data}
                setIsOpen={setIsOpen}
                checked={state?.checked}
              />
            ))}

          <Box
            sx={{
              width: "100%",

              border: 0.5,
              borderColor: "gray.border",
              borderRadius: 2,
              p: 1.5,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 2,
                flexDirection: {
                  md: "row",
                  xl: "row",
                  sm: "column",
                  xs: "column",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  color="initial"
                  fontWeight={600}
                  fontSize={14}
                >
                  Shipping Address
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  fontWeight={400}
                  fontSize={12}
                  sx={{
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {state?.data?.address?.firstName}{" "}
                  {state?.data?.address?.lastName}
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  fontWeight={400}
                  fontSize={12}
                  sx={{
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {state?.data?.address?.mobile},{state?.data?.address?.address}
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  fontWeight={400}
                  fontSize={12}
                  sx={{
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {state?.data?.address?.apartment} ,
                  {state?.data?.address?.area}
                  {state?.data?.address?.city}, {state?.data?.address?.state},{" "}
                  {state?.data?.address?.country},
                  {state?.data?.address?.pinCode}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",

              border: 0.5,
              borderColor: "gray.border",
              borderRadius: 2,
              p: 1.5,
            }}
          >
            {" "}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 0.5,
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body1"
                color="initial"
                fontWeight={600}
                fontSize={14}
              >
                Billing Details
              </Typography>
              {state?.data?.netAmount && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={400}
                    fontSize={12}
                  >
                    Subtotal
                  </Typography>
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={600}
                    fontSize={12}
                  >
                    {SkPrice(state?.data?.netAmount)}
                  </Typography>
                </Box>
              )}
              {state?.data?.taxAmount && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={400}
                    fontSize={12}
                  >
                    Estimated Tax
                  </Typography>
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={600}
                    fontSize={12}
                  >
                    {SkPrice(state?.data?.taxAmount)}
                  </Typography>
                </Box>
              )}

              {state?.data?.totalOfferDiscount && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={400}
                    fontSize={12}
                  >
                    Discount
                  </Typography>
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={600}
                    fontSize={12}
                  >
                    -{SkPrice(state?.data?.totalOfferDiscount)}
                  </Typography>
                </Box>
              )}
              {state?.data?.orderTotal && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={400}
                    fontSize={12}
                  >
                    Total
                  </Typography>
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={600}
                    fontSize={12}
                  >
                    {SkPrice(state?.data?.orderTotal)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <BasicModal
        show={isOpen?.state}
        isDisabled={true}
        unShow={(e) => setIsOpen((prev) => ({ ...prev, state: e }))}
        overflowY={true}
        height={"auto"}
        title={
          isOpen?.rating ? (
            "Rating"
          ) : (
            <Typography
              variant="body1"
              color="primary"
              sx={{ fontWeight: 700 }}
            >
              Order Cancellation
            </Typography>
          )
        }
      >
        {isOpen?.rating ? (
          <Box sx={{ width: "100%", height: "100%" }}>
            <form
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "15px",
              }}
              onSubmit={handleSubmitForm2}
            >
              <Box
                sx={{
                  mt: 1,
                  width: "100%",
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {" "}
                <Typography component="legend">Rating</Typography>
                <Rating
                  precision={0.5}
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ color: "primary.main", fontWeight: 600 }}
                >
                  Review
                </Typography>
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  variant="outlined"
                  placeholder="Enter your Review"
                  type={"text"}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  multiline
                  rows={4}
                  maxRows={8}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "none",
                      outline: "none",
                      borderColor: "GrayText",
                      bgcolor: "#E8E8E8",
                      color: "primary.main",
                    },

                    "& .MuiOutlinedInput-notchedOutline": {
                      outline: "none",
                      border: "none",
                    },
                  }}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                type={"submit"}
                disabled={comment?.trim() === "" && rating !== 0}
              >
                Submit
              </Button>
            </form>
          </Box>
        ) : (
          <Box sx={{ width: "100%", height: "100%" }}>
            <form
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "15px",
              }}
              onSubmit={handleSubmitForm}
            >
              <Box
                sx={{
                  mt: 1,
                  width: "100%",
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "primary.main", fontWeight: 600 }}
                >
                  Reason
                </Typography>
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  variant="outlined"
                  placeholder="Enter your reason"
                  type={"text"}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  multiline
                  rows={4}
                  maxRows={8}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "none",
                      outline: "none",
                      borderColor: "GrayText",
                      bgcolor: "#E8E8E8",
                      color: "primary.main",
                    },

                    "& .MuiOutlinedInput-notchedOutline": {
                      outline: "none",
                      border: "none",
                    },
                  }}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                type={"submit"}
                disabled={reason?.trim() === ""}
              >
                Submit Reason
              </Button>
            </form>
          </Box>
        )}
      </BasicModal>
      <SkConfirm
        dialogTitle={" Order Cancellation"}
        dialogContent={"Are you sure you want to cancel this Order?"}
        open={isOpenDialog}
        setOpen={setIsOpenDialog}
        dialogAction={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="primary"
              sx={{ width: "50%" }}
              onClick={() => {
                setIsOpenDialog(false);
                setReason("");
                setIsOpen((prev) => ({
                  ...prev,
                  state: false,
                  id: null,
                  rating: false,
                }));
              }}
            >
              Cancel
            </Button>
            <Button
              variant="text"
              sx={{ width: "50%" }}
              color="info"
              onClick={() => {
                dispatch(
                  updateOrders({
                    url: `${process.env.REACT_APP_API}/cancelOrder/${isOpen?.id}/${isUserId}`,
                    data: {
                      reason: reason?.trim(),
                    },
                  })
                ).then((data) => {
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: data?.payload?.success ? "success" : "error",
                      msg: data?.payload?.message,
                    })
                  );
                  if (data?.payload?.success) {
                    dispatch(
                      getAllOrder(`getAllOrderByCostomerId/${isUserId}`)
                    );
                    setReason("");
                    setIsOpenDialog(false);
                    setIsOpen((prev) => ({
                      ...prev,
                      state: false,
                      id: null,
                      rating: false,
                    }));
                  }
                });
              }}
            >
              yes
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default OrderHistoryDetails;
