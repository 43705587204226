import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import { KeyRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { handleAlert } from "../../../Redux/alertSlice";
import { registerUsers, updateUsers } from "../../../Redux/festures/userSlice";
import { updateUserStatus } from "../../../Redux/festures/couponSlice";
import { useNavigate } from "react-router-dom";
// import { handleAlert } from "../../Redux/alertSlice";
// import { handleModalOpen } from "../../Redux/LoginSlice";
// import {
//   getUserDetails,
//   handleExistUser,
//   registerUsers,
// } from "../../Redux/festures/userSlice";

export default function Verify({ myOtp, credential, setMyOtp, userData }) {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  // console.log(userData, "userData");
  useEffect(() => {
    sendOTP();
  }, [myOtp]);
  const sendOTP = () => {
    setMinutes(1);
    setSeconds(0);
  };
  // const navigate = useNavigate();
  const handleFormSubmit = (values) => {
    setIsSubmitting(true);
    dispatch(
      handleAlert({
        isOpen: true,
        type: myOtp === values.otp ? "success" : "error",
        msg:
          myOtp === values?.otp
            ? "Verify OTP Successfully"
            : "Enter invalid OTP",
      })
    );

    if (myOtp === values.otp) {
      dispatch(
        updateUserStatus({
          url: `${process.env.REACT_APP_API}/disableUser/${userData?._id}`,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: data?.payload?.success ? "success" : "error",
            msg: data?.payload?.success
              ? "Account Deleted Successfully !"
              : "something went wrong !",
          })
        );
        setIsSubmitting(false);
        if (data?.payload?.success) {
          navigate("/");

          localStorage.removeItem("customerFcmToken");
          localStorage.removeItem("isOtp");
          localStorage.removeItem("credential");
          localStorage.removeItem("userVerify");
          localStorage.removeItem("isToken");
        }
      });
    } else {
      setIsSubmitting(false);
    }
    //     localStorage.setItem("userVerify", true);
    //     dispatch(handleModalOpen(false));
    //     localStorage.removeItem("isUserIdDummy");
    //     setOtpVerify(false);
    //     setClick(true);
    //     setIsShow(true);
    //     localStorage.setItem("isOtp", null);
    //     // navigate("/home-page");
    //     setMobileNumber(null);
    //     dispatch(handleExistUser(true));
    //     dispatch(getUserDetails(`/getUserById/${userId}`));
    //   } else {
    //     localStorage.setItem("userVerify", false);
    //   }
    // const result = await signIn("credentials", {
    //   redirect: false,
    //   ...values,
    // });
    // if (result.error) {
    //   console.error("Authentication failed:", result.error);
    // } else {
    //   console.log("Authentication successful:", result);
    //   // Redirect or perform other actions upon successful authentication
    // }
  };

  const isOtpSchema = yup.object().shape({
    otp: yup
      .string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits or numbers")
      .min(4, "Must be exactly 4 digits")
      .max(4, "Must be exactly 4 digits"),
  });

  const initialValues1 = {
    otp: "",
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues1}
      validationSchema={isOtpSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            bgcolor: "#ffffff",
            p: 4,
            gap: 2,
            boxShadow: 4.5,
            borderRadius: 2.5,
            width: { xs: "90%", sm: "90%", md: "35rem", xl: "35rem" },
          }}
          component="form"
          role="form"
          onSubmit={handleSubmit}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: 30,
              fontWeight: 800,
              fontFamily: "Inter",
            }}
          >
            Verify otp
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: 14,
              fontWeight: 400,
              fontFamily: "Inter",
            }}
          >
            Verify your mobile number
          </Typography>
          <Box sx={{ display: "flex", gap: 2, mt: 1, width: "100%" }}>
            <TextField
              id="standard-basic"
              autoComplete="off"
              variant="outlined"
              fullWidth
              required
              type={"text"}
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.otp}
              name="otp"
              error={!!touched.otp && !!errors.otp}
              helperText={touched.otp && errors.otp}
              placeholder="Enter your 4 digit OTP"
              sx={{
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "textfield",
                    },
                },
                "& .MuiInputBase-root": {
                  height: 45,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "#E8E8E8",
                  color: "primary.main",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  outline: "none",
                  border: "none",
                },

                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 4);
              }}
              InputProps={{
                endAdornment: (
                  <inputAdornment position="end">
                    {/* <SearchIcon /> */}
                    <KeyRounded sx={{ fontSize: 20 }} />
                  </inputAdornment>
                ),
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              mt: 0.5,
              gap: 1,
            }}
          >
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 12,
                fontWeight: 600,
                textTransform: "capitalize",
              }}
            >
              Resend the OTP in
            </Typography>
            {seconds > 0 || minutes > 0 ? (
              <Typography
                variant="body2"
                sx={{ color: "red", fontWeight: 600 }}
                component="span"
              >
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </Typography>
            ) : (
              <Button
                variant="text"
                color="primary"
                // onClick={() => setClick(false)}
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  // setIsLoading(true);

                  dispatch(
                    registerUsers({
                      url: `${process.env.REACT_APP_API}/sendOtp`,
                      data: { ...credential },
                    })
                  ).then((isData) => {
                    // console.log(isData, "isData");
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: isData?.payload?.success ? "success" : "error",
                        msg: isData?.payload?.message,
                      })
                    );
                    if (isData?.payload?.success) {
                      sendOTP();
                      setMyOtp(isData?.payload?.otp);
                    }
                  });
                  // setIsLoading(false);
                }}
              >
                resend
              </Button>
            )}
          </Box>
          <Button
            type="submit"
            disabled={!!touched.otp && !!errors.otp && values.otp !== ""}
            variant="contained"
            color="primary"
            sx={{
              // width: "40%",
              borderRadius: 5,
              textTransform: "capitalize",
              mt: 2,
            }}
          >
            {isSubmitting ? "Account Deleting...." : "Verify"}
          </Button>
        </Box>
      )}
    </Formik>
  );
}
