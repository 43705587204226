import { Box, Typography } from "@mui/material";
import React from "react";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const SpecialOffer = ({ value }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "6rem",
          backgroundColor:
            value?.backgroundColourCode &&
            value.backgroundColourCode.startsWith("#")
              ? value.backgroundColourCode
              : "primary.main", //"#B61575",
          borderRadius: 2,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 4,
          p: 1.5,
          boxShadow: "rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box
          sx={{
            width: "5rem",
            height: "5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border: 0.5,
            backgroundColor:
              value?.taskColourCode && value?.taskColourCode.startsWith("#")
                ? value?.taskColourCode
                : "info.main",
            borderRadius: "50%",
          }}
        >
          {/* <LocalOfferIcon sx={{ color: "white.main", fontSize: 30 }} /> */}
          <img
            src={`${process.env.REACT_APP_URI}/${value?.icon}`}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../assets/Iconly/Light/coupon.png");
            }}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "50%",
            }}
            alt=".."
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            color:
              value?.backgroundColourCode &&
              value.backgroundColourCode.startsWith("#")
                ? "black.main"
                : "white.main",
          }}
        >
          <Typography
            variant="body1"
            // color="initial"
            sx={{
              fontSize: 25,
              fontWeight: 600,
              fontFamily: "inter",
            }}
          >
            {value?.couponPercent}% off
          </Typography>
          <Typography
            variant="body1"
            // color="initial"
            sx={{
              fontSize: 15,
              fontWeight: 300,
              fontFamily: "inter",
              display: "-webkit-box",
              maxWidth: "100%",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Coupon Name : {value?.couponName} ,<br /> Coupon Code :{" "}
            {value?.couponCode}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SpecialOffer;
