import React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";

const SkDatePicker = ({ initialDate, onChange }) => {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#C42524", // Set the primary color
      },
    },
  });

  const disablePastAndFutureDates = (date) => {
    // const isdate = new Date();
    const today = dayjs().startOf("day");
    // console.log(isdate, "today");
    const oneMonthFromNow = today.add(1, "month");

    return date.isBefore(today) || date.isAfter(oneMonthFromNow);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          sx={{ width: "100%" }}
          value={initialDate ? dayjs(initialDate) : null}
          onChange={handleDateChange}
          placeholder="Select your date"
          inputProps={{
            min: dayjs().format("YYYY-MM-DD"), // Disable past dates
            max: dayjs().add(1, "month").format("YYYY-MM-DD"), // Disable dates more than 1 month in the future
          }}
          shouldDisableDate={disablePastAndFutureDates} // Custom function to disable past and future dates
          variant="outlined"
          renderInput={(params) => (
            <input
              {...params}
              className="datepicker-input"
              variant="outlined"
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
              }}
            />
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default SkDatePicker;
