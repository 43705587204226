import axios from "axios";
import React, { useEffect } from "react";
import { handleOrderOpen } from "../../Redux/OrderModalSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import SkLoading from "../../components/SkError/SkLoading";

const PaymentStatus = () => {
  const merchantId = localStorage.getItem("merchantId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // window.close();
    handleCheckPaymentStatus(localStorage.getItem("merchantId"));
  }, [merchantId]);

  const handleCheckPaymentStatus = async (merchantId) => {
    console.log(merchantId);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/PhonePayGatewayCheckStatus?merchantTransactionId=${merchantId}`
      );
      if (res?.data?.data?.success) {
        if (res?.data?.data?.code === "PAYMENT_SUCCESS") {
          dispatch(handleOrderOpen(true));
        }
      } else {
        navigate("/orderHistoryPage");
      }

      // console.log(res, "resPyament sytaus");
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <SkLoading />
        </Box>
      </Container>
    </>
  );
};

export default PaymentStatus;
