import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ExerciseBikesCard = ({ value, EComm }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        height: 300,
        width: "100%",
        // bgcolor: value?.colorCode,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        p: 0,
        flexDirection: "column",
        border: 0.5,
        borderColor: "lightgray",
        cursor: "pointer",
        gap: 2,
      }}
      onClick={() =>
        EComm
          ? navigate(`/e_categoriesDetails/${value?._id}`)
          : navigate(`/serviceDetails-page/${value?._id}`)
      }
      // component={Link}
      // to="/homepage"
    >
      <Box sx={{ width: "100%", height: 200 }}>
        <img
          src={`${process.env.REACT_APP_URI}/${value?.thumnail}`}
          alt="img"
          title={value?.title}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
            // objectPosition: `left top`,
          }}
          onError={(e) => {
            e.onerror = null;
            e.target.src = require("../assets/images/r1.png");
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          flexDirection: "column",
          width: "95%",
          p: 1,
          textAlign: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          fontSize={16}
          fontWeight={600}
        >
          {value?.title}
        </Typography>
        <Typography
          variant="subtitle"
          sx={{
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          fontSize={12}
        >
          {value?.include &&
            value?.include.length > 0 &&
            value?.include.slice(0, 2)?.map((text) => text + ",")}
        </Typography>
      </Box>
    </Card>
  );
};

export default ExerciseBikesCard;
