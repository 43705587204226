import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import http from "../../Utils/Api";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";



function SearchResults({ results }) {
  //   console.log(results, "results");
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Box sx={{ width: 100, height: 50 }}>
        <img
          src={`${process.env.REACT_APP_URI}/${results?.thumnail}`}
          onError={(e) => {
            e.onerror = null;
            e.target.src = require("../../assets/images/banner.png");
          }}
          alt="...."
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Box sx={{ flex: 1, flexDirection: "column", display: "flex" }}>
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontWeight: 700,
            fontSize: 14,
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {results?.title}
        </Typography>
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontWeight: 500,
            fontSize: 12,
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {results?.subtitle}
        </Typography>
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontWeight: 400,
            fontSize: 10,
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {results?.price}
        </Typography>
      </Box>
    </Box>
  );
}

export default function MySearchBar() {
  const [open, setOpen] = React.useState(false);
  const [isData, setIsData] = useState(null);
  const [options, setOptions] = React.useState([]);
  const [isSearch, setIsSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { checked } = useSelector((state) => state.isOrders);
  //   const loading = open && options.length === 0;
  //   console.log(isSearch, "isSearch");
  const url = checked ? `/searchProducts` : "/searchServices";
  //   console.log(options, "|options");
  React.useEffect(() => {
    let active = true;
    if (active && isData && isData?.length > 0) {
      setOptions([...isData]);
    }

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (isSearch?.length) {
      const isSearchValue = isSearch?.trim();
      //   console.log(isSearchValue, "isSearchbalue");

      (async () => {
        setLoading(true);
        try {
          const res = await http.get(`${url}?search=${isSearchValue}`);
          //   console.log(res);
          if (res?.data?.success) {
            setIsData(res?.data?.data?.services);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [isSearch]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      //   sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.subtitle}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          value={isSearch}
          onChange={(e) => setIsSearch(e.target.value)}
          {...params}
          placeholder={checked ? "Search Products" : "Search services"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li
          {...props}
          onClick={() =>
            checked
              ? navigate(`/e_categoriesDetails/${option?._id}`)
              : navigate(`/serviceDetails-page/${option?._id}`)
          }
        >
          <SearchResults results={option} />{" "}
          {/* Render your custom card component */}
        </li>
      )}
      sx={{
        width: " 100%",
        bgcolor: "white.main",
        color: "primary.main",
        borderRadius: 1,
        fontSize: "0.9rem",
        // height: 45,
        minWidth: "45rem",

        "& .MuiAutocomplete-inputRoot ": {
          fontSize: "0.9rem",
          outline: "none",
          border: 0,
          borderColor: "none",
          borderRadius: 1,
          color: "primary.main",
          // height: 45,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          fontSize: "0.9rem",
          outline: "none",
          border: 0,
          borderColor: "none",
          borderRadius: 1,
          color: "primary.main",
          // height: 45,
        },

        "& .MuiOutlinedInput-input": {
          color: "primary.main",
          fontWeight: 600,
          fontSize: "0.9rem",
          outline: "none",
          border: 0,
          borderColor: "none",
        },
      }}
    />
  );
}
