import { AppBar, Box, Button } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleSwitch } from "../../../Redux/festures/orderSlice";
import { useDispatch, useSelector } from "react-redux";

const ToggleMenu = ({ Order }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const { checked } = useSelector((state) => state.isOrders);
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });
  const dispatch = useDispatch();
  return (
    <AppBar
      color="white"
      style={{
        // transitionDuration: "2s",
        // transitionDelay: "2s",
        transition: "top 0.6s",
        overflow: "hidden",
        top: `${visible ? "0px" : "-120px"}`,
      }}
      sx={{
        position: "sticky",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          //   position: "sticky",
          p: 2,
          gap: 2,
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          //   color="primary"
          fullWidth
          sx={{
            textTransform: "capitalize",
            bgcolor: "primary.main",
            color: "white.main",
            p: 1.8,
            border: pathname === "/home-page" || !checked ? 2.5 : "none",
            borderRadius: 3,
            borderColor: "secondary.main",
          }}
          //   sx={{
          //     textTransform: "capitalize",
          //     //   background: "linear-gradient(45deg, #FF3366 30%, #FF9933 90%)",
          //     background:
          //       "linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%)",
          //     border: pathname === "/home-page" ? 2.5 : "none",
          //     borderRadius: 3,
          //     borderColor: "primary.main",
          //     color: "white.main",
          //     height: "48px",
          //     width: "50%",
          //     padding: "0 30px",
          //     boxShadow: "0 3px 5px 2px rgba(255, 51, 102, 0.3)",
          //     transition: "box-shadow 0.3s ease-in-out",
          //     backgroundSize: "200% auto",
          //     "&:hover": {
          //       boxShadow: "0 6px 10px 4px rgba(255, 51, 102, 0.3)",
          //       backgroundPosition: " right center",
          //     },
          //   }}
          onClick={() => {
            !Order && navigate("/home-page");
            dispatch(handleSwitch(false));
          }}
        >
          services
        </Button>
        <Button
          variant="contained"
          fullWidth
          //   color="secondary"
          sx={{
            textTransform: "capitalize",
            bgcolor: "secondary.bg",
            color: "white.main",
            p: 1.8,
            border: pathname === "/e_home-page" || checked ? 2.5 : "none",
            borderRadius: 3,
            borderColor: "primary.main",
          }}
          //   sx={{
          //     textTransform: "capitalize",

          //     background:
          //       "linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%)",
          //     border: pathname === "/e_home-page" ? 2.5 : "none",
          //     borderRadius: 3,
          //     borderColor: "primary.main",
          //     outline: "none",
          //     color: "white.main",
          //     height: "48px",
          //     width: "50%",
          //     padding: "0 30px",
          //     boxShadow: "0 3px 5px 2px rgba(255, 51, 102, 0.3)",
          //     transition: "box-shadow 0.5s ease-in-out",
          //     backgroundSize: "200% auto",
          //     "&:hover": {
          //       boxShadow: "0 6px 10px 4px rgba(255, 51, 102, 0.3)",
          //       backgroundPosition: " right center",
          //     },
          //   }}
          onClick={() => {
            !Order && navigate("/e_home-page");
            dispatch(handleSwitch(true));
          }}
        >
          E-commerce
        </Button>
      </Box>
    </AppBar>
  );
};

export default ToggleMenu;
