import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";
import axios from "axios";

// export const getPincode = createAsyncThunk(
//   "pinCode/getPincode",
//   async ({ url }) => {
//     // console.log(url)
//     try {
//       const res = await axios.get(url);
//       return await res.data;
//     } catch (error) {
//       return error;
//     }
//   }
// );
export const getSingleOrder = createAsyncThunk(
  "getSingleOrder",
  async (url) => {
    try {
      const res = await http.get(url);
      return res?.data;
    } catch (error) {
      return { data: null };
    }
  }
);
export const getAllOrder = createAsyncThunk("getAllOrder", async (url) => {
  try {
    const res = await http.get(url);
    return res?.data;
  } catch (error) {
    return { data: null };
  }
});
export const createOrder = createAsyncThunk(
  "createOrder",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const updateOrders = createAsyncThunk(
  "updateOrders",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("isToken"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);
export const deleteOrders = createAsyncThunk(
  "deleteOrders",
  async ({ url }) => {
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("isToken"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);
const orderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    checked: false,
    allOrdersData: null,
    singleOrder: null,
  },
  reducers: {
    handleSwitch: (state, action) => {
      state.checked = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSingleOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSingleOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.singleOrder = action?.payload?.data;
      })
      .addCase(getSingleOrder.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getAllOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.allOrdersData = action?.payload?.data;
      })
      .addCase(getAllOrder.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { handleSwitch } = orderSlice.actions;
export default orderSlice.reducer;
