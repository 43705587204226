import { Box, Button, Card, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceCategory = ({ value, EComm }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        height: "12rem",
        width: "18rem",
        bgcolor:
          value?.backgroundColourCode &&
          value.backgroundColourCode.startsWith("#")
            ? value.backgroundColourCode
            : "#B61575",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        textDecoration: "none",
      }}
      // component={Link}
      // to="/homepage"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-evenly",
          width: "55%",
          height: "100%",
          p: 1,
        }}
      >
        <Typography
          variant="body1"
          fontSize={16}
          fontWeight={600}
          sx={{
            color: "white.main",
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {value?.title}
        </Typography>
        <Typography
          variant="subtitle"
          sx={{ color: "white.main" }}
          fontSize={12}
        >
          {value?.subtitle}
        </Typography>
        {/* <Button variant="contained">Book now</Button> */}
        {/* {console.log(value, "value")} */}
        <Button
          onClick={() =>
            EComm
              ? navigate(`/e_categories-page/${value?.pCategory}`)
              : navigate(`/services-page/${value?.pCategory}`)
          }
          variant="contained"
          sx={{
            textTransform: "capitalize",
            bgcolor:
              value?.taskColourCode && value?.taskColourCode.startsWith("#")
                ? value?.taskColourCode
                : "#7C0D51",
            color: "white.main",
            "&:hover": {
              color: "white.main",
              bgcolor:
                value?.taskColourCode && value?.taskColourCode.startsWith("#")
                  ? value?.taskColourCode
                  : "#7C0D51",
            },
          }}
        >
          book now
        </Button>
      </Box>
      <Box sx={{ width: "45%", height: "100%" }}>
        <img
          src={`${process.env.REACT_APP_URI}/${value?.image}`}
          onError={(e) => {
            e.onerror = null;
            e.target.src = require("../assets/images/r2.png");
          }}
          alt="img"
          title={value?.title}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
    </Card>
  );
};

export default ServiceCategory;
