import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import TransparentNavbar from "../components/Navbar/TransparentNavbar";
import isBanner from "../assets/images/banner.png";
import IsTransparentNavbar from "../components/Navbar/IsTransparentNavbar";
import { useNavigate } from "react-router-dom";
import MySearchBar from "../components/SearchableComponent/MyAutocomplete";

const HeaderSection = ({ text, bannar, isLogin, isCategory, Ecomm }) => {
  // console.log(bannar, "Abannaf");
  // const isImagess = ;
  // const finalImages = bannar.replace(" ", "%20");
  // console.log(finalImages);
  // let originalString = "riteshsinghrajput";
  let iModifiedString = bannar?.replace(/ /g, "%20");
  // console.log(modifiedString);

  const styles = {
    isBgImage: {
      height: "85vh",
      width: "100%",
      backgroundImage: bannar
        ? `url(${process.env.REACT_APP_URI}/${iModifiedString})`
        : `url(${isBanner})`,
    },
  };

  const isUserVerify = localStorage.getItem("userVerify");
  const navigate = useNavigate();

  // only search here

  // console.log(isData, "isData");
  return (
    <>
      <Paper
        style={styles.isBgImage}
        sx={{
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 0,
          // backgroundAttachment: "fixed",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            background:
              "linear-gradient(180deg, rgba(217, 217, 217, 0.51) 0%, rgba(66, 66, 66, 0.48) 0.01%, rgba(0, 0, 0, 0.43) 100%)",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            borderRadius: 0,
          }}
        >
          {isLogin ? (
            <TransparentNavbar isUserVerify={isUserVerify} />
          ) : (
            <IsTransparentNavbar isUserVerify={isUserVerify} />
          )}
          {!isLogin && (
            <Box
              sx={{
                height: "65vh",
                width: "100%",

                // background:
                //   "linear-gradient(180deg, rgba(217, 217, 217, 0.51) 0%, rgba(66, 66, 66, 0.48) 0.01%, rgba(0, 0, 0, 0.43) 100%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                maxWidth="md"
                variant="h3"
                // style={{
                //   font: "800 50px",
                //   WebkitTextFillColor: "rgba(0, 0, 0, 0.80)",
                //   WebkitTextStroke: "1.1px #fff",
                //   fontFamily: "Helvetica",
                //   textTransform: "uppercase",
                //   textAlign: "center",
                // }}
                sx={{ color: "white.main" }}
                style={{
                  maxWidth: "75%",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  // lineHeight: "60px",
                  textAlign: "center",
                  fontFamily: "DM Serif Text",
                  fontSize: "48px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                {text}
              </Typography>
              {/* ytrfytfytfytifyitfiuvy tfyfytfutyfiytfyi fytf */}
              {/* <SearchableComponent isCategory={isCategory} /> */}
              <Box
                maxWidth="75%"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 2,
                  // width: "100%",
                  position: "relative",
                }}
              >
                <MySearchBar Ecomm={true} />
                {/* <TextField
                  variant="outlined"
                  placeholder="Search for service"
                  // autoComplete="off"
                  value={isSearch}
                  onChange={(e) => setIsSearch(e.target.value)}
                  fullWidth
                  type={"search"}
                  sx={{
                    "& .MuiInputBase-root": {
                      bgcolor: "white.main",
                      color: "primary.main",
                      fontSize: "0.9rem",
                      outline: "none",
                      border: 0,
                      borderColor: "none",
                      borderRadius: 1,
                      width: "100%",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
                {isShow && isData?.services?.length > 0 && (
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "30rem",
                      maxHeight: "20rem",
                      borderRadius: 1.5,
                      backgroundColor: "white.main",
                      position: "absolute",
                      top: "70%",
                      left: 0,
                      p: 2,
                      zIndex: 3,
                      boxShadow: "0px 4px 20px rgba(15,15,15,0.08)",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          borderBottom: "20px solid #ffffff",
                          borderRight: "20px solid transparent",
                          borderLeft: "20px solid transparent",
                          top: "-25px",
                          zIndex: 99999,
                          display: "inline-block",
                          left: 0,
                        }}
                      ></Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        overflowY: "scroll",
                        maxHeight: "15rem",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          color: "info.main",
                          gap: 1,
                          height: "auto",
                        }}
                      >
                        By strategically deciding to render certain components
                        on the server side, we save users' browsers from doing
                        extra work with JavaScript to show those components.
                        Instead, we get the initial HTML code for those
                        components, which the browser can display immediately.
                        This reduces the size of the JavaScript bundle, making
                        the initial page load faster. And as discussed above,
                        we’ll overcome our limitations with the pages directory.
                        Rather than fetching and passing data to components
                        separately, we can fetch the data directly within the
                        component, turning it into a server component. Overall,
                        we’ll have these benefits if we choose to do server-side
                        rendering: Smaller JavaScript bundle size: The size of
                        the JavaScript code that needs to be downloaded by the
                        browser is reduced.
                      </Box>
                    </Box>
                  </Box>
                )} */}

                {/* <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  {isCategory &&
                    isCategory.length > 0 &&
                    isCategory.slice(0, 3).map((text) => (
                      <Typography
                        maxWidth="md"
                        variant="body1"
                        onClick={() => navigate(`/services-page/${text?._id}`)}
                        sx={{
                          color: "white.main",
                          "&:hover": {
                            color: "third.main",
                            borderBottom: 0.5,
                            borderColor: "third.main",
                          },
                          maxWidth: "175px",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          // lineHeight: "60px",
                          textAlign: "left",
                          cursor: "pointer",
                          borderBottom: 0.5,
                          borderColor: "white.main",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        {text?.name}
                      </Typography>
                    ))}
                </Box> */}
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default HeaderSection;
