import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";
import axios from "axios";

export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async (url) => {
    try {
      const res = await http.get(url);
      return res.data;
    } catch (error) {
      return { data: null };
    }
  }
);

export const updateUsers = createAsyncThunk(
  "updateUsers",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          // "Content-Type": "multipart/form-data",
          // Accept: "application/json",
          authorization: localStorage.getItem("token"),
        },
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const registerUsers = createAsyncThunk(
  "registerUsers",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
const userSlice = createSlice({
  name: "users",
  initialState: {
    loading: false,
    userData: null,
    error: null,
    profileData: null,
    existUser: false,
  },
  reducers: {
    handleExistUser: (state, action) => {
      state.existUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userData = action?.payload?.data;
        state.loading = false;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(registerUsers.fulfilled, (state, action) => {
        // state.userData = action?.payload?.data;
        state.loading = false;
      })
      .addCase(registerUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUsers.fulfilled, (state, action) => {
        // state.userData = action?.payload?.data;
        state.loading = false;
      })
      .addCase(updateUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { handleExistUser } = userSlice.actions;
export default userSlice.reducer;
