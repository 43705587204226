import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";

const MobileHomeCard = ({ data }) => {
  const supportedImageFormats = ["png", "jpg", "jpeg"];

  const isImageFormatSupported = (fileName) => {
    const lowerCaseFileName = fileName.toLowerCase();
    return supportedImageFormats.some((format) =>
      lowerCaseFileName.endsWith(format)
    );
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "10rem",
          borderRadius: 2,
        }}
      >
        {data?.banner && isImageFormatSupported(data?.banner) ? (
          <Box sx={{ width: "100%", height: "10rem" }}>
            <img
              src={`${process.env.REACT_APP_URI}/${data?.banner}`}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../assets/image4.png");
              }}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "10px",
              }}
              alt="..."
            />
          </Box>
        ) : (
          <CardMedia
            component="video"
            sx={{
              height: "100%",
              width: "100%",
              // height: { xs: "180px", sm: "100px" },
              // width: { xs: "170px", sm: "220px" },
              borderRadius: "10px",
              objectFit: "cover",
            }}
            image={`${process.env.REACT_APP_URI}/${data?.banner}`}
            title="essIndiaOnline"
            autoPlay={false}
            controls
            // poster={`${process.env.REACT_APP_URI}/${ele?.bannerImage}`}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../assets/image4.png");
            }}
          />
        )}
      </Box>
    </>
  );
};

export default MobileHomeCard;
