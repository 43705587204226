import { Box } from "@mui/material";
import React from "react";
// import MobileComponent from '../../components/Mobile/MobileComponent'
import NotiCard from "../../examples/NotiCard";
import { NotificationCard } from "../../Utils/Constant/Services";
import { useSelector } from "react-redux";

const Notification = () => {
  const { Notification } = useSelector((state) => state.isNoti);
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, p: 2 }}>
        {/* <MobileComponent text={"Notification"}/> */}
        {Notification &&
          Notification.length > 0 &&
          Notification.map((value, index) => (
            <NotiCard key={index} data={value} />
          ))}
      </Box>
    </>
  );
};

export default Notification;
