import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "LoginModal",
  initialState: {
    isOpen: false,
    isPaymentOpen: false,
    url: null,
  },

  reducers: {
    handleProfileOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    handlePaymentOption: (state, action) => {
      state.isPaymentOpen = action.payload?.isPaymentOpen;
      state.url = action.payload?.url;
    },
  },
});

export const { handleProfileOpen, handlePaymentOption } = profileSlice.actions;

export default profileSlice.reducer;
