import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";

export const getCompanyData = createAsyncThunk(
  "getCompanyData",
  async (url) => {
    try {
      const res = await http.get(url);
      return res.data;
    } catch (error) {
      return { data: null };
    }
  }
);

const companySlice = createSlice({
  name: "conpany",
  initialState: {
    company: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyData.fulfilled, (state, action) => {
      state.company = action?.payload?.data;
    });
  },
});
export default companySlice.reducer;
