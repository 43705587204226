import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  IconButton,
} from "@mui/material";
import CardPageCard from "../../examples/CardPageCard";

import { useNavigate } from "react-router-dom";
import BasicModal from "../../components/Modal";

import Address from "../../examples/Address";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons } from "../../Redux/festures/couponSlice";
import { SkPrice } from "../../Utils/dataFunction";
import { getCartData } from "../../Redux/festures/cartSlice";
import img1 from "../../assets/images/1svg1.svg";
import SkError from "../../components/SkError";
import {
  AddTask,
  ExpandLess,
  ExpandMore,
  Remove,
  RemoveCircle,
} from "@mui/icons-material";
import MobileCard from "../../examples/MobileCard";
import { handleModalOpen } from "../../Redux/LoginSlice";

const IsSpecialCart = ({ value, i, setCouponOption, setIsSpecialOffer }) => {
  const dispatch = useDispatch();
  return (
    <List
      component="div"
      disablePadding
      sx={{
        backgroundColor: i % 2 === 0 ? "#f2f2f2" : "white.main",
        "& : hover": {
          backgroundColor: i % 2 === 0 ? "#f2f2f2" : "white.main",
        },
        display: "flex",
      }}
    >
      <ListItemButton
        sx={{
          px: 1.5,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <ListItemText
          sx={{ maxWidth: ` calc(100% - 110px)` }}
          primary={
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontWeight: 600,
                fontSize: 16,
                maxWidth: `100%`,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Coupon : {value?.couponName}
            </Typography>
          }
        />
        <ListItemText
          sx={{ maxWidth: 100 }}
          primary={
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontWeight: 800, fontSize: 16 }}
            >
              Off : {value?.couponPercent}%
            </Typography>
          }
        />
        <ListItemIcon
          onClick={() => {
            if (localStorage.getItem("isUserId")) {
              setIsSpecialOffer(false);
              setCouponOption((prev) => ({
                ...prev,
                couponName: value?.couponName,
                couponCode: value?.couponCode,
                _id: value?._id,
              }));
              window.scrollTo({ top: 0, behavior: "smooth" });
            } else dispatch(handleModalOpen(true));
          }}
        >
          <AddTask
            sx={{
              color: "primary.main",
              mx: 0.5,
            }}
          />{" "}
          <Typography variant="body1" color="primary" fontWeight={600}>
            {" "}
            Apply
          </Typography>
        </ListItemIcon>
      </ListItemButton>
      {/* <IconButton
          aria-label="apply_coupon"
          sx={{
            maxWidth: 50,
          }}
          onClick={() => {
            setIsSpecialOffer(false);
            setCouponOption((prev) => ({
              ...prev,
              couponName: value?.couponName,
              couponCode: value?.couponCode,
              _id: value?._id,
            }));
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <AddTask />
        </IconButton> */}
      {/* <ListItemButton
        variant="contained"
        color="primary"
        size="small"
        sx={{
          textTransform: "capitalize",
        }}
      >
        apply
      </ListItemButton> */}
    </List>
  );
};

const Cart = () => {
  const isMobile = useMediaQuery("(max-width:870px)");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { cartData } = useSelector((state) => state.isCarts);
  const navigate = useNavigate();
  const isUserId = localStorage.getItem("isUserId");
  const { existUser } = useSelector((State) => State.isUsers);

  useEffect(() => {
    if (localStorage.getItem("isUserIdDummy")) {
      dispatch(
        getCartData(
          `/getAllCartByuserId/${localStorage.getItem("isUserIdDummy")}`
        )
      );
    } else {
      dispatch(
        getCartData(
          `getAllCartBycustomerId/${localStorage.getItem("isUserId")}`
        )
      );
    }
  }, [isUserId, localStorage.getItem("isUserIdDummy"), existUser]);

  const [isCouponOption, setCouponOption] = useState({
    couponName: "",
    couponCode: "",
    _id: "",
  });
  // console.log(isCouponOption, "isCouponOption");
  const [isShowCouponOption, setShowCouponOption] = useState({
    isOpen: false,
    type: null,
    msg: null,
  });

  useEffect(() => {
    if (isCouponOption?.couponCode !== "") {
      dispatch(
        getCartData(
          `getAllCartBycustomerId/${isUserId}?couponCode=${isCouponOption?.couponCode}`
        )
      ).then((data) => {
        if (data?.payload?.success) {
          setTimeout(() => {
            setShowCouponOption((prev) => ({
              ...prev,
              isOpen: false,
              type: null,
              msg: null,
            }));
          }, 30000);
          setShowCouponOption((prev) => ({
            ...prev,
            isOpen: data?.payload?.success,
            type: data?.payload?.isSuccess ? "#013220" : "primary.main",
            msg: data?.payload?.isMessage,
          }));
        }
      });
    }
  }, [isCouponOption]);

  // console.log(isCouponOption, "isCouponOption");
  const [isSpecialOffer, setIsSpecialOffer] = useState(false);
  const [isCoupons, setIsCoupons] = useState([]);
  const [isPage, setIsPage] = useState(1);
  const [isHasMore, setIsHasMore] = useState(true);
  // console.log(isHasMore, "fgsdfg");
  const isFetchData = async () => {
    dispatch(getAllCoupons(`/getAllCoupon?type=CATEGORY&page=${isPage}`)).then(
      (data) => {
        if (data?.payload?.data?.length > 0) {
          setIsCoupons((prevData) => [...prevData, ...data?.payload?.data]);
          setIsPage(isPage + 1);
        } else {
          setIsHasMore(false);
        }
      }
    );
  };
  useEffect(() => {
    isFetchData();
  }, []);

  // console.log(cartData, "cardData");
  return (
    <>
      {isMobile ? (
        <>
          {cartData && cartData?.cartData?.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                gap: 2,
                // minHeight: " 35rem",
              }}
            >
              {cartData &&
                cartData?.cartData?.length > 0 &&
                cartData?.cartData?.map((value, index) => (
                  // <MobileCartPageCard key={index} value={value} />
                  <MobileCard key={index} value={value} />
                ))}
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  border: 1,
                  borderColor: "gray.border",
                  borderRadius: 2,
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                  // minHeight: " 35rem",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 1.5,
                    p: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{ fontSize: 20, fontWeight: 600 }}
                  >
                    Order Summary
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        fontSize: 15,
                        fontWeight: 400,
                        fontFamily: "Inter",
                      }}
                    >
                      Total price
                    </Typography>
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        fontSize: 15,
                        fontWeight: 500,
                        fontFamily: "Inter",
                      }}
                    >
                      {SkPrice(cartData?.billDetail?.netAmount)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        fontSize: 15,
                        fontWeight: 400,
                        fontFamily: "Inter",
                      }}
                    >
                      Taxes and Fee
                    </Typography>
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        fontSize: 15,
                        fontWeight: 500,
                        fontFamily: "Inter",
                      }}
                    >
                      {SkPrice(cartData?.billDetail?.taxAmount)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      onClick={() => setIsSpecialOffer(!isSpecialOffer)}
                    >
                      <ListItemIcon>
                        <Box sx={{ width: 25, height: 25 }}>
                          <img
                            src={require("../../assets/persent.png")}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            alt="..."
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText primary="Coupon and Offer" />
                      {isSpecialOffer ? <ExpandLess /> : <ExpandMore />}
                      {cartData?.billDetail?.couponDiscount > 0 && (
                        <IconButton
                          aria-label="removeCoupon"
                          onClick={(e) => {
                            e.stopPropagation();
                            // alert("hh");/
                            setCouponOption((prev) => ({
                              ...prev,
                              couponName: null,
                              couponCode: null,
                              _id: null,
                            }));
                          }}
                          sx={{
                            color: "primary.main",
                            textTransform: "capitalize",
                            fontWeight: 600,
                          }}
                        >
                          <RemoveCircle />
                        </IconButton>
                      )}
                    </ListItemButton>
                    <Collapse in={isSpecialOffer} timeout="auto" unmountOnExit>
                      {isCoupons &&
                        isCoupons.length > 0 &&
                        isCoupons.map((data, index) => (
                          <IsSpecialCart
                            key={index}
                            value={data}
                            i={index}
                            setCouponOption={setCouponOption}
                            setIsSpecialOffer={setIsSpecialOffer}
                          />
                        ))}
                    </Collapse>
                  </Box>
                  {!isSpecialOffer && isShowCouponOption?.isOpen && (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 13.5,
                          fontWeight: 800,
                          color: isShowCouponOption?.type,
                        }}
                      >
                        {isShowCouponOption?.msg}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{ fontSize: 18, fontWeight: 400, color: "red" }}
                    >
                      Discount
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ fontSize: 18, fontWeight: 500, color: "red" }}
                      >
                        -{" "}
                        {cartData?.billDetail?.totalOfferDiscount
                          ? SkPrice(cartData?.billDetail?.totalOfferDiscount)
                          : 0}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  position: "sticky",
                  bottom: 0,
                  width: "100%",
                  height: "10vh",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "space-evenly",
                  boxShadow:
                    "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25)",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{ fontSize: 15, fontWeight: 800 }}
                  >
                    {SkPrice(cartData?.billDetail?.orderTotal)}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: 10,
                    width: "40%",
                    height: "50%",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    if (existUser && isUserId) {
                      setIsOpen(true);
                    } else {
                      dispatch(handleModalOpen(true));
                    }
                  }}
                >
                  Place Order
                </Button>
              </Box>
            </Box>
          ) : (
            <SkError type={"Cart"} />
          )}
        </>
      ) : (
        <>
          <Container maxWidth="lg">
            {cartData && cartData?.cartData?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  justifyContent: "center",
                  // alignItems: "center",
                  height: "100%",
                  mt: 5,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    width: "55%",
                    height: "100%",
                    border: 1,
                    borderColor: "#D1D1D1",
                    borderRadius: 2,
                    p: 4,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    my: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{ fontSize: 20, fontWeight: 600 }}
                  >
                    Added services
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      gap: 4,
                      p: 1,
                      alignItems: "center",
                      overflowY: "scroll",
                      width: "100%",
                      maxHeight: {
                        xs: "100%",
                        md: "150vh",
                        xl: "150vh",
                      },
                      scrollBehavior: "smooth",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {cartData?.cartData &&
                      cartData?.cartData?.length > 0 &&
                      cartData?.cartData?.map((data, index) => (
                        <CardPageCard key={index} value={data} />
                      ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                    width: "40%",
                    height: "100%",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{ fontSize: 20, fontWeight: 600 }}
                    >
                      Order Summary
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 15,
                          fontWeight: 400,
                          fontFamily: "Inter",
                        }}
                      >
                        Total price
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 15,
                          fontWeight: 500,
                          fontFamily: "Inter",
                        }}
                      >
                        {SkPrice(cartData?.billDetail?.netAmount)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 15,
                          fontWeight: 400,
                          fontFamily: "Inter",
                        }}
                      >
                        Taxes and Fee
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontSize: 15,
                          fontWeight: 500,
                          fontFamily: "Inter",
                        }}
                      >
                        {SkPrice(cartData?.billDetail?.taxAmount)}
                      </Typography>
                    </Box>
                    {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 15, fontWeight: 400, fontFamily: "Inter" }}
                >
                  Tax Percentage
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 15, fontWeight: 500, fontFamily: "Inter" }}
                >
                  {cartData?.billDetail?.taxPercentage}%
                </Typography>
              </Box> */}
                    <Box
                      sx={{
                        width: "100%",
                        p: 0,
                      }}
                    >
                      <ListItemButton
                        onClick={() => setIsSpecialOffer(!isSpecialOffer)}
                      >
                        <ListItemIcon>
                          <Box sx={{ width: 25, height: 25 }}>
                            <img
                              src={require("../../assets/persent.png")}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              alt="..."
                            />
                          </Box>
                        </ListItemIcon>
                        <ListItemText primary="Coupon and Offer" />
                        {isSpecialOffer ? <ExpandLess /> : <ExpandMore />}
                        {cartData?.billDetail?.couponDiscount > 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              // alert("hh");/
                              setCouponOption((prev) => ({
                                ...prev,
                                couponName: null,
                                couponCode: null,
                                _id: null,
                              }));
                            }}
                            sx={{
                              color: "primary.main",
                              textTransform: "capitalize",
                              fontWeight: 600,
                            }}
                          >
                            remove
                          </Button>
                        )}
                      </ListItemButton>
                      <Collapse
                        in={isSpecialOffer}
                        timeout="auto"
                        unmountOnExit
                      >
                        {isCoupons &&
                          isCoupons.length > 0 &&
                          isCoupons.map((data, index) => (
                            <IsSpecialCart
                              key={index}
                              value={data}
                              i={index}
                              setCouponOption={setCouponOption}
                              setIsSpecialOffer={setIsSpecialOffer}
                            />
                          ))}
                      </Collapse>
                    </Box>
                    {!isSpecialOffer && isShowCouponOption?.isOpen && (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            fontSize: 13.5,
                            fontWeight: 800,
                            color: isShowCouponOption?.type,
                          }}
                        >
                          {isShowCouponOption?.msg}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ fontSize: 18, fontWeight: 400, color: "red" }}
                      >
                        Discount
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{ fontSize: 18, fontWeight: 500, color: "red" }}
                        >
                          -{" "}
                          {cartData?.billDetail?.totalOfferDiscount
                            ? SkPrice(cartData?.billDetail?.totalOfferDiscount)
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: 0.5,
                        borderColor: "gray.border",
                        width: "100%",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ fontSize: 18, fontWeight: 600 }}
                      >
                        Total
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ fontSize: 18, fontWeight: 600 }}
                      >
                        {SkPrice(cartData?.billDetail?.orderTotal)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: 0.5,
                        borderColor: "gray.border",
                        width: "100%",
                      }}
                    ></Box>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => {
                        if (existUser && isUserId) {
                          setIsOpen(true);
                        } else {
                          dispatch(handleModalOpen(true));
                        }
                      }}
                    >
                      Add Address
                    </Button>
                  </Box>
                  {/* <Box
              // sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4,  }}
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                gap: 2,
                my: 1,
                overflowY: "scroll",
                height: {
                  xs: "100%",
                  md: "calc(100vh - 50px)",
                  xl: "calc(100vh - 50px)",
                },
                scrollBehavior: "smooth",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <InfiniteScroll
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  height: "auto",
                  width: "100%",
                }}
                dataLength={isCoupons.length || 0}
                next={isFetchData}
                hasMore={isHasMore}
                loader={<CircularProgress />}
                endMessage={<p>No More Coupons To Load...</p>}
              >
                {isCoupons &&
                  isCoupons.length > 0 &&
                  isCoupons.map((data, index) => (
                    <SpecialOffer key={index} value={data} i={index} />
                  ))}
              </InfiniteScroll>
            </Box> */}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box sx={{ width: "50%", height: "calc(100vh - 200px)" }}>
                  <img
                    src={img1}
                    alt={"...."}
                    style={{ height: "100%", width: "100%" }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate("/home-page")}
                >
                  go back to more services
                </Button>
              </Box>
            )}
          </Container>
        </>
      )}
      <BasicModal
        show={isOpen}
        unShow={setIsOpen}
        overflowY={true}
        height={"auto"}
        maxWidth={"90%"}
        title={"Added address"}
      >
        <Address isOpen={isOpen} setIsOpen={setIsOpen} />
      </BasicModal>
    </>
  );
};

export default Cart;
