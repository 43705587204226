import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import {
  Box,
  Container,
  TextField,
  Button,
  Rating,
  Switch,
  Divider,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
// import { OrderCardData } from "../../Utils/Constant/Services";
import BasicModal from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { handleModalOpen } from "../../Redux/LoginSlice";
import {
  createOrder,
  getAllOrder,
  handleSwitch,
  updateOrders,
} from "../../Redux/festures/orderSlice";

import SkLoading from "../../components/SkError/SkLoading";
// import { KeyboardReturnTwoTone } from "@mui/icons-material";
import OrderHistoryCard from "../../examples/OrderHistoryCard";
import HistoryCard from "../../examples/HistoryCard";

import { SkPrice } from "../../Utils/dataFunction";
import { handleAlert } from "../../Redux/alertSlice";
import SkConfirm from "../../components/SkConfirm";
import SkError from "../../components/SkError";
// import { ChevronLeft } from "@mui/icons-material";
import ToggleMenu from "../home/Component";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Order = () => {
  const [isId, setIsId] = useState(false);
  const [isOrderIndex, setIsOrderIndex] = useState(0);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isActive, setIsActive] = useState(0);
  const [singleOrder, setSingleOrder] = useState(0);
  const [isOpen, setIsOpen] = useState({
    state: false,
    id: null,
    rating: false,
  });
  // const [isId, setIsId] = useState(false);
  const { loading, allOrdersData, checked } = useSelector(
    (state) => state.isOrders
  );
  const { existUser } = useSelector((state) => state.isUsers);

  // const [checked, setChecked] = useState(false);
  // console.log(checked, "checked");
  const handleChange = (event) => {
    dispatch(handleSwitch(event.target.checked));
  };
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      const isindex = allOrdersData?.findIndex((item) => item._id === state);
      setIsOrderIndex(isindex);
    }
  }, [state]);

  const isUserId = localStorage.getItem("isUserId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(allOrdersData, "allOrdersData");
  useEffect(() => {
    if (isUserId || existUser) {
      if (checked) {
        dispatch(getAllOrder(`eCommerce/getOrderByCustomerId/${isUserId}`));
      } else {
        dispatch(getAllOrder(`getAllOrderByCostomerId/${isUserId}`));
      }
    } else {
      navigate("/home-page");
      dispatch(handleModalOpen(true));
    }
  }, [existUser, checked]);

  useEffect(() => {
    allOrdersData &&
      allOrdersData.length > 0 &&
      setIsId(allOrdersData?.at(isOrderIndex)?.product?.at(isActive)?._id);

    setSingleOrder(allOrdersData?.at(isOrderIndex));
  }, [allOrdersData, isOrderIndex, isActive]);

  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const handleSubmitForm = (e) => {
    e.preventDefault();
    setIsOpenDialog(true);
    setIsOpen((prev) => ({
      ...prev,
      state: false,
      // id: null,
      // rating: false,
    }));
  };
  const handleSubmitForm2 = (e) => {
    e.preventDefault();
    if (checked) {
      dispatch(
        createOrder({
          url: `${process.env.REACT_APP_API}/eCommerce/createReview`,
          data: {
            // reason: reason?.trim(),
            userId: isUserId,
            productId: isOpen?.id,
            comment: comment?.trim(),
            rating: rating,
          },
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: data?.payload?.success ? "success" : "error",
            msg: data?.payload?.message,
          })
        );
        if (data?.payload?.success) {
          dispatch(getAllOrder(`eCommerce/getOrderByCustomerId/${isUserId}`));
          setReason("");
          setComment("");
          setRating(0);
          setIsOpen((prev) => ({
            ...prev,
            state: false,
            id: null,
            rating: false,
          }));
        }
      });
    } else {
      dispatch(
        createOrder({
          url: `${process.env.REACT_APP_API}/createReview`,
          data: {
            // reason: reason?.trim(),
            userId: isUserId,
            productId: isOpen?.id,
            comment: comment?.trim(),
            rating: rating,
          },
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: data?.payload?.success ? "success" : "error",
            msg: data?.payload?.message,
          })
        );
        if (data?.payload?.success) {
          dispatch(getAllOrder(`getAllOrderByCostomerId/${isUserId}`));
          setReason("");
          setComment("");
          setRating(0);
          setIsOpen((prev) => ({
            ...prev,
            state: false,
            id: null,
            rating: false,
          }));
        }
      });
    }
  };
  const isMobile = useMediaQuery("(max-width:870px)");
  return isMobile ? (
    <>
      {" "}
      <ToggleMenu Order={true} />
      <Container
        sx={{
          width: "100%",
          px: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",

            display: "flex",
            p: 1,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          {loading ? (
            <SkLoading />
          ) : (
            allOrdersData &&
            allOrdersData.length > 0 &&
            allOrdersData.map(
              (data, i) =>
                data?.product &&
                data?.product.length > 0 &&
                data?.product.map((items, index) => (
                  <Box
                    key={index}
                    sx={{
                      // border: orderHistorys === i ? 1.2 : null,
                      // borderColor: "primary.main",
                      cursor: "pointer",
                      // backgroundColor:
                      //   i === isOrderIndex ? "gray.border" : "white.main",
                      height: "100%",
                      width: "100%",
                      p: 1,
                    }}
                    onClick={() =>
                      navigate("/orderHistoryPage", {
                        state: {
                          data,
                          checked,
                        },
                      })
                    }
                  >
                    <div
                      onClick={() => {
                        setIsActive(index);
                        setIsOrderIndex(i);
                      }}
                    >
                      <HistoryCard
                        isMobileView={data?.status}
                        value={items}
                        // key={index}
                        i={i}
                        isOrderIndex={isOrderIndex}
                        isId={isId}
                        setIsId={setIsId}
                      />
                    </div>
                  </Box>
                ))
            )
          )}
        </Box>
      </Container>
    </>
  ) : (
    <>
      <Container
        sx={{
          width: "100%",
          px: { md: 8, xl: 8, sm: 2, xs: 2 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            pb: 2,
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: 0.5,
              borderColor: "gray.border",
            }}
          >
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 25,
                fontWeight: 600,
                display: { xs: "none", sm: "none", md: "flex", xl: "flex" },
                justifyContent: "flex-start",
              }}
            >
              Ordered
            </Typography>
            {/* <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontSize: 25,
                  fontWeight: 600,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                switch
              </Typography> */}
            {/* <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              /> */}
            {/* <Tooltip
              arrow
              title={`Switch to ${
                checked ? " Service Orders" : "E-commerce Orders"
              }`}
            > */}
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex", xl: "flex" },
                alignItems: "center",
                justifyContent: "center",
                width: "70%",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  color: !checked ? "primary.main" : "graydark.main",
                  // transition: "all 0.5s",
                  // duration: 200,
                  cursor: "pointer",
                  fontWeight: !checked ? 700 : 400,
                }}
              >
                Service Orders{" "}
              </Typography>{" "}
              <Tooltip
                arrow
                title={`Switch to ${
                  checked ? " Service Orders" : "E-commerce Orders"
                }`}
              >
                <AntSwitch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Tooltip>
              <Typography
                sx={{
                  color: checked ? "primary.main" : "graydark.main",
                  // transition: "all 0.5s",
                  // duration: 200,
                  cursor: "pointer",
                  fontWeight: checked ? 700 : 400,
                }}
              >
                E-commerce Orders
              </Typography>{" "}
            </Box>
            {/* </Tooltip> */}
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  xl: "none",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                  width: "100%",
                },
              }}
            >
              <Typography
                sx={{
                  opacity: !checked ? 1 : 0,
                  transition: "all 0.5s",
                  duration: 200,
                }}
              >
                Service Orders{" "}
              </Typography>
              <AntSwitch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography
                sx={{
                  opacity: checked ? 1 : 0,
                  transition: "all 0.5s",
                  duration: 200,
                }}
              >
                E-commerce Orders
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                xl: "none",
              },

              width: "100%",
            }}
          />
          {allOrdersData && allOrdersData.length > 0 ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexDirection: {
                  md: "row",
                  xl: "row",
                  sm: "column",
                  xs: "column",
                },
                gap: 2,
                pb: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: { md: "30%", xl: "30%", sm: "100%", xs: "100%" },
                  maxHeight: {
                    md: "100vh",
                    xl: "100vh",
                    sm: "20rem",
                    xs: "20rem",
                  },
                  display: "flex",
                  p: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: 1,
                  position: {
                    md: "sticky",
                    xl: "sticky",
                    sm: "static",
                    xs: "static",
                  },
                  top: "15%",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {loading ? (
                  <SkLoading />
                ) : (
                  allOrdersData &&
                  allOrdersData.length > 0 &&
                  allOrdersData.map(
                    (data, i) =>
                      data?.product &&
                      data?.product.length > 0 &&
                      data?.product.map((items, index) => (
                        <Box
                          key={index}
                          sx={{
                            // border: orderHistorys === i ? 1.2 : null,
                            // borderColor: "primary.main",
                            cursor: "pointer",
                            // backgroundColor:
                            //   i === isOrderIndex ? "gray.border" : "white.main",
                            height: "100%",
                            width: "100%",
                            p: 1,
                          }}
                          // onClick={() => handleClickHistory(i)}
                        >
                          <div
                            onClick={() => {
                              setIsActive(index);
                              setIsOrderIndex(i);
                            }}
                          >
                            <HistoryCard
                              value={items}
                              // key={index}
                              i={i}
                              isOrderIndex={isOrderIndex}
                              isId={isId}
                              setIsId={setIsId}
                            />
                          </div>
                        </Box>
                      ))
                  )
                )}
              </Box>
              {allOrdersData?.at(isOrderIndex)?.product?.at(isActive) && (
                <Box
                  sx={{
                    width: { md: "68%", xl: "68%", sm: "100%", xs: "100%" },
                    display: "flex",
                    minHeight: {
                      md: "100vh",
                      xl: "100vh",
                      sm: "100%",
                      xs: "100%",
                    },
                    flex: 1,
                    p: 1,
                    gap: 3,
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    // border: 0.5,
                    // borderColor: "gray.border",
                    // borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",

                      border: 0.5,
                      borderColor: "gray.border",
                      borderRadius: 2,
                      p: 1.5,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        fontWeight={600}
                        fontSize={15}
                      >
                        Order Id :
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        fontWeight={600}
                        fontSize={15}
                      >
                        {singleOrder?._id}
                      </Typography>
                    </Box>
                  </Box>

                  <OrderHistoryCard
                    value={allOrdersData
                      ?.at(isOrderIndex)
                      ?.product?.at(isActive)}
                    singleOrder={singleOrder}
                    setIsOpen={setIsOpen}
                    checked={checked}
                  />

                  <Box
                    sx={{
                      width: "100%",

                      border: 0.5,
                      borderColor: "gray.border",
                      borderRadius: 2,
                      p: 1.5,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: 2,
                        flexDirection: {
                          md: "row",
                          xl: "row",
                          sm: "column",
                          xs: "column",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="body1"
                          color="initial"
                          fontWeight={600}
                          fontSize={14}
                        >
                          Shipping Address
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          fontWeight={400}
                          fontSize={12}
                          sx={{
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {singleOrder?.address?.firstName}{" "}
                          {singleOrder?.address?.lastName}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          fontWeight={400}
                          fontSize={12}
                          sx={{
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {singleOrder?.address?.mobile},
                          {singleOrder?.address?.address}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          fontWeight={400}
                          fontSize={12}
                          sx={{
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {singleOrder?.address?.apartment} ,
                          {singleOrder?.address?.area}
                          {singleOrder?.address?.city},{" "}
                          {singleOrder?.address?.state},{" "}
                          {singleOrder?.address?.country},
                          {singleOrder?.address?.pinCode}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",

                      border: 0.5,
                      borderColor: "gray.border",
                      borderRadius: 2,
                      p: 1.5,
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: 0.5,
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        fontWeight={600}
                        fontSize={14}
                      >
                        Billing Details
                      </Typography>
                      {singleOrder?.netAmount && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={400}
                            fontSize={12}
                          >
                            Subtotal
                          </Typography>
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={600}
                            fontSize={12}
                          >
                            {SkPrice(singleOrder?.netAmount)}
                          </Typography>
                        </Box>
                      )}
                      {singleOrder?.taxAmount && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={400}
                            fontSize={12}
                          >
                            Estimated Tax
                          </Typography>
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={600}
                            fontSize={12}
                          >
                            {SkPrice(singleOrder?.taxAmount)}
                          </Typography>
                        </Box>
                      )}

                      {singleOrder?.totalOfferDiscount && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={400}
                            fontSize={12}
                          >
                            Discount
                          </Typography>
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={600}
                            fontSize={12}
                          >
                            -{SkPrice(singleOrder?.totalOfferDiscount)}
                          </Typography>
                        </Box>
                      )}
                      {singleOrder?.orderTotal && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={400}
                            fontSize={12}
                          >
                            Total
                          </Typography>
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={600}
                            fontSize={12}
                          >
                            {SkPrice(singleOrder?.orderTotal)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <SkError
              type={"Error"}
              msg={
                <Typography variant="body1" color="initial">
                  sorry...!! no order right now!!
                </Typography>
              }
              width={{ xs: "100%", sm: "100%", xl: "35vw", md: "35vw" }}
              height={"80%"}
              EComm={checked}
            />
          )}
        </Box>
      </Container>
      <BasicModal
        show={isOpen?.state}
        isDisabled={true}
        unShow={(e) => setIsOpen((prev) => ({ ...prev, state: e }))}
        overflowY={true}
        height={"auto"}
        title={
          isOpen?.rating ? (
            "Rating"
          ) : (
            <Typography
              variant="body1"
              color="primary"
              sx={{ fontWeight: 700 }}
            >
              Order Cancellation
            </Typography>
          )
        }
      >
        {isOpen?.rating ? (
          <Box sx={{ width: "100%", height: "100%" }}>
            <form
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "15px",
              }}
              onSubmit={handleSubmitForm2}
            >
              <Box
                sx={{
                  mt: 1,
                  width: "100%",
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {" "}
                <Typography component="legend">Rating</Typography>
                <Rating
                  precision={0.5}
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ color: "primary.main", fontWeight: 600 }}
                >
                  Review
                </Typography>
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  variant="outlined"
                  placeholder="Enter your Review"
                  type={"text"}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  multiline
                  rows={4}
                  maxRows={8}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "none",
                      outline: "none",
                      borderColor: "GrayText",
                      bgcolor: "#E8E8E8",
                      color: "primary.main",
                    },

                    "& .MuiOutlinedInput-notchedOutline": {
                      outline: "none",
                      border: "none",
                    },
                  }}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                type={"submit"}
                disabled={comment?.trim() === "" && rating !== 0}
              >
                Submit
              </Button>
            </form>
          </Box>
        ) : (
          <Box sx={{ width: "100%", height: "100%" }}>
            <form
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "15px",
              }}
              onSubmit={handleSubmitForm}
            >
              <Box
                sx={{
                  mt: 1,
                  width: "100%",
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "primary.main", fontWeight: 600 }}
                >
                  Reason
                </Typography>
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  variant="outlined"
                  placeholder="Enter your reason"
                  type={"text"}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  multiline
                  rows={4}
                  maxRows={8}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "none",
                      outline: "none",
                      borderColor: "GrayText",
                      bgcolor: "#E8E8E8",
                      color: "primary.main",
                    },

                    "& .MuiOutlinedInput-notchedOutline": {
                      outline: "none",
                      border: "none",
                    },
                  }}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                type={"submit"}
                disabled={reason?.trim() === ""}
              >
                Submit Reason
              </Button>
            </form>
          </Box>
        )}
      </BasicModal>
      <SkConfirm
        dialogTitle={" Order Cancellation"}
        dialogContent={"Are you sure you want to cancel this Order?"}
        open={isOpenDialog}
        setOpen={setIsOpenDialog}
        dialogAction={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="primary"
              sx={{ width: "50%" }}
              onClick={() => {
                setIsOpenDialog(false);
                setReason("");
                setIsOpen((prev) => ({
                  ...prev,
                  state: false,
                  id: null,
                  rating: false,
                }));
              }}
            >
              Cancel
            </Button>
            <Button
              variant="text"
              sx={{ width: "50%" }}
              color="info"
              onClick={() => {
                dispatch(
                  updateOrders({
                    url: `${process.env.REACT_APP_API}/cancelOrder/${isOpen?.id}/${isUserId}`,
                    data: {
                      reason: reason?.trim(),
                    },
                  })
                ).then((data) => {
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: data?.payload?.success ? "success" : "error",
                      msg: data?.payload?.message,
                    })
                  );
                  if (data?.payload?.success) {
                    dispatch(
                      getAllOrder(`getAllOrderByCostomerId/${isUserId}`)
                    );
                    setReason("");
                    setIsOpenDialog(false);
                    setIsOpen((prev) => ({
                      ...prev,
                      state: false,
                      id: null,
                      rating: false,
                    }));
                  }
                });
              }}
            >
              yes
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default Order;
