import { Box, Button, Rating, Typography } from "@mui/material";
import React from "react";
import { SkPrice } from "../Utils/dataFunction";
import { handleAlert } from "../Redux/alertSlice";
import { deleteCart, getCartData } from "../Redux/festures/cartSlice";
import { useDispatch } from "react-redux";

const MobileCard = ({ value, EComm }) => {
  const dispatch = useDispatch();
  const isUserId = localStorage.getItem("isUserId");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "11rem",
          border: 2,
          borderColor: "gray.border",
          borderRadius: 2,
          display: "flex",
          gap: 2,
          p: 2,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "8.5rem",
            height: "100%",
            border: 2,
            borderColor: "gray.border",
            borderRadius: 2,
          }}
        >
          <img
            src={`${process.env.REACT_APP_URI}/${value?.productId?.thumnail}`}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../assets/images/cart.png");
            }}
            // src={require("../assets/images/cart.png")}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="..."
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "calc(100% - 8.5rem)",
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: 15,
              fontWeight: 600,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {value?.productId?.title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Rating
              name="read-only"
              value={value?.productId?.reviewRating}
              readOnly
              precision={0.5}
              size="small"
              max={1}
            />{" "}
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 15, fontWeight: 600 }}
            >
              {value?.productId?.reviewRating}
            </Typography>
            {/* <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 15, fontWeight: 600, pl: 2 }}
            >
              202 Reviews
            </Typography> */}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              variant="body1"
              color="initial"
              sx={{ fontSize: 15, fontWeight: 800 }}
            >
              {SkPrice(value?.productId?.price)}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "15%",
              height: "20%",
              bgcolor: "white.main",
              fontSize: 10,
              color: "black.main",
              textTransform: "capitalize",
              fontWeight: 600,
              borderRadius: 2,
              boxShadow:
                "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25)",
              mt: 1,
              "&:hover": {
                bgcolor: "white.main",
              },
            }}
            onClick={() => {
              if (EComm) {
                dispatch(
                  deleteCart({
                    url: `${process.env.REACT_APP_API}/eCommerce/deleteCartById/${value?._id}`,
                  })
                ).then((data) => {
                  // console.log(data, "quantityUpdate");
                  if (data?.payload?.success) {
                    dispatch(
                      getCartData(
                        `/eCommerce/getAllCartBycustomerId/${isUserId}`
                      )
                    );
                  }
                  if (!data?.payload?.success) {
                    // setIsShow(false);
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: "error",
                        msg: data?.payload?.message,
                      })
                    );
                  }
                });
              } else {
                dispatch(
                  deleteCart({
                    url: `${process.env.REACT_APP_API}/deleteCartById/${value?._id}`,
                  })
                ).then((data) => {
                  if (data?.payload?.success) {
                    if (localStorage.getItem("isUserId")) {
                      dispatch(
                        getCartData(`getAllCartBycustomerId/${isUserId}`)
                      );
                    } else {
                      dispatch(
                        getCartData(
                          `/getAllCartByuserId/${localStorage.getItem(
                            "isUserIdDummy"
                          )}`
                        )
                      );
                    }
                  }
                  if (!data?.payload?.success) {
                    // setIsShow(false);
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: "error",
                        msg: data?.payload?.message,
                      })
                    );
                  }
                });
              }
            }}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default MobileCard;
