import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "LoginModal",
  initialState: {
    isOpen: false,
    isOpenLogout: false,
  },
  reducers: {
    handleModalOpen: (state, action) => {
      // if (state.alerts.isOpen == false) {
      //   state.alerts = action.payload;
      // }
      // console.log(action.payload, "slice");

      state.isOpen = action.payload;
    },
    handleModalOpenLogout: (state, action) => {
      // if (state.alerts.isOpen == false) {
      //   state.alerts = action.payload;
      // }
      // console.log(action.payload, "slice");

      state.isOpenLogout = action.payload;
    },
  },
});

export const { handleModalOpen, handleModalOpenLogout } = loginSlice.actions;

export default loginSlice.reducer;
