import React, { useEffect } from "react";
import { connect } from "react-redux";
import HeaderSection from "../../examples/HeaderSection";
import ServiceCard from "../../examples/homeServiseCard";
import {
  Avatar,
  Box,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import ServiceCategory from "../../examples/ServiceCategry";
import ExerciseBikesCard from "../../examples/ExerciseBikesCard";
// import LocationOnIcon from "@mui/icons-material/LocationOn";

import SearchIcon from "@mui/icons-material/Search";
import MobileHomeCard from "../../examples/MobileHomeCard";

import SwiperSlider from "../../components/Carousel/SwiperSlider";
import { SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { getHomeData } from "../../Redux/festures/homeSlice";

import ToggleMenu from "./Component";
import { getNotification, getNotificationCount } from "../../Redux/NotiSlice";
const Home = ({ homeData }) => {
  const data = { ...homeData?.homeData };

  const hour = new Date().getHours();

  // console.log(process.env.REACT_APP_API);
  // console.log(process.env.REACT_APP_HASHKEY);
  // const { homeData, loading } = useSelector((state) => ({
  //   ...state.isHomes,
  // }));
  // const isLoading = true;
  // console.log(homeData);
  // console.log("data", data);
  // console.log("dafgjdjta", data?.homeBanner[0]?.banner?.replace(/\s+/g, ''));
  const { userData, existUser } = useSelector((state) => state.isUsers);
  const isUserId = localStorage.getItem("isUserId");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHomeData(`/homePage`));
  }, [dispatch]);

  const isMobile = useMediaQuery("(max-width:870px)");

  useEffect(() => {
    if (isMobile) {
      dispatch(
        getNotification(`${process.env.REACT_APP_API}/getByUserId/${isUserId}`)
      ).then((res) => {
        if (res?.payload) {
          dispatch(
            getNotificationCount(
              `${process.env.REACT_APP_API}/seenCount/${isUserId}?userType=CUSTOMER`
            )
          );
        }
      });
    }
  }, [isMobile]);

  return isMobile ? (
    <>
      <ToggleMenu />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
          gap: 2,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Avatar
              src={
                existUser && userData?.image
                  ? `${process.env.REACT_APP_URI}/${userData?.image}`
                  : ""
              }
              sx={{
                width: "5.1rem",
                height: "5.1rem",
              }}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* {(hour < 12 && (
                  <Brightness4
                    sx={{ color: "#D04141", width: "25px", height: "20px" }}
                  />
                )) ||
                  (hour < 18 && (
                    <LightMode
                      sx={{
                        color: "#D04141",
                        width: "25px",
                        height: "20px",
                      }}
                    />
                  )) || (
                    <Nightlight
                      sx={{
                        color: "#D04141",
                        width: "25px",
                        height: "20px",
                      }}
                    />
                  )} */}

                  <Typography
                    variant="body1"
                    fontFamily="inherit"
                    sx={{
                      fontSize: 25,
                      fontWeight: 600,
                      color: "#353638",
                      maxWidth: "100%",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {existUser && userData
                      ? userData?.fullName
                      : "Good " +
                        ((hour < 12 && "Morning") ||
                          (hour < 18 && "Afternoon") ||
                          "Evening")}
                  </Typography>
                </Box>
                {/* <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <LocationOnIcon sx={{ color: "rgba(236, 47, 7, 1)" }} />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 18, fontWeight: 400, color: "gray.border" }}
                >
                  Bhopal 458001
                </Typography>
              </Box> */}
              </Box>
            </Box>
          </Box>
          {/* <Box>jhbuv</Box> */}

          <Link to={"/notification"}>
            <Box
              sx={{
                width: "3rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={require("../../assets/images/Notification.png")}
                alt="..."
                style={{ height: "2.5rem", width: "2.5rem" }}
              />
            </Box>
          </Link>
        </Box>
        <TextField
          component={Link}
          to={"/search-page"}
          fullWidth
          type={"text"}
          required
          variant="outlined"
          placeholder="Search "
          sx={{
            "& .MuiInputBase-root": {
              height: 45,

              fontSize: 14,
              fontFamily: "Inter",
              borderRadius: "15px",
              width: "100%",
              gap: 2,
              bgcolor: "rgba(245, 245, 245, 0.99)",
              display: "flex",
              alignItems: "center",
              // boxShadow:
              // " inset -6px -3px 5px rgba(255, 255, 255, 0.750601), inset 3px 6px 6px #C3D7E7, inset 4px 2px 6px #B1C5D5, inset 0px 2px 4px #C8D4E2",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              outline: "none",
              border: "none",
            },
          }}
          InputProps={{
            startAdornment: (
              <inputAdornment position="start">
                <SearchIcon sx={{ fontSize: 18 }} />
              </inputAdornment>
            ),
          }}
        />

        <SwiperSlider
        //  navigation={false}
        >
          {data?.appBanner &&
            data?.appBanner.length > 0 &&
            data?.appBanner.map((value, index) => (
              <>
                <SwiperSlide key={index}>
                  <MobileHomeCard data={value} />
                </SwiperSlide>
              </>
            ))}
        </SwiperSlider>
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontSize: 18,
            fontWeight: 600,
            fontFamily: "DM Serif ",
            letterSpacing: "1%",
            ml: 2,
          }}
        >
          Services
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 3,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "auto",
          }}
        >
          {data?.category &&
            data?.category.length > 0 &&
            data?.category
              ?.slice(0, 12)
              ?.map((value, index) => (
                <ServiceCard key={index} value={value} />
              ))}
        </Box>
        <Typography
          variant="body1"
          color="initial"
          sx={{
            fontSize: 18,
            fontWeight: 600,
            fontFamily: "DM Serif ",
            letterSpacing: "1%",
            ml: 2,
          }}
        >
          More Services Categories
        </Typography>
        <SwiperSlider>
          {data?.homeCategoryCart &&
            data?.homeCategoryCart.length > 0 &&
            data?.homeCategoryCart.map((data, index) => (
              <SwiperSlide key={index}>
                <ServiceCategory value={data} />
              </SwiperSlide>
            ))}
        </SwiperSlider>
        <Box sx={{ width: " 100%" }}>
          {data?.product &&
            data?.product.length > 0 &&
            data?.product.map(
              (isProduct, index) =>
                isProduct?.products &&
                isProduct?.products.length > 0 && (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      py: 2,
                      borderTop: 0.5,

                      borderColor: "lightgray",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      {isProduct?.title && (
                        <Typography
                          variant="h4"
                          color="secondary.main"
                          textAlign={"center"}
                          fontWeight={600}
                        >
                          {isProduct?.title}
                        </Typography>
                      )}
                      {isProduct?.description && (
                        <Typography
                          variant="h6"
                          color="secondary.main"
                          textAlign={"center"}
                          fontWeight={400}
                        >
                          {isProduct?.description}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        my: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 3,
                        py: 2,
                      }}
                    >
                      {isProduct?.products &&
                        isProduct?.products?.length > 0 && (
                          <SwiperSlider items={1}>
                            {isProduct?.products &&
                              isProduct?.products?.length > 0 &&
                              isProduct?.products?.map((value, i) => (
                                <SwiperSlide key={i}>
                                  <ExerciseBikesCard value={value} />
                                </SwiperSlide>
                              ))}
                          </SwiperSlider>
                        )}
                    </Box>
                  </Box>
                )
            )}
        </Box>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        mb: 2,
      }}
    >
      {/* <Skeleton variant="rectangular" width={"100%"} height={"85vh"} /> */}
      <HeaderSection
        text={data?.homeBanner?.at(0)?.title}
        bannar={data?.homeBanner?.at(0)?.banner}
        isLogin={false}
        isCategory={data && data?.category}
        Ecomm={false}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: -12.5,
        }}
      >
        <Box
          width="70%"
          sx={{
            minHeight: "20rem",
            border: 1,
            borderRadius: 1,
            borderColor: "#d3d3d3",
            background: "#FFF",
            boxShadow: "0px 4px 61px 0px rgba(0, 0, 0, 0.18)",
            p: 3.5,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
            gap: "0.5%",
            zIndex: 1,
          }}
        >
          {data?.category &&
            data?.category.length > 0 &&
            data?.category.map((value, index) => (
              <ServiceCard key={index} value={value} />
            ))}
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          px: 10,
          py: 2,
          borderTop: 0.5,
          // borderBottom: 0.5,
          borderColor: "lightgray",
          mt: 8,
        }}
      >
        <Box
          sx={{
            width: "100%",
            my: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            py: 2,
          }}
        >
          {/* {data?.homeCategoryCart && data?.homeCategoryCart.length} */}
          {data?.homeCategoryCart &&
            data?.homeCategoryCart.length <= 3 &&
            data?.homeCategoryCart.map((data, index) => (
              <ServiceCategory value={data} key={index} />
            ))}
          {data?.homeCategoryCart && data?.homeCategoryCart.length > 3 && (
            <SwiperSlider items={4} nav={true}>
              {data?.homeCategoryCart &&
                data?.homeCategoryCart.length > 0 &&
                data?.homeCategoryCart.map((data, index) => (
                  <SwiperSlide key={index}>
                    <ServiceCategory value={data} />
                  </SwiperSlide>
                ))}
            </SwiperSlider>
          )}
        </Box>
      </Box>
      {data?.product &&
        data?.product.length > 0 &&
        data?.product.map(
          (isProduct, index) =>
            isProduct?.products &&
            isProduct?.products.length > 0 && (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  px: 10,
                  py: 2,
                  borderTop: 0.5,

                  borderColor: "lightgray",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  {isProduct?.title && (
                    <Typography
                      variant="h4"
                      color="secondary.main"
                      textAlign={"center"}
                      fontWeight={600}
                    >
                      {isProduct?.title}
                    </Typography>
                  )}
                  {isProduct?.description && (
                    <Typography
                      variant="h6"
                      color="secondary.main"
                      textAlign={"center"}
                      fontWeight={400}
                    >
                      {isProduct?.description}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    my: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    py: 2,
                  }}
                >
                  {isProduct?.products &&
                    isProduct?.products?.length <= 3 &&
                    isProduct?.products?.map((value, i) => (
                      <Box
                        id="sajfhkjsadhfkds"
                        sx={{
                          width: `390px`,
                        }}
                        key={i}
                      >
                        <ExerciseBikesCard value={value} />{" "}
                      </Box>
                    ))}

                  {isProduct?.products && isProduct?.products?.length > 3 && (
                    <SwiperSlider items={3}>
                      {isProduct?.products &&
                        isProduct?.products?.length > 0 &&
                        isProduct?.products?.map((value, i) => (
                          <SwiperSlide key={i}>
                            <ExerciseBikesCard value={value} />
                          </SwiperSlide>
                        ))}
                    </SwiperSlider>
                  )}
                </Box>
              </Box>
            )
        )}
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    homeData: state.isHomes, // Assuming "homeData" is a key in your root reducer
  };
};

export default connect(mapStateToProps)(Home);
