import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";

export const getHomeData = createAsyncThunk("getHomeData", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getE_HomeData = createAsyncThunk("getE_HomeData", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const homeSlice = createSlice({
  name: "home",
  initialState: {
    homeData: null,
    e_homeData: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomeData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHomeData.fulfilled, (state, action) => {
        state.homeData = action?.payload?.data;
        state.loading = false;
      })
      .addCase(getHomeData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getE_HomeData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getE_HomeData.fulfilled, (state, action) => {
        state.e_homeData = action?.payload?.data;
        state.loading = false;
      })
      .addCase(getE_HomeData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default homeSlice.reducer;
