import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  Box,
  Divider,
  Rating,
  Button,
  CardMedia,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import ProductImage from "../../examples/ProductImage";
// import MobileComponent from "../../components/Mobile/MobileComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleService } from "../../Redux/festures/serviceSlice";
import ReactResponsiveCarousel from "../../components/Carousel/ReactResponsiveCarousel";
import { SkPrice, formattedDateServer } from "../../Utils/dataFunction";
import { sanitize } from "dompurify";
import { Circle } from "@mui/icons-material";
import { createCartData, getCartData } from "../../Redux/festures/cartSlice";
import SkError from "../../components/SkError";

import { handleAlert } from "../../Redux/alertSlice";
import MultiSliders from "../../components/Carousel/CoreUiSlider";

const ServiceDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const { singleService, singleServiceReviews } = useSelector(
    (data) => data.isServices
  );

  useEffect(() => {
    dispatch(getSingleService(`getByProductId/${id}`));
  }, [id]);

  const isUserId = localStorage.getItem("isUserId");
  const navigate = useNavigate();
  const { existUser } = useSelector((State) => State.isUsers);

  const isAdditional = singleService && (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexWrap: "wrap",
        width: "100%",
        height: "auto",
        alignItems: "center",
        justifyContent: "center ",
      }}
    >
      {singleService?.additional && singleService?.additional.length > 0 ? (
        singleService?.additional.map((value, index) => (
          <Box
            sx={{
              flex: `1 1 390px`,
            }}
          >
            {(value.type === "IMAGE" && (
              <img
                src={`${process.env.REACT_APP_URI}/${value?.url}`}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("../../assets/images/g3.png");
                }}
                width="100%"
                height="100%"
                alt={`slide-${index}`}
              />
            )) ||
              (value.type === "VIDEO" && (
                <CardMedia
                  component="video"
                  sx={{
                    height: "90%",
                    width: "100%",
                    // height: { xs: "180px", sm: "100px" },
                    // width: { xs: "170px", sm: "220px" },

                    objectFit: "contain",
                  }}
                  // onError={(e) => {
                  //   e.onerror = null;
                  //   e.target.src = require("../../assets/images/g3.png");
                  // }}
                  image={`${process.env.REACT_APP_URI}/${value?.url}`}
                  title="easySolution"
                  autoPlay={false}
                  controls
                  poster={`${process.env.REACT_APP_URI}/${value?.url}`}
                />
              ))}
          </Box>
        ))
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkError
            // EComm={true}
            type={"Error"}
            msg={
              <Typography variant="body1" color="initial">
                sorry...!! no additional for this service
              </Typography>
            }
            width={{ xs: "100%", sm: "100%", xl: "50vw", md: "50vw" }}
            height={"100%"}
          />
        </Box>
      )}
    </Box>
  );
  const isDescription = singleService && (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {singleService?.description ? (
        <Typography
          variant="body1"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            // "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            lineHeight: "30px",
            maxWidth: "100%",
          }}
          dangerouslySetInnerHTML={{
            __html: sanitize(singleService?.description),
          }}
        />
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkError
            // EComm={true}
            type={"Error"}
            msg={
              <Typography variant="body1" color="initial">
                sorry...!! no description for this service
              </Typography>
            }
            width={{ xs: "100%", sm: "100%", xl: "50vw", md: "50vw" }}
            height={"100%"}
          />
        </Box>
      )}
    </Box>
  );
  const isServiceInclude = singleService && (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 1.5,
        height: "auto",
        flexDirection: "column",
      }}
    >
      {singleService?.include && singleService?.include?.length > 0 ? (
        singleService?.include.map((value, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              maxWidth: "100%",
            }}
          >
            <Circle sx={{ fontSize: 10, color: "gray" }} />

            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 13.5,
                fontWeight: 400,
                color: "gray",
                fontFamily: "Inter",
                maxWidth: "100%",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </Typography>
          </Box>
        ))
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkError
            // EComm={true}
            type={"Error"}
            msg={
              <Typography variant="body1" color="initial">
                {/* sorry...!! no additional for this service */}
                sorry...!! no service include for this service
              </Typography>
            }
            width={{ xs: "100%", sm: "100%", xl: "50vw", md: "50vw" }}
            height={"100%"}
          />
        </Box>
      )}
    </Box>
  );
  const isServiceExclude = singleService && (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 1.5,
        height: "auto",
        flexDirection: "column",
      }}
    >
      {singleService?.exclude && singleService?.exclude?.length > 0 ? (
        singleService?.exclude.map((value, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              maxWidth: "100%",
            }}
          >
            <Circle sx={{ fontSize: 10, color: "gray" }} />

            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 13.5,
                fontWeight: 400,
                color: "gray",
                fontFamily: "Inter",
                maxWidth: "100%",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </Typography>
          </Box>
        ))
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkError
            // EComm={true}
            type={"Error"}
            msg={
              <Typography variant="body1" color="initial">
                {/* sorry...!! no additional for this service */}
                sorry...!! no service exclude for this service
              </Typography>
            }
            width={{ xs: "100%", sm: "100%", xl: "50vw", md: "50vw" }}
            height={"100%"}
          />
        </Box>
      )}
    </Box>
  );
  const isRatingReview = singleServiceReviews && (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 1.5,
        height: "auto",
        flexDirection: "column",
      }}
    >
      {singleServiceReviews && singleServiceReviews.length > 0 ? (
        singleServiceReviews.map((value, index) => (
          <ProductImage key={index} data={value} i={index} />
        ))
      ) : (
        <Box
          sx={{
            height: "100%",
            width: { xs: "100%", sm: "100%", xl: "100%", md: "100%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkError
            // EComm={true}
            type={"Error"}
            msg={
              <Typography variant="body1" color="initial">
                {/* sorry...!! no additional for this service */}
                sorry...!! no rating for this service
              </Typography>
            }
            width={{ xs: "100%", sm: "100%", xl: "50vw", md: "50vw" }}
            height={"100%"}
          />
        </Box>
      )}
    </Box>
  );

  const tabsName = [
    {
      name: "Service Include",
      value: "serviceInclude",
      isData: isServiceInclude,
    },
    {
      name: "Service Exclude",
      value: "serviceExclude",
      isData: isServiceExclude,
    },
    {
      name: "Additional Things To Get ",
      value: " additionalThingstoget",
      isData: isAdditional,
    },
    {
      name: "Description",
      value: "description",
      isData: isDescription,
    },
    {
      name: "Rating and Review",
      value: "rating&review",
      isData: isRatingReview,
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {singleService ? (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 2.5,
              mt: 2,
              justifyContent: "space-between",
              width: "100%",
              height: { xs: "100%", sm: "100%", md: "100%", xl: "100%" },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                xl: "row",
              },
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex", xl: "flex" },
                flexDirection: "column",
                gap: 1,
                width: { xs: "100%", sm: "100%", md: "55%", xl: "55%" },
                height: "100%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                color="initial"
                sx={{ fontSize: 30, fontWeight: 600, fontFamily: "DM Serif" }}
              >
                Service detail
              </Typography>
              <Divider sx={{ width: "100%" }}></Divider>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 20,
                    fontWeight: 700,
                    fontFamily: "inter",
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 4,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {singleService?.title}
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    fontFamily: "inter",
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {singleService?.subtitle}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <LocationOnIcon sx={{ color: "primary.main" }} />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 15, fontWeight: 400 }}
                >
                  {singleService?.cityId?.cityName}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Rating
                  name="read-only"
                  value={singleService?.reviewRating}
                  readOnly
                  precision={0.5}
                  size="small"
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 15,
                    fontWeight: 600,
                    color: "rgba(121, 121, 121, 1)",
                  }}
                >
                  ( {singleService?.reviewRating})
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body1" color="initial">
                  price:
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 18, fontWeight: 600, color: "primary.main" }}
                >
                  {SkPrice(singleService?.price)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body1" color="initial">
                  Warranty:
                </Typography>
                <Typography variant="body1" color="initial">
                  {/* {formattedDateServer(new Date(singleService?.warranty))} */}
                  {singleService?.warranty} /day's
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  bgcolor: "rgba(196, 37, 36, 1)",
                  borderRadius: 10,
                  textTransform: "capitalize",
                  maxWidth: "80%",
                }}
                // onClick={() => {
                //   if (existUser && localStorage.getItem("isToken")) {
                //     dispatch(
                //       createCartData({
                //         url: `${process.env.REACT_APP_API}/createCart`,
                //         data: {
                //           productId: singleService?._id,
                //           customerId: isUserId,
                //         },
                //       })
                //     ).then((data) => {
                //       // console.log(data, "data");
                //       if (data?.payload?.success) {
                //         // setIsShow(false);
                //         navigate("/cart-page");
                //         dispatch(
                //           getCartData(`getAllCartBycustomerId/${isUserId}`)
                //         );
                //       }
                //     });
                //   } else {
                //     dispatch(handleModalOpen(true));
                //   }
                // }}
                onClick={async () => {
                  try {
                    const id = singleService?._id;
                    const customerId = existUser
                      ? isUserId
                      : localStorage.getItem("isUserIdDummy") || "";

                    const url =
                      isUserId && existUser && localStorage.getItem("isToken")
                        ? `${process.env.REACT_APP_API}/createCart`
                        : `${process.env.REACT_APP_API}/createDummyCart`;

                    const response = await dispatch(
                      createCartData({
                        url,
                        data: { productId: id, customerId: customerId },
                      })
                    );

                    if (response.payload.success) {
                      if (response.payload.data?.customerId) {
                        localStorage.setItem(
                          "isUserIdDummy",
                          response.payload.data.customerId
                        );
                      }
                      navigate("/cart-page");
                      dispatch(
                        getCartData(
                          existUser && isUserId
                            ? `getAllCartBycustomerId/${customerId}`
                            : `getAllCartByuserId/${customerId}`
                        )
                      );
                    } else {
                      dispatch(
                        handleAlert({
                          isOpen: true,
                          type: "error",
                          msg: response.payload.message,
                        })
                      );
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                add services
              </Button>
            </Box>

            {/* <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "55%", xl: "55%" },
                height: "100%",
              }}
            >
              <SwiperSlider items={1} isAutoplay={true}>
                {singleService &&
                  singleService?.images?.map((el, i) => {
                    if (el.type === "IMAGE")
                      return (
                        <SwiperSlide key={i}>
                          <img
                            src={`${process.env.REACT_APP_URI}/${el?.url}`}
                            onError={(e) => {
                              e.onerror = null;
                              e.target.src = require("../../assets/images/g3.png");
                            }}
                            width="100%"
                            height="100%"
                            alt={`slide-${i}`}
                          />
                        </SwiperSlide>
                      );
                    if (el.type === "VIDEO")
                      return (
                        <SwiperSlide key={i}>
                          <CardMedia
                            component="video"
                            sx={{
                              height: "90%",
                              width: "100%",
                              // height: { xs: "180px", sm: "100px" },
                              // width: { xs: "170px", sm: "220px" },

                              objectFit: "contain",
                            }}
                            // onError={(e) => {
                            //   e.onerror = null;
                            //   e.target.src = require("../../assets/images/g3.png");
                            // }}
                            image={`${process.env.REACT_APP_URI}/${el?.url}`}
                            title="easySolution"
                            autoPlay={false}
                            controls
                            poster={`${process.env.REACT_APP_URI}/${el?.url}`}
                          />
                        </SwiperSlide>
                      );
                    return <></>;
                  })}
              </SwiperSlider>
            </Box> */}
            <Box
              sx={{
                width: { md: "50%", xl: "50%", xs: "100%", sm: "100%" },
                height: { xs: "20rem", sm: "20rem", md: "20rem", xl: "20rem" },
                position: "relative",
              }}
            >
              <ReactResponsiveCarousel>
                {singleService &&
                  singleService?.images?.map((el, i) => {
                    if (el.type === "IMAGE")
                      return (
                        <Box sx={{ maxWidth: "100%", maxHeight: "100%" }}>
                          <img
                            src={`${process.env.REACT_APP_URI}/${el?.url}`}
                            onError={(e) => {
                              e.onerror = null;
                              e.target.src = require("../../assets/images/g3.png");
                            }}
                            width="100%"
                            height="100%"
                            alt={`slide-${i}`}
                            style={{ objectFit: "contain" }}
                          />
                        </Box>
                      );
                    if (el.type === "VIDEO")
                      return (
                        <CardMedia
                          component="video"
                          sx={{
                            height: "90%",
                            width: "20rem",
                            // height: { xs: "180px", sm: "100px" },
                            // width: { xs: "170px", sm: "220px" },

                            objectFit: "contain",
                          }}
                          // onError={(e) => {
                          //   e.onerror = null;
                          //   e.target.src = require("../../assets/images/g3.png");
                          // }}
                          image={`${process.env.REACT_APP_URI}/${el?.url}`}
                          title="easySolution"
                          autoPlay={false}
                          controls
                          poster={`${process.env.REACT_APP_URI}/${el?.url}`}
                        />
                      );
                    return <></>;
                  })}
              </ReactResponsiveCarousel>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", sm: "flex", md: "none", xl: "none" },
                flexDirection: "column",
                gap: 1,
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                mt: 1.5,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 20,
                    fontWeight: 700,
                    fontFamily: "inter",
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 4,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {singleService?.title}
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    fontFamily: "inter",
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {singleService?.subtitle}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <LocationOnIcon sx={{ color: "primary.main" }} />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 15, fontWeight: 400 }}
                >
                  {singleService?.cityId?.cityName}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Rating
                  name="read-only"
                  value={singleService?.reviewRating}
                  readOnly
                  precision={0.5}
                  size="small"
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    fontSize: 15,
                    fontWeight: 600,
                    color: "rgba(121, 121, 121, 1)",
                  }}
                >
                  ( {singleService?.reviewRating})
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body1" color="initial">
                  price:
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 18, fontWeight: 600, color: "primary.main" }}
                >
                  {SkPrice(singleService?.price)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body1" color="initial">
                  Warranty:
                </Typography>
                <Typography variant="body1" color="initial">
                  {/* {formattedDateServer(new Date(singleService?.warranty))} */}
                  {singleService?.warranty}/day's
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  bgcolor: "rgba(196, 37, 36, 1)",
                  borderRadius: 10,
                  textTransform: "capitalize",
                  maxWidth: "80%",
                }}
                // onClick={() => {
                //   dispatch(
                //     createCartData({
                //       url: `${process.env.REACT_APP_API}/createCart`,
                //       data: {
                //         productId: singleService?._id,
                //         customerId: isUserId,
                //       },
                //     })
                //   ).then((data) => {
                //     // console.log(data, "data");
                //     if (data?.payload?.success) {
                //       // setIsShow(false);
                //       navigate("/cart-page");
                //       dispatch(
                //         getCartData(`getAllCartBycustomerId/${isUserId}`)
                //       );
                //     }
                //   });
                // }}
                onClick={async () => {
                  try {
                    const id = singleService?._id;
                    const customerId = existUser
                      ? isUserId
                      : localStorage.getItem("isUserIdDummy") || "";

                    const url =
                      isUserId && existUser && localStorage.getItem("isToken")
                        ? `${process.env.REACT_APP_API}/createCart`
                        : `${process.env.REACT_APP_API}/createDummyCart`;

                    const response = await dispatch(
                      createCartData({
                        url,
                        data: { productId: id, customerId: customerId },
                      })
                    );
                    navigate("/cart-page");
                    if (response.payload.success) {
                      if (response.payload.data?.customerId) {
                        localStorage.setItem(
                          "isUserIdDummy",
                          response.payload.data.customerId
                        );
                      }

                      dispatch(
                        getCartData(
                          existUser && isUserId
                            ? `getAllCartBycustomerId/${customerId}`
                            : `getAllCartByuserId/${customerId}`
                        )
                      );
                    } else {
                      dispatch(
                        handleAlert({
                          isOpen: true,
                          type: "error",
                          msg: response.payload.message,
                        })
                      );
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                add services
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              my: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Tabs
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                sx={{
                  bgcolor: "#e2dede",
                  width: "100%",
                }}
              >
                {tabsName.map((elm, i) => (
                  <Tab
                    sx={{
                      color: "secondary.bg",
                      fontWeight: 600,
                      "&.MuiButtonBase-root-MuiTab-root": {
                        backgroundColor: "primary.main",
                        color: "white.main",
                        textTransform: "capitalize",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "primary.main",
                        color: "white.main",
                        fontWeight: 600,
                        textTransform: "capitalize",
                      },
                      textTransform: "capitalize",
                    }}
                    label={elm?.name}
                  />
                ))}
              </Tabs>
              {tabsName.map((elm, i) => (
                <TabPanel value={value} index={i}>
                  <Box
                    sx={{
                      width: "100%",
                      p: 1.5,
                      my: 0.5,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {elm?.isData}
                  </Box>
                </TabPanel>
              ))}
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            height: "100%",
            width: { xs: "100%", sm: "100%", xl: "50%", md: "50%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkError type={"Products"} />
        </Box>
      )}
    </Container>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default ServiceDetailsPage;
