import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton, useMediaQuery, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
export default function BasicModal({
  children,
  show,
  unShow,
  width,
  height,
  overflowY,
  padding,
  title,
  isDisabled,
}) {
  const isMobile = useMediaQuery("(max-width:870px)");

  return (
    <div>
      <Modal
        // TransitionComponent={Transition}
        open={show}
        onClose={() => unShow(isDisabled ? true : false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // TransitionComponent={Transition}
      >
        <Box
          sx={{
            backgroundColor: "white.main",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "90%" : width ? width : 600,
            height: height ? height : "auto",
            borderRadius: 1.5,
            boxShadow: 24,
            // p: 1,
            maxHeight: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
              borderBottom: 0.5,
              borderColor: "secondary.main",
            }}
          >
            <Typography variant="h6" color="initial" fontWeight={600}>
              {title || "Welcome to Easy Solution"}
            </Typography>
            <IconButton
              onClick={() => {
                unShow(isDisabled ? true : false);
              }}
              sx={{
                display: isDisabled ? "none" : "flex",
                color: "primary.main",
                // position: "fixed",
                // top: 10.5,
                // right: 10.5,
                border: 1,
                borderRadius: 3,
                p: 0.5,
                borderColor: "primary.main",
                zIndex: 999,
              }}
            >
              <Close
                sx={{
                  color: "primary.main",
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              backgroundColor: "transparent",

              width: "98%",
              height: "98%",
              overflowY: overflowY ? "scroll" : "",
              // bgcolor: darkMode ? white.main : dark.main,

              p: padding ? padding : 2,
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                width: "0.4em",
                display: "none",
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
