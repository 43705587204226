import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SkHelmet = () => {
  const route = useLocation()
    .pathname?.split("/")
    ?.slice(1)
    ?.at(0)
    ?.replace("-", " ");

  const isTitleName = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };
  const { company } = useSelector((state) => state.isCompany);

  const location = useLocation();
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content={company?.seo_keyword}></meta>
        <meta name="description" content={company?.seo_description}></meta>
        <link
          rel="icon"
          type="image/png"
          href={`${process.env.REACT_APP_URI}/${company?.fav_icon}`}
          sizes="16x16"
        />

        <title>{`${isTitleName(route) || "web"} - ${
          isTitleName(company?.site_name) || "adminEasySolutions"
        }`}</title>
      </Helmet>
    </div>
  );
};

export default SkHelmet;
