import Layout from "./components/Layout";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import routes from "./routes";
import AlertTost from "./examples/AlertTost";
import LoginModal from "./components/LoginModal";
import ProfileModal from "./components/LoginModal/ProfileModal";
import { useEffect, useState } from "react";
import { handleAlert } from "./Redux/alertSlice";
import { handleModalOpen } from "./Redux/LoginSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { handleExistUser } from "./Redux/festures/userSlice";
import OrderModal from "./components/OrderModal";
import LogoutConfirm from "./components/LoginModal/LogoutConfirm";
import { handleSwitch } from "./Redux/festures/orderSlice";
import { onMessageListener, requestPermission } from "./firebase";
import PaymentModal from "./components/Modal/PaymentModal ";
import { getNotificationCount } from "./Redux/NotiSlice";
import SkMenu from "./components/SkMenu";
import SkHelmet from "./components/SkHelmet";
import WhatsappFloating from "./components/FloatingWhatsApp";

function App() {
  const dispatch = useDispatch();
  const isToken = localStorage.getItem("isToken");
  const isUserId = localStorage.getItem("isUserId");
  const isUserVerify = localStorage.getItem("userVerify");
  const { pathname } = useLocation();

  const [previousRoute, setPreviousRoute] = useState("");

  useEffect(() => {
    requestPermission();
    onMessageListener(dispatch);
  }, []);
  // useEffect(() => {
  //   if (pathname !== previousRoute) {
  //     setPreviousRoute(pathname);
  //   }
  //   // console.log(previousRoute, previousRoute.startsWith("/e_"));

  //   let shouldSwitch = false;

  //   if (pathname === "/order-page") {
  //     shouldSwitch = previousRoute.startsWith("/e_");
  //   } else if (pathname.startsWith("/e_")) {
  //     shouldSwitch = true;
  //   }
  //   if (pathname !== "paymentstauspage") {
  //     dispatch(handleSwitch(shouldSwitch));
  //   }
  // }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  useEffect(() => {
    (async () => {
      if (isToken && isUserId && isUserVerify) {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API}/getUserBYToken`,
            {
              headers: {
                Authorization: isToken,
              },
            }
          );
          dispatch(handleExistUser(true));
          return res.data;
        } catch (error) {
          if (error?.response?.data?.success === false) {
            dispatch(
              handleAlert({
                isOpen: true,
                type: "error",
                msg: "Session Expired",
              })
            );
            localStorage.removeItem("isUserId");
            localStorage.removeItem("isToken");
            dispatch(handleModalOpen(true));
            dispatch(handleExistUser(false));
          }
          // console.log(error, "error");
        }
      }
    })();
  }, [isToken, isUserId, isUserVerify]);

  useEffect(() => {
    dispatch(
      getNotificationCount(
        `${process.env.REACT_APP_API}/seenCount/${isUserId}?userType=CUSTOMER`
      )
    );
  }, []);

  return (
    <>
      <AlertTost />
      <Layout>
        <SkHelmet />
        <SkMenu />
        <LoginModal />
        <ProfileModal />
        <OrderModal />
        <LogoutConfirm />
        <PaymentModal />
        <WhatsappFloating />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/home-page" />} />
        </Routes>
        {/* <MobileLeft /> */}
      </Layout>
    </>
  );
}

export default App;
