import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
// import Check from "@mui/icons-material/Check";
import { Circle } from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function VerticalLinearStepper({ data }) {
  const { checked } = useSelector((state) => state.isOrders);
  const stepColors = {
    PENDING: "#f9a603",
    ORDERED: "#00A36C",
    ACCEPTED: "#013220",
    ONTHEWAY: "#89CFF0",
    WORKING: "#088F8F",
    COMPLETED: "#00008B",
    CANCELLED: "#FF0000",
    DELIVERED: "#006400",
    OUT_OF_DELIVERY: "#013220",
    RETURN_REQUEST: "#dc143c ",
    RETURNED: "#c90016",
    RETURN_REQUEST_APPROVED: "#ff6347",
    // CANCELLED: "#ff0000",
    SHIPPED: "#00b7eb",
    // #90EE90 #4B0082
  };

  const steps = [
    {
      title: "PENDING",
    },
    {
      title: "ORDERED",
    },
    {
      title: "ACCEPTED",
    },
    {
      title: "ONTHEWAY",
    },
    {
      title: "WORKING",
    },
    {
      title: "COMPLETED",
    },
  ];
  const steps3 = [
    {
      title: "PENDING",
    },
    {
      title: "ORDERED",
    },
    {
      title: "ACCEPTED",
    },
    {
      title: "SHIPPED",
    },
    {
      title: "OUT FOR DELIVERY",
    },
    {
      title: "DELIVERED",
    },
  ];

  const steps4 = [
    {
      title: "RETURN REQUEST",
    },
    {
      title: "RETURN REQUEST APPROVED",
    },
    {
      title: "RETURNED",
    },
    {
      title: "CANCELLED",
    },
  ];

  const steps2 = [
    {
      title: "PENDING",
    },
    {
      title: "ORDERED",
    },
    {
      title: "CANCELLED",
    },
  ];
  // Styled StepConnector
  const QontoConnector = styled(StepConnector)(({ theme, ownerState }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 0,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: ownerState.stepColor || "#784af4",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: ownerState.stepColor || "#784af4",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#acacac",
      borderLeftWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState, active }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 24,
    "& .QontoStepIcon-completedIcon": {
      //   color: ownerState?.stepColor || "#784af4",
      color: ownerState?.stepColor || "#acacac",
      zIndex: 1,
      fontSize: 15,
    },
    "& .QontoStepIcon-circle": {
      width: 12.5,
      height: 12.5,
      borderRadius: "50%",
      //   backgroundColor: ownerState?.stepColor || "#784af4",
      border: ownerState?.active
        ? `3px solid ${ownerState?.stepColor}`
        : `2px solid #acacac` || "#acacac",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className, ownerState } = props;
    // console.log(props);
    return (
      <QontoStepIconRoot
        ownerState={{ ...ownerState, active }}
        className={className}
      >
        {completed ? (
          <Circle className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const activeStep2 =
    (data?.status === "PENDING" && 0) ||
    (data?.status === "ORDERED" && 1) ||
    (data?.status === "CANCELLED" && 2) ||
    2;

  const activeStep =
    (data?.status === "PENDING" && 0) ||
    (data?.status === "ORDERED" && 1) ||
    (data?.status === "ACCEPTED" && 2) ||
    (data?.status === "ONTHEWAY" && 3) ||
    (data?.status === "WORKING" && 4) ||
    (data?.status === "COMPLETED" && 5) ||
    // (data?.status === "CANCELLED" && 6) ||
    0;
  const activeStep3 =
    (data?.status === "PENDING" && 0) ||
    (data?.status === "ORDERED" && 1) ||
    (data?.status === "ACCEPTED" && 2) ||
    (data?.status === "SHIPPED" && 3) ||
    (data?.status === "OUT_OF_DELIVERY" && 4) ||
    (data?.status === "DELIVERED" && 5) ||
    (data?.status === "CANCELLED" && 6) ||
    // (data?.status === "CANCELLED" && 6) ||
    0;
  const activeStep4 =
    (data?.status === "RETURN_REQUEST" && 0) ||
    (data?.status === "RETURNED" && 1) ||
    (data?.status === "RETURN_REQUEST_APPROVED" && 2) ||
    (data?.status === "CANCELLED" && 3) ||
    // (data?.status === "CANCELLED" && 6) ||
    0;
  //
  // console.log(data?.status);

  const isSteps = checked
    ? (data?.status === "CANCELLED" && steps2) ||
      ((checked && data?.status) === "DELIVERED" && steps3) ||
      ((checked && data?.status) === "RETURN_REQUEST" && steps4) ||
      steps3
    : (data?.status === "CANCELLED" && steps2) ||
      (data?.status === "DELIVERED" && steps) ||
      (data?.status === "RETURN_REQUEST" && steps4) ||
      steps;
  return (
    <Box sx={{ width: "80%" }}>
      <Stepper
        activeStep={
          checked
            ? (data?.status === "CANCELLED" && activeStep2) ||
              ((checked && data?.status) === "DELIVERED" && activeStep3) ||
              ((checked && data?.status) === "RETURN_REQUEST" && activeStep4) ||
              activeStep3
            : (data?.status === "CANCELLED" && activeStep2) ||
              (data?.status === "DELIVERED" && activeStep) ||
              (data?.status === "RETURN_REQUEST" && activeStep4) ||
              activeStep
        }
        orientation="vertical"
        sx={{
          "& .MuiStepConnector-root": {
            marginLeft: "10.5px",
          },
        }}
        connector={
          <QontoConnector
            ownerState={{
              stepColor: stepColors[data?.status],
            }}
          />
        }
      >
        {
          isSteps &&
            isSteps.length > 0 &&
            isSteps.map((step, index) => (
              <Step key={index} sx={{ minHeight: "0.5rem" }}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <QontoStepIcon
                      {...props}
                      ownerState={{ stepColor: stepColors[data?.status] }}
                    />
                  )}
                  ownerState={{ stepColor: stepColors[data?.status] }}
                >
                  {step.title}
                </StepLabel>
              </Step>
            ))

          // data?.status === "CANCELLED"
          //   ?
          //  steps2 &&
          //     steps2.length > 0 &&
          //     steps2.map((step, index) => (
          //       <Step key={index} sx={{ minHeight: "0.5rem" }}>
          //         <StepLabel
          //           StepIconComponent={(props) => (
          //             <QontoStepIcon
          //               {...props}
          //               ownerState={{ stepColor: stepColors[data?.status] }}
          //             />
          //           )}
          //           ownerState={{ stepColor: stepColors[data?.status] }}
          //         >
          //           {step.title}
          //         </StepLabel>
          //       </Step>
          //     ))
          //   : checked || data?.status === "DELIVERED"
          //   ? steps3 &&
          //     steps3.length > 0 &&
          //     steps3.map((step, index) => (
          //       <Step key={index} sx={{ minHeight: "0.5rem" }}>
          //         <StepLabel
          //           StepIconComponent={(props) => (
          //             <QontoStepIcon
          //               {...props}
          //               ownerState={{ stepColor: stepColors[data?.status] }}
          //             />
          //           )}
          //           ownerState={{ stepColor: stepColors[data?.status] }}
          //         >
          //           {step.title}
          //         </StepLabel>
          //       </Step>
          //     ))
          //   : checked || data?.status === "RETURN_REQUEST"
          //   ? steps4 &&
          //     steps4.length > 0 &&
          //     steps4.map((step, index) => (
          //       <Step key={index} sx={{ minHeight: "0.5rem" }}>
          //         <StepLabel
          //           StepIconComponent={(props) => (
          //             <QontoStepIcon
          //               {...props}
          //               ownerState={{ stepColor: stepColors[data?.status] }}
          //             />
          //           )}
          //           ownerState={{ stepColor: stepColors[data?.status] }}
          //         >
          //           {step.title}
          //         </StepLabel>
          //       </Step>
          //     ))
          //   : steps &&
          //     steps.length > 0 &&
          //     steps.map((step, index) => (
          //       <Step key={index} sx={{ minHeight: "0.5rem" }}>
          //         <StepLabel
          //           StepIconComponent={(props) => (
          //             <QontoStepIcon
          //               {...props}
          //               ownerState={{ stepColor: stepColors[data?.status] }}
          //             />
          //           )}
          //           ownerState={{ stepColor: stepColors[data?.status] }}
          //         >
          //           {step.title}
          //         </StepLabel>
          //       </Step>
          //     ))
        }
      </Stepper>
    </Box>
  );
}
// import React from "react";
// import Box from "@mui/material/Box";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
// import StepConnector, {
//   stepConnectorClasses,
// } from "@mui/material/StepConnector";
// import { styled } from "@mui/material/styles";

// // Styled StepConnector
// const QontoConnector = styled(StepConnector)(({ theme, ownerState }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 0,
//     left: "calc(-50% + 16px)",
//     right: "calc(50% + 16px)",
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: ownerState.activeStepColor || "#acacac",
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: ownerState.stepColor || "#acacac",
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor:
//       theme.palette.mode === "dark" ? theme.palette.grey[800] : "#acacac",
//     borderLeftWidth: 3,
//     borderRadius: 1,
//   },
// }));

// const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",
//   minWidth: 24,
//   "& .QontoStepIcon-circle": {
//     width: 12.5,
//     height: 12.5,
//     borderRadius: "50%",
//     backgroundColor: ownerState?.stepColor || "#acacac",
//     border: `1px solid ${
//       ownerState?.active
//         ? ownerState.activeStepColor
//         : ownerState?.stepColor || "#acacac"
//     }`,
//   },
// }));

// function QontoStepIcon(props) {
//   const { active, completed, className, ownerState } = props;
//   console.log(props);
//   return (
//     <QontoStepIconRoot
//       ownerState={{ ...ownerState, active }}
//       className={className}
//     >
//       <div className="QontoStepIcon-circle" />
//     </QontoStepIconRoot>
//   );
// }

// export default function VerticalLinearStepper({ steps, data }) {
//   const stepColors = {
//     PENDING: "#f9a603",
//     CANCELLED: "#FF0000",
//     DELIVERED: "#006400",
//     ONTHEWAY: "#89CFF0",
//     ORDERED: "#0000FF",
//     ACCEPTED: "#013220",
//     WORKING: "#013220",
//     COMPLETED: "#006400",
//   };

//   const activeStep =
//     (data?.status === "PENDING" && 0) ||
//     (data?.status === "ORDERED" && 1) ||
//     (data?.status === "ACCEPTED" && 2) ||
//     (data?.status === "ONTHEWAY" && 3) ||
//     (data?.status === "WORKING" && 4) ||
//     (data?.status === "COMPLETED" && 5) ||
//     (data?.status === "CANCELLED" && 6) ||
//     0;

//   return (
//     <Box sx={{ width: "80%" }}>
//       <Stepper
//         activeStep={activeStep}
//         orientation="vertical"
//         connector={
//           <QontoConnector
//             ownerState={{
//               stepColor: stepColors[data?.status],
//               activeStepColor: stepColors[data?.status],
//             }}
//           />
//         }
//       >
//         {steps &&
//           steps.length > 0 &&
//           steps.map((step, index) => (
//             <Step key={index} sx={{ minHeight: "0.5rem" }}>
//               <StepLabel
//                 StepIconComponent={(props) => (
//                   <QontoStepIcon
//                     {...props}
//                     ownerState={{ stepColor: stepColors[data?.status] }}
//                   />
//                 )}
//                 ownerState={{ activeStepColor: stepColors[data?.status] }}
//               >
//                 {step.title}
//               </StepLabel>
//             </Step>
//           ))}
//       </Stepper>
//     </Box>
//   );
// }
