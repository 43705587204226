import { LoadingButton } from "@mui/lab";
import { Box, Container, Switch, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Verify from "./Components/Verify";
import { registerUsers } from "../../Redux/festures/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "../../Redux/alertSlice";

export default function AccountDelete() {
  const dispatch = useDispatch();
  const [isToggle, setIsToggle] = useState(false);
  const [myOtp, setMyOtp] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { company } = useSelector((state) => state.isCompany);
  // console.log("company", company);

  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );

  const validationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(phoneRegex, "Invalid phone")
      .required("Phone is required"),
  });
  const initialValues = {
    phoneNumber: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log(values);
      // const formData = new FormData();
      setIsSubmitting(true);
      dispatch(
        registerUsers({
          url: `${process.env.REACT_APP_API}/login/customer`,
          data: {
            ...values,
            hashKey: process.env.REACT_APP_HASHKEY,
            customerId: localStorage.getItem("isUserIdDummy") || "",
            customerFcmToken: localStorage.getItem("customerFcmToken"),
          },
        })
      ).then((data) => {
        if (!data?.payload?.success) {
          setIsSubmitting(false);
          dispatch(
            handleAlert({
              isOpen: true,
              type: data?.payload?.success ? "success" : "error",
              msg:
                data?.payload?.message === "User Not Found"
                  ? "Please Register Your Self..!"
                  : data?.payload?.message,
            })
          );
        }
        if (data?.payload?.success) {
          dispatch(
            registerUsers({
              url: `${process.env.REACT_APP_API}/sendOtp`,
              data: {
                ...values,
                check: true,
              },
            })
          ).then((isData) => {
            // console.log(isData, "isData");

            dispatch(
              handleAlert({
                isOpen: true,
                type: isData?.payload?.success ? "success" : "error",
                msg: isData?.payload?.message,
              })
            );
            setIsSubmitting(false);
            if (isData?.payload?.success) {
              setIsToggle(true);
              setMyOtp(isData?.payload?.otp);
              setUserData(data?.payload?.data);
            }
          });
        }
      });
    },
  });
  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: "#eff3f6",
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md" sx={{ py: 10 }}>
        {/* <Switch defaultChecked onClick={handleToggleClick} /> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            bgcolor: "white",
            color: "#354052",
            gap: 2.5,
          }}
        >
          <Typography
            variant="h4"
            fontSize={28}
            fontWeight={600}
            textAlign={"center"}
          >
            Welcome To {company?.site_name || "Essindiaonline.com"}
          </Typography>
          {!isToggle ? (
            <Box
              sx={{
                width: { xs: "90%", sm: "90%", md: "35rem", xl: "35rem" },
                p: 4,
                bgcolor: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                gap: 3,
                boxShadow: 4.5,
                borderRadius: 2.5,
              }}
              component={"form"}
              onSubmit={formik.handleSubmit}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "1rem",
                  color: "#354052",
                }}
              >
                We take pride in offering not only expert repair and maintenance
                services but also professional installation and unInstallation
                for all your domestic, commercial, and non- commercial fitness
                equipment needs.
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "primary.main",
                    fontWeight: 600,
                    mr: 1.5,
                    textAlign: "start",
                  }}
                >
                  Phone Number
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  type={"number"}
                  required
                  // disabled={Loading}
                  autoComplete="off"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                  name="phoneNumber"
                  error={
                    !!formik.touched.phoneNumber && !!formik.errors.phoneNumber
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  placeholder="Phone No"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                          "-moz-appearance": "textfield",
                        },
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      border: "none",
                      outline: "none",
                      borderColor: "GrayText",
                      bgcolor: "#E8E8E8",
                      color: "primary.main",
                    },

                    "& .MuiOutlinedInput-notchedOutline": {
                      outline: "none",
                      border: "none",
                    },

                    "& .MuiFormHelperText-root": {
                      mx: 0,
                    },
                    // "& .MuiOutlinedInput-notchedOutline": {
                    //   outline: "none",
                    //   border: "none",
                    // },
                  }}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                />
              </Box>

              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <LoadingButton
                  //   loading={loading}
                  variant="contained"
                  type="submit"
                  color="primary"
                  sx={{ textTransform: "capitalize", px: 2.5, py: 1 }}
                >
                  {isSubmitting ? "Verifying...." : " Verify & Delete Account"}
                </LoadingButton>
              </Typography>
            </Box>
          ) : (
            <Verify
              myOtp={myOtp?.toString()}
              setMyOtp={setMyOtp}
              credential={{ phoneNumber: formik.values.phoneNumber }}
              isToggle={isToggle}
              userData={userData}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}
