import { Grid } from "@mui/material";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../assets/css/responsiveReactCarousel.css";
// import { useState } from "react";

const ReactResponsiveCarousel = ({ children }) => {
  // const urls = children?.map((child) => {
  //   if (child?.type === "img") {
  //     return child?.props?.src;
  //   } else if (child?.props?.component === "video") {
  //     return child?.props?.image;
  //   }
  //   return null;
  // });

  return (
    <Carousel
      autoPlay
      interval="3000"
      transitionTime="2000"
      infiniteLoop
      emulateTouch={true}
      showArrows={false}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      swipeable={true}
      // dynamicHeight={true}

      // renderThumbs={customRenderThumb}
      // renderItem={customRenderItem}
    >
      {children}
    </Carousel>
    // <Grid item md={6} xs={12} sx={{ height: "100%", width: "100%" }}>
    //  </Grid>
  );
};

export default ReactResponsiveCarousel;
