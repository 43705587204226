import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  // Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import http from "../../Utils/Api";
const Faq = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const res = await http.get(`/getAllFAQ`);
        setData(res.data.data);
        return res.data.data;
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  // console.log(data, "data");
  return (
    <>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: 3,
            pb: 3,
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontSize: 30, fontWeight: 600 }}
          >
            Frequently Asked Question
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 5,
              gap: 1,
              width: "100%",
              //   width: "80%",
            }}
          >
            {data &&
              data?.length > 0 &&
              data?.map((value, index) => (
                <Accordion
                  key={index}
                  style={{ width: "100%" }}
                  sx={{
                    "&.MuiAccordionDetails-root": {
                      width: "100%",
                    },
                  }}
                >
                  <AccordionSummary
                    style={{ width: "100%" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      fontWeight={600}
                      sx={{
                        maxWidth: "100%",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {value?.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ minWidth: "100%" }}>
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: 400,
                        maxWidth: "100%",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        // WebkitLineClamp: 4,
                        overflow: "hidden",
                        // textOverflow: "ellipsis",
                      }}
                    >
                      {value?.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Faq;
